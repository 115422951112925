import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TestService } from '../../services/behaviourService';
import { ConfigService } from '../../services/config.service';
import { SharingService } from '../../services/sharing.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-demographic',
  templateUrl: './demographic.component.html',
  styleUrls: ['./demographic.component.scss'],
  providers: [DatePipe]
})
export class DemographicComponent implements OnInit {
  primaryForm: FormGroup;
  submitAttempt: boolean = false;
  spouseArray: FormArray;
  childArray: FormArray;
  applicant: any = {}
  objectData: any = {}
  cartProducts: any;
  memberdata: any;
  test: FormGroup;
  primaryApplicant: any;
  spouseApplicant: any;
  childApplicant: any;
  newForm: FormGroup;
  form: FormGroup;
  auxConfigform: FormGroup;
  applicants: any = {};
  states: any;
  theCheckbox = true;
  isDisabled: boolean = true;
  CheckBoxVisible: boolean = false;
  submitButton: boolean = false;
  txt: string;
  height: string;
  number = "";
  numberSpouse = "";
  childArrayLength: any;
  spouseArrayLength: any;
  isDisabled1: boolean = true;
  data = ""
  phnumbervalue: any;
  transformedInput: string;
  orderDetails: any;
  zip: any;
  startdate: any;
  fromA2Cdata: boolean = true
  A2CflowData: any;
  agentInfo: any;
  companyId: any;

  cartFee: any;
  cartData: any;
  memberDiscount: any;
  bmiinfo: any;
  additionalMember: any;
  zipFormArray: any[] = [];
  companyInfo: any;
  oneTimeFee: any;
  monthlyContribution: any;
  monthlyContributionStartDate: any;
  ssN: any;
  addOnProducts: any;
  supplementalProducts: any;
  totalAddOnsPrice: any;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private objectService: TestService,
    private configService: ConfigService,
    private services: SharingService,
    private service: TestService,
    private http: HttpClient,
    private datePipe: DatePipe,
    private toastr: ToastrService
  ) {
    this.services.clientData.subscribe((data) => {
      if (data != '') {
        this.companyInfo = data;
        this.companyId = this.companyInfo.company_id;
        // this.CompanyName=  this.companyInfo.company_name;
        this.a2CDataFlow();
        this.getState();
      }
    })
    this.agentInfo = JSON.parse(sessionStorage.getItem('agentinfo'));
  }

  a2CDataFlow() {
    this.objectService.cartPlan.subscribe(result => {
      if (result != '') {
        this.data = JSON.parse(sessionStorage.getItem('applicantData'));
        this.applicant = result;
        this.cartFee = this.applicant.cart_fee;
        this.cartData = this.applicant.cart_data;
        this.memberDiscount = this.applicant.agent_cart_discount;
        this.bmiinfo = this.applicant.cart_bmi;
        this.additionalMember = this.applicant.additional_member_fee
        this.orderDetails = this.applicant.order_details;
        this.oneTimeFee = this.applicant.a2c_total_one_time_fee;
        if (this.applicant !== null) {
          if (this.applicant.startdate !== undefined) {
            this.startdate = this.applicant.startdate;
            this.zip = this.applicant.zipcode
          } else {
            this.startdate = this.applicant.startDate;
            this.zip = this.applicant.zipcode
          }
          if (this.applicant.member_personal_data[0].zip !== undefined) {
            this.zip = this.applicant.member_personal_data[0].zip
            this.zip = this.applicant.zipcode
          } else {
            this.zip = this.applicant.zipcode
            this.zip = this.applicant.member_personal_data[0].zip
          }
        }
        this.cartProducts = this.applicant.cart_products;
        this.memberdata = this.applicant.member_personal_data;
        if (this.memberdata[0].ssn == null) {
          this.CheckBoxVisible = true
          this.submitButton = true;
          this.primaryApplicant = this.memberdata.filter(x => x['relation'] == "Primary");
          this.spouseApplicant = this.memberdata.filter(x => x['relation'] == "Spouse");
          this.childApplicant = this.memberdata.filter(x => x['relation'] == "Child");
          this.primaryApplicant[0].zip = this.applicant.zipcode;
          this.zip = this.applicant.zipcode
          if (this.spouseApplicant !== undefined && this.spouseApplicant.length !== 0) {
            this.spouseApplicant[0].zip = this.applicant.zipcode;
          }
          if (this.childApplicant !== undefined && this.childApplicant.length !== 0) {
            for (var i = 0; i < this.childApplicant.length; i++) {
              this.childApplicant[i].zip = this.applicant.zipcode;
            }
          }
          this.theCheckbox = false;
          this.childArrayLength = this.childApplicant.length;
          this.spouseArrayLength = this.spouseApplicant.length;
        }
        else {
          this.CheckBoxVisible = false;
          this.theCheckbox = true;
          this.submitButton = false;
          this.primaryApplicant = this.memberdata.filter(x => x['relation'] == "Primary");
          this.spouseApplicant = this.memberdata.filter(x => x['relation'] == "Spouse");
          this.childApplicant = this.memberdata.filter(x => x['relation'] == "Child");
        }
        this.memberInfo();
        this.getApplicationCart();
      } else {
        var A2CUrl = window.location.href;
        const lastUrlSegment = A2CUrl.split('?')[0].split('/').pop();
        localStorage.setItem('a2cUrl', lastUrlSegment)
        var url = "DecryptMemberIdA2C/" + lastUrlSegment
        this.services.getData(url).subscribe((Object: any) => {
          if (Object != null) {
            
            this.A2CflowData = Object
            this.fromA2Cdata = false;
            var member_id = Object.data[0].member_id;
            var cart_id = Object.data[0].cart_id;
            // agent_user_id
            var url = 'GetAgentInfo/' + Object.data[0].agent_id
            this.services.getData(url).subscribe((Object: any) => {

              this.agentInfo = Object.data;
              sessionStorage.setItem('agentinfo', JSON.stringify(this.agentInfo));
            })
            var url = 'GetMemberDependents/' + this.companyId + '/' + member_id + '/' + cart_id;
            this.services.getData(url).subscribe((Object: any) => {
             
              this.objectService.pushheaderCondition("showheaders");
              this.applicant = Object;
              this.getApplicationCart();
              this.memberdata = Object.member_personal_data;
              if (this.applicant !== null) {
                if (this.applicant.startdate !== undefined) {
                  this.startdate = this.applicant.startdate;
                  this.zip = this.applicant.zipcode
                } else {
                  this.startdate = this.applicant.startDate;
                  this.zip = this.applicant.zipcode
                }
                if (this.applicant.member_personal_data[0].zip !== undefined) {
                  this.zip = this.applicant.member_personal_data[0].zip
                  this.zip = this.applicant.zipcode
                } else {
                  this.zip = this.applicant.zipcode
                  this.zip = this.applicant.member_personal_data[0].zip
                }
              }

              if (this.memberdata[0].ssn != null || this.memberdata[0].ssn != " ") {
                this.CheckBoxVisible = true
                this.submitButton = true;
                this.primaryApplicant = this.memberdata.filter(x => x['relation'] == "Primary");
                this.spouseApplicant = this.memberdata.filter(x => x['relation'] == "Spouse");
                this.childApplicant = this.memberdata.filter(x => x['relation'] == "Child");
                this.primaryApplicant[0].zip = this.applicant.zipcode;
                this.zip = this.applicant.zipcode
                if (this.spouseApplicant !== undefined && this.spouseApplicant.length !== 0) {
                  this.spouseApplicant[0].zip = this.applicant.zipcode;
                }
                if (this.childApplicant !== undefined && this.childApplicant.length !== 0) {
                  for (var i = 0; i < this.childApplicant.length; i++) {
                    this.childApplicant[i].zip = this.applicant.zipcode;
                  }
                }
                this.theCheckbox = false;
                this.childArrayLength = this.childApplicant.length;
                this.spouseArrayLength = this.spouseApplicant.length;
              }
              else {
                this.CheckBoxVisible = false;
                this.theCheckbox = true;
                this.submitButton = false;
                this.primaryApplicant = this.memberdata.filter(x => x['relation'] == "Primary");
                this.spouseApplicant = this.memberdata.filter(x => x['relation'] == "Spouse");
                this.childApplicant = this.memberdata.filter(x => x['relation'] == "Child");
              }
              this.memberInfo();
            })
          }
        }, err => {
          console.log(err)
        })
      }
    });
  }

  getApplicationCart() {

    var url = 'GetCartPlan/' + this.companyId + '/' + this.applicant.cart_data[0].id + '/' + this.applicant.member_personal_data[0].family_id;
    this.services.getData(url).subscribe((Object: any) => {
      var applicationCartData = Object
      this.objectService.appCartInfo(Object);
      localStorage.setItem('appCart', JSON.stringify(applicationCartData))
    }, err => {

    })
  }
  memberInfo() {
    const arryOfPrimaryGroup = this.primaryApplicant.map(item => {

      this.txt = item.height;
      var height = item.height;
      var n = height.toString().includes(".");
      if (n == false) {
        var str2 = ".0";
        var height = height.toString().concat(str2);
      }
      const Height = height.toString();
      var inch = Height.substring(Height.lastIndexOf('.') + 1);
      var ft = Height.substring(0, Height.lastIndexOf('.'));
      this.height = ft + '' + ' ft.' + ' ' + inch + '' + ' inch';
      var D = this.datePipe.transform(item.dob, "MM/dd/yyyy");
      if (this.primaryApplicant[0].phone != null || this.primaryApplicant[0].phone != undefined) {
        var phone = item.phone.substr(0, 3) + "-" + item.phone.substr(3, 3) + "-" + item.phone.substr(6, 4);
      }
      else {
        var phone: string = item.phone;
      }
      if (this.primaryApplicant[0].ssn == '') {
        const ssN = item.ssn.toString();
        // var ssN = ssn.substr(0, 3) + "-" + ssn.substr(3, 2) + "-" + ssn.substr(5, 4);
      }
      else if (this.primaryApplicant[0].ssn != '') {
        const ssn = item.ssn.toString();
        var ssN = ssn.substr(0, 3) + "-" + ssn.substr(3, 2) + "-" + ssn.substr(5, 4);
      }
      return this.createChildGroup(
        item.weight, D, this.height, item.gender, item.relation, item.age, item.smoker, item.firstname, item.lastname, item.email,
        phone, item.city, item.state, item.zip, ssN, item.address1, item.address2, item.member_id,
      );
    });
    const arryOfSpouseGroup = this.spouseApplicant.map(item => {
      this.txt = item.height;
      var height = item.height;
      var n = height.toString().includes(".");
      if (n == false) {
        var str2 = ".0";
        var height = height.toString().concat(str2);
      }
      const Height = height.toString();
      var inch = Height.substring(Height.lastIndexOf('.') + 1);
      var ft = Height.substring(0, Height.lastIndexOf('.'));
      this.height = ft + '' + ' ft.' + ' ' + inch + '' + ' inch';
      var D = this.datePipe.transform(item.dob, "MM/dd/yyyy");
      if (this.spouseApplicant[0].phone !== null) {
        var phone = item.phone.substr(0, 3) + "-" + item.phone.substr(3, 3) + "-" + item.phone.substr(6, 4);
      }
      else {
        var phone: string = item.phone;
      }
      if (this.spouseApplicant[0].ssn == '') {
        const ssN = item.ssn.toString();
        // var ssN = ssn.substr(0, 3) + "-" + ssn.substr(3, 2) + "-" + ssn.substr(5, 4);
      }
      else if (this.spouseApplicant[0].ssn != '') {
        const ssn = item.ssn.toString();
        var ssN = ssn.substr(0, 3) + "-" + ssn.substr(3, 2) + "-" + ssn.substr(5, 4);
      }
      return this.createChildGroup(
        item.weight, D, this.height, item.gender, item.relation, item.age, item.smoker, item.firstname, item.lastname, item.email,
        phone, item.city, item.state, item.zip, ssN, item.address1, item.address2, item.member_id,
      );
    });
    console.log(this.childApplicant)
    const arryOfChildGroup = this.childApplicant.map(item => {

      this.txt = item.height;
      var height = item.height;
      var n = height.toString().includes(".");
      if (n == false) {
        var str2 = ".0";
        var height = height.toString().concat(str2);
      }
      const Height = height.toString();
      var inch = Height.substring(Height.lastIndexOf('.') + 1);
      var ft = Height.substring(0, Height.lastIndexOf('.'));
      this.height = ft + '' + ' ft.' + ' ' + inch + '' + ' inch';
      var D = this.datePipe.transform(item.dob, "MM/dd/yyyy");
      if (item.phone !== undefined && item.phone !== null) {
        var phone = item.phone.substr(0, 3) + "-" + item.phone.substr(3, 3) + "-" + item.phone.substr(6, 4);
      } else {
        var phone: string = item.phone;
      }

      if (this.childApplicant[0].ssn != null && this.childApplicant[0].ssn != undefined && this.childApplicant[0].ssn != '') {
        const ssn = item.ssn.toString();
        let str = "***-**-"
        var ssN = str + ssn.slice(ssn.length - 4);
      }
      else {
        var ssN: string = item.ssn;
      }

      return this.createChildGroup(
        item.weight, D, this.height, item.gender, item.relation, item.age, item.smoker, item.firstname, item.lastname, item.email,
        phone, item.city, item.state, item.zip, ssN, item.address1, item.address2, item.member_id,
      );
    });
    this.auxConfigform = this.formBuilder.group({
      childArray: this.formBuilder.array(arryOfChildGroup),
      primaryArray: this.formBuilder.array(arryOfPrimaryGroup),
      SpouseArray: this.formBuilder.array(arryOfSpouseGroup)
    });
  }

  child_Array(): FormArray {
    return this.auxConfigform.get("childArray") as FormArray
  }
  get demgChildArray() {
    return this.auxConfigform.get("childArray")["controls"] as FormArray;
  }
  get demgPrimaryArray() {
    return this.auxConfigform.get("primaryArray")["controls"] as FormArray;
  }
  get demgSpouseArray() {
    return this.auxConfigform.get("SpouseArray")["controls"] as FormArray;
  }
  createChildGroup(weight, dob, height, gender, relation, age, smoker, firstname, lastname,
    email, phone, city, state, zip, ssn, address1, address2, member_id) {

    return this.formBuilder.group({
      relation: [relation],
      age: [age],
      smoker: [smoker],
      firstname: [firstname, Validators.required],
      lastname: [lastname, Validators.required],
      phone: [phone,],
      email: [email, Validators.compose([Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])],//, Validators.pattern(this.emailPattern)
      dob: [dob, Validators.required],
      height: [height, Validators.required],
      weight: [weight, Validators.required],
      gender: [gender, Validators.required],
      ssn: [ssn,],
      address1: [address1, Validators.required],
      address2: [address2],
      city: [city, Validators.required],
      state: [state, Validators.required],
      zip: [zip, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{5}$")]],
      member_id: [member_id],
      checkBox: [this.CheckBoxVisible],
      disabled: [this.CheckBoxVisible]
    });
  }

  ngOnInit(): void {

  }
  InputChange(ControlName) {
    var ControlValue = this.auxConfigform.controls.primaryArray['controls'][0].controls[ControlName].value
    for (var i = 0; i < this.demgChildArray.length; i++) {
      if (this.auxConfigform.controls.childArray['controls'][i].controls.checkBox.value) {
        this.auxConfigform.controls.childArray['controls'][i].controls[ControlName].setValue(ControlValue)
      }
    }
    for (var i = 0; i < this.demgSpouseArray.length; i++) {
      if (this.auxConfigform.controls.SpouseArray['controls'][i].controls.checkBox.value) {
        this.auxConfigform.controls.SpouseArray['controls'][i].controls[ControlName].setValue(ControlValue)
      }
    }

  }
  checkBoXChange(index, arrayName) {
    if (this.auxConfigform.controls[arrayName]['controls'][index].controls.checkBox.value) {
      this.auxConfigform.controls[arrayName]['controls'][index].patchValue({
        disabled: true,
        address1: this.auxConfigform.controls.primaryArray['controls'][0].controls['address1'].value,
        address2: this.auxConfigform.controls.primaryArray['controls'][0].controls['address2'].value,
        city: this.auxConfigform.controls.primaryArray['controls'][0].controls['city'].value,
        state: this.auxConfigform.controls.primaryArray['controls'][0].controls['state'].value,
        zip: this.auxConfigform.controls.primaryArray['controls'][0].controls['zip'].value,
      })
    }
    else {
      this.auxConfigform.controls[arrayName]['controls'][index].patchValue({
        disabled: false,
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
      })
    }
  }

  backToPlanes(item) {

    var Object = this.applicant;
    this.objectService.plansback(Object);
    this.router.navigate(['PlansAndProducts']);
  }

  getState() {
    var url = 'ListUsaStates';
    this.services.getData(url).subscribe((Object: any) => {
      if (Object !== null) {
        this.states = Object.data;
      }
    })
  }
  submitPrimaryForm(values) {

    this.zipFormArray = [];
    if (this.auxConfigform.valid) {
      if (values.primaryArray[0].ssn != null && values.primaryArray[0].ssn != '') {
        if (values.primaryArray[0].ssn.replace(/[^0-9\.]+/g, "").length == 9) {
          this.zipFormArray.push(true);
        }
        else {
          this.zipFormArray.push(false);
        }
      }
      if (values.primaryArray[0].zip != null && values.primaryArray[0].zip != '') {
        this.submitAttempt = true;
        const z = values.primaryArray[0].zip.toString();
        if (z.length == 5) {
          // this.submitPrimaryForm1(values)
          this.zipFormArray.push(true);
        }
        else {
          this.zipFormArray.push(false);
        }
      } else {
        this.submitAttempt = true
      }//primary array end
      if (values.SpouseArray.length > 0) {
        if (values.SpouseArray[0].ssn != null && values.SpouseArray[0].ssn != "") {
          if (values.SpouseArray[0].ssn.replace(/[^0-9\.]+/g, "").length == 9) {
            this.zipFormArray.push(true);
          }
          else {
            this.zipFormArray.push(false);
          }
        }
        if (values.SpouseArray[0].zip != null && values.SpouseArray[0].zip != '') {
          this.submitAttempt = true;
          const z = values.SpouseArray[0].zip.toString();
          if (z.length == 5) {
            this.zipFormArray.push(true);
          }
          else {
            this.zipFormArray.push(false);
          }
        } else {
          this.submitAttempt = true
        } //spouse
      }
      if (values.childArray.length > 0) {
        for (let i = 0; i < values.childArray.length; i++) {
          if (values.childArray[i].ssn != null && values.childArray[i].ssn != '') {
            if (values.childArray[i].ssn.replace(/[^0-9\.]+/g, "").length == 9) {
              this.zipFormArray.push(true);
            }
            else {
              this.zipFormArray.push(false);
            }
          }
          if (values.childArray[i].zip != null && values.childArray[i].zip != '') {
            const z = values.childArray[i].zip.toString();
            this.submitAttempt = true;
            if (z.length == 5) {
              this.zipFormArray.push(true);
            }
            else {
              this.zipFormArray.push(false);
            }
          }
          else {
            this.submitAttempt = true;
          }// child
        }
      }
      if (this.zipFormArray.includes(false) == false) {
        this.submitPrimaryForm1(values);
      }
      else {
        this.submitAttempt = true;
      }
    } else {
      this.submitAttempt = true;
    }
  }
  //============***============//
  submitPrimaryForm1(values) {
    this.submitAttempt = true
    if (this.A2CflowData != null) {
      this.objectData.planTerm = 'Monthly';
      this.objectData.company_id = this.companyId;
      this.objectData.cart_id = this.applicant.cart_data[0].id
      this.objectData.zipcode = this.applicant.zipcode;
      this.objectData.startdate = this.applicant.startDate;
      values.primaryArray[0].height
      let resultArr = [];
      resultArr = resultArr.concat(values.primaryArray);
      resultArr = resultArr.concat(values.childArray);
      resultArr = resultArr.concat(values.SpouseArray);
      for (let i = 0; i < resultArr.length; i++) {
        resultArr[i].height = resultArr[i].height.replace(/[^0-9\.-]+/g, "");
        if (resultArr[i].ssn == null || resultArr[i].ssn == '') {
          resultArr[i].ssn == '';
        } else {
          resultArr[i].ssn = resultArr[i].ssn.replace(/[^0-9\.]+/g, "");
        }
        if (resultArr[i].phone == null) {
          resultArr[i].phone = resultArr[i].phone;
        } else {
          resultArr[i].phone = resultArr[i].phone.replace(/[^0-9\.]+/g, "");
        }
      }
      var applicantObj: any = {}
      applicantObj.member_personal_data = resultArr;
      var body: any = {}
      body = Object.assign({}, this.objectData, applicantObj)
      var url = 'UpdateMemberAddress';
      this.services.putData(url, body).subscribe((Object: any) => {
        if (Object.status == 'success') {
          var demographicsDepdnt = Object;
          this.objectService.demographyH(demographicsDepdnt);
          this.router.navigate(['A2C/health-based-questions']);
          this.objectService.questionsback("");
          this.getApplicationCart();
        }
      }, err => {
        console.log(err);
        this.toastr.error(err.error.email);
      })
      // }
    }
    else if (this.memberdata[0].member_id !== null && this.applicant.order_details == null && this.data == "1") {
      // if (this.auxConfigform.valid) {
      this.objectData.planTerm = 'Monthly';
      this.objectData.company_id = this.companyId;
      this.objectData.cart_id = this.applicant.cart_data[0].id
      this.objectData.zipcode = this.applicant.member_personal_data[0].zip;
      this.objectData.startdate = this.applicant.startdate;
      let resultArr = [];
      resultArr = resultArr.concat(values.primaryArray);
      resultArr = resultArr.concat(values.childArray);
      resultArr = resultArr.concat(values.SpouseArray);
      for (let i = 0; i < resultArr.length; i++) {
        resultArr[i].height = resultArr[i].height.replace(/[^0-9\.-]+/g, "");
        if (resultArr[i].ssn == null || resultArr[i].ssn == '') {
          resultArr[i].ssn == '';
        } else {
          resultArr[i].ssn = resultArr[i].ssn.replace(/[^0-9\.]+/g, "");
        }
        if (resultArr[i].phone == null) {
          resultArr[i].phone = resultArr[i].phone;
        } else {
          resultArr[i].phone = resultArr[i].phone.replace(/[^0-9\.]+/g, "");
        }
      }
      var applicantObj: any = {}
      applicantObj.member_personal_data = resultArr;
      var body: any = {}
      body = Object.assign({}, this.objectData, applicantObj)
      var url = 'UpdateMemberAddress';
      this.services.putData(url, body).subscribe((Object: any) => {
        if (Object.status == 'success') {
          this.objectService.paymentMethod(Object);
          this.router.navigate(['A2C/health-based-questions']);
          this.getApplicationCart();
        }
      }, err => {
        console.log(err);
        this.toastr.error(err.error.email);
      })
      // }
    } else if (this.memberdata[0].member_id !== null && this.applicant.cart_data[0].id !== null && this.data !== "1") {
      // if (this.auxConfigform.valid) {
      this.objectData.planTerm = 'Monthly';
      this.objectData.company_id = this.companyId;
      this.objectData.cart_id = this.applicant.cart_data[0].id
      this.objectData.zipcode = this.orderDetails[0].zipcode;
      this.objectData.startdate = this.orderDetails[0].startdate;
      let resultArr = [];
      resultArr = resultArr.concat(values.primaryArray);
      resultArr = resultArr.concat(values.childArray);
      resultArr = resultArr.concat(values.SpouseArray);
      for (let i = 0; i < resultArr.length; i++) {
        resultArr[i].height = resultArr[i].height.replace(/[^0-9\.-]+/g, "");
        if (resultArr[i].ssn == null || resultArr[i].ssn == '') {
          resultArr[i].ssn == '';
        } else {
          resultArr[i].ssn = resultArr[i].ssn.replace(/[^0-9\.]+/g, "");
        }
        if (resultArr[i].phone == null) {
          resultArr[i].phone = resultArr[i].phone;
        } else {
          resultArr[i].phone = resultArr[i].phone.replace(/[^0-9\.]+/g, "");
        }
      }
      var applicantObj: any = {}
      applicantObj.member_personal_data = resultArr;
      var body: any = {}
      body = Object.assign({}, this.objectData, applicantObj)
      var url = 'UpdateMemberAddress';
      this.services.putData(url, body).subscribe((Object: any) => {
        if (Object.status == 'success') {
          this.objectService.paymentMethod(Object);
          this.router.navigate(['A2C/summary']);
          this.getApplicationCart()
        }
      }, err => {
        console.log(err);
        this.toastr.error(err.error.email);
      })
    }
  }
  onTypingSocialSecurity() {
    let primary_value = (<HTMLInputElement>document.getElementById("social-securityA2c")).value;
    if (primary_value.length < 11 && primary_value.length > 0) {
      this.number = "Please enter a 9 digit number"
    }
    if (primary_value.length >= 11) {
      this.number = ""
    }
    if (this.spouseArrayLength != "") {
      let spouse_value = (<HTMLInputElement>document.getElementById("social-security-spouseA2c")).value;
      if (spouse_value.length < 11 && spouse_value.length > 0) {
        this.numberSpouse = "Please enter a 9 digit number"
      }
      if (spouse_value.length >= 11) {
        this.numberSpouse = ""
      }
    }
  }
  onTypingSocialSecuritychild(i, event) {
    let id = "social-security-childA2c-" + i;
    if (event.target.id == id) {
      let child_value = (<HTMLInputElement>document.getElementById("social-security-childA2c-" + [i])).value;
      if (child_value.length < 11 && child_value.length > 0) {
        let error = "error" + i;
        (<HTMLInputElement>document.getElementById("error" + i)).innerHTML = "Please enter a 9 digit number";
      }
      if (child_value.length >= 11) {
        let error = "error" + i;
        (<HTMLInputElement>document.getElementById("error" + i)).innerHTML = "";
      }
    }
  }


  //================number validation==============//
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  onkeyupPhone(e) {
    this.phnumbervalue = (<HTMLInputElement>document.getElementById("phnumber")).value;
    this.transformedInput = this.phnumbervalue.replace(/^0*/g, '');
    (<HTMLInputElement>document.getElementById("phnumber")).value = this.transformedInput;
    if (this.phnumbervalue.length == 12) {
      (<HTMLInputElement>document.getElementById("phnumbererrmsg")).innerHTML = "";
    }
  }
  onfocusoutphnumber(feature, i, event) {

    if (feature == "primary") {
      let text = (<HTMLInputElement>document.getElementById("phnumbererrmsg")).innerHTML;
      let value = (<HTMLInputElement>document.getElementById("phnumber")).value;

      if (value.length < 12) {
        (<HTMLInputElement>document.getElementById("phnumbererrmsg")).innerHTML = "Please enter valid Phone number";
      }
      if (value.length >= 12) {
        (<HTMLInputElement>document.getElementById("phnumbererrmsg")).innerHTML = "";
      }
    }
    if (feature == "spouse") {

      let value = (<HTMLInputElement>document.getElementById("spousephnumber")).value;

      if (value.length < 12) {
        (<HTMLInputElement>document.getElementById("spousephnumbererrmsg")).innerHTML = "Please enter valid Phone number";
      }
      if (value.length >= 12) {
        (<HTMLInputElement>document.getElementById("spousephnumbererrmsg")).innerHTML = "";
      }
    }
    if (feature == "child") {
      let id = "childphnumber" + i
      if (event.target.id == id) {
        let child_phnbumber = (<HTMLInputElement>document.getElementById("childphnumber" + [i])).value;
        if (child_phnbumber.length < 12 && child_phnbumber.length > 0) {


          (<HTMLInputElement>document.getElementById("childpherror" + i)).innerHTML = "Please enter valid Phone number";
        }
        if (child_phnbumber.length >= 12) {


          (<HTMLInputElement>document.getElementById("childpherror" + i)).innerHTML = "";
        }

      }
    }

  }
  onPrimarySSNFocusout(e) {
    let spousessnvalue = (<HTMLInputElement>document.getElementById(e.target.id)).value;
    if (spousessnvalue.length == 11) {
      var substr = spousessnvalue.substring(spousessnvalue.length - 4, spousessnvalue.length);
      let str = "***-**-"
      str = str + substr;
      (<HTMLInputElement>document.getElementById(e.target.id)).value = str;
    }
  }
  onFocusPrimaryA2c(event) {

    let ssn = (<HTMLInputElement>document.getElementById(event.target.id)).value;
    if (ssn.length == 11) {
      let sessionstoragessn = sessionStorage.getItem(event.target.id);
      (<HTMLInputElement>document.getElementById(event.target.id)).value = sessionstoragessn;
    }
  }

  onSpouseSSNFocusout(e) {
    let spousessnvalue = (<HTMLInputElement>document.getElementById(e.target.id)).value;
    if (spousessnvalue.length == 11) {
      var substr = spousessnvalue.substring(spousessnvalue.length - 4, spousessnvalue.length);
      let str = "***-**-"
      str = str + substr;
      (<HTMLInputElement>document.getElementById(e.target.id)).value = str;
    }
  }
  onFocusSpouseA2c(event) {
    let ssn = (<HTMLInputElement>document.getElementById(event.target.id)).value;
    if (ssn.length == 11) {
      let sessionstoragessn = sessionStorage.getItem(event.target.id);
      (<HTMLInputElement>document.getElementById(event.target.id)).value = sessionstoragessn;
    }
  }

  onChildSSNFocusout(e) {
    let spousessnvalue = (<HTMLInputElement>document.getElementById(e.target.id)).value;
    if (spousessnvalue.length == 11) {
      var substr = spousessnvalue.substring(spousessnvalue.length - 4, spousessnvalue.length);
      let str = "***-**-"
      str = str + substr;
      (<HTMLInputElement>document.getElementById(e.target.id)).value = str;
    }
  }
  onFocusChildA2c(event) {
    let ssn = (<HTMLInputElement>document.getElementById(event.target.id)).value;
    if (ssn.length == 11) {
      let sessionstoragessn = sessionStorage.getItem(event.target.id);
      (<HTMLInputElement>document.getElementById(event.target.id)).value = sessionstoragessn;
    }
  }

}
