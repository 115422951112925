import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { TestService } from '../services/behaviourService';
import { SharingService } from '../services/sharing.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  url: string;
  loadedData: any;
  companyId: string;
  companyName: string;
  companyInfo: any;
  memberLogin: any;
  companyContent:boolean=true;
  constructor(private router: Router, private behaviourservice: TestService, private service: SharingService) {
    this.service.clientData.subscribe((data) => {
      if (data != '') {
        this.companyInfo = data;
        this.companyId = this.companyInfo.company_id;
        this.memberLogin = this.companyInfo.MemberLogin
        this.companyName = this.companyInfo.company_name;
        if(this.companyInfo.companyUrl=='fellowshiphealthshare'){
          this.companyContent=true;
        }else{
          this.companyContent=false
        }
      }
    })
  }

  ngOnInit(): void {
    this.behaviourservice.getheaderCondition().subscribe((data) => {
      this.loadedData = data;
    });
  }
  status: boolean = true;
  loadedProductDetails: any[] = [];
  menu() {
    this.status = false;
  }
  submenu() {
    this.status = !false;
  }

  hide() {
    this.status = true;
  }
  reLoad() {
    //  window.location.reload();

  }
}
