import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  DialogLayoutDisplay,
  ConfirmBoxInitializer,
} from '@costlydeveloper/ngx-awesome-popup';

import { TestService } from '../services/behaviourService';
import { SharingService } from '../services/sharing.service';
@Component({
  selector: 'app-appointmenttest',
  templateUrl: './appointmenttest.component.html',
  styleUrls: ['./appointmenttest.component.scss'],
})
export class AppointmenttestComponent implements OnInit {
  companyInfo: any;
  companyId: any;
  questions: any[] = [];
  submitAttempt: boolean = false;
  selectedOption: any[] = [];
  checkedRadioQtns: any[] = [];
  checkedRadioQtns1: any[] = [];
  checkedRadioQtns2: any[] = [];
  checkedRadioQtnsIds: any[] = [];
  finalCheckedQtns: any[] = [];
  appointmentForm: FormGroup;
  testTaken = 'No';
  questionId: any[] = [];
  answersObj: any;
  answersArr: any[] = [];
  loader: boolean = false;
  showalreadytakentest: any;
  apiReturnedData: any;
  apiReturnedAnswers: any[] = [];
  visibledom = 'qtns';
  messagearea = '';
  useransweredQtns: any[] = [];
  showanswerstatus = 'Your answer is right.';
  selectedoptionId: any;
  selectedQuestionId: any;
  unansweredQtns: any[] = [];
  answerId: any[] = [];
  answers: any[] = [];
  result: any[] = [];
  useranswerd: any[] = [];
  data: any[] = [];

  constructor(
    private services: SharingService,
    private formBuilder: FormBuilder,
    private objectService: TestService
  ) {
    this.services.clientData.subscribe((data) => {
      if (data != '') {
        this.companyInfo = data;
        this.companyId = this.companyInfo.company_id;
        this.getQuestions();
      }
    });
  }

  ngOnInit(): void {
    this.objectService.pushheaderCondition('showheader');
    this.appointmentForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],

      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ]),
      ], //, Validators.pattern(this.emailPattern)
    });
  }
  getQuestions() {
    var url = 'getAgentTestQA' + '/' + this.companyId;
    this.services.getData(url).subscribe(
      (Object: any) => {
        if (Object !== null) {
          this.questions = Object.data;
          this.questions.forEach((el) => {
            el.index = this.questions.indexOf(el) + 1;
          });
          for (var j = 0; j < this.questions.length; j++) {
            if (this.questions[j].question_id == j + 1) {
              for (var k = 0; k < this.questions[j].answer.length; k++) {
                this.questions[j].answer[k].question_id = j + 1;
                this.questions[j].answer[k].classname = 'appointmenttest';
              }
            }
          }

          for (var t = 0; t < this.questions.length; t++) {
            for (var f = 0; f < this.questions[t].answer.length; f++) {
              this.answers.push(this.questions[t].answer[f]);
            }
          }
          this.submitAttempt = false;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  setAnswer(question, e, item) {
    this.result = [];
    this.answers.forEach((el) => {
      el.classname = 'appointmenttest';
    });
    this.checkedRadioQtns.push(item);

    this.finalCheckedQtns = [];
    for (let i = 0; i < this.checkedRadioQtns.length; i++) {
      this.questionId.push(this.checkedRadioQtns[i].question_id);
    }
    let mySet = new Set(this.questionId);
    this.questionId = [...mySet];
    for (let i = 0; i < this.questionId.length; i++) {
      var lastIndex = this.checkedRadioQtns
        .map((e) => e.question_id)
        .lastIndexOf(this.questionId[i]);
      this.finalCheckedQtns.push(this.checkedRadioQtns[lastIndex]);
    }

    this.result = this.answers;

    for (var l = 0; l < this.result.length; l++) {
      for (var m = 0; m < this.finalCheckedQtns.length; m++) {
        if (this.result[l].answer_id == this.finalCheckedQtns[m].answer_id) {
          if (this.result[l].classname == 'appointmenttest') {
            this.result[l].classname = 'activecolor';
          }
        }
      }
    }
  }
  submit(value) {
    if (this.appointmentForm.valid) {
      if (this.checkedRadioQtns.length > 0) {
        this.confirmBox();
      }
    } else {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.submitAttempt = true;
    }
  }

  confirmBox() {
    const confirmBox = new ConfirmBoxInitializer();
    confirmBox.setTitle('Are you sure you want to submit your answers?');
    confirmBox.setButtonLabels('YES', 'NO');
    confirmBox.setConfig({
      LayoutType: DialogLayoutDisplay.INFO, // SUCCESS | INFO | NONE | DANGER | WARNING
    });

    confirmBox.openConfirmBox$().subscribe((resp) => {
      if (resp.ClickedButtonID == 'yes') {
        this.submitQtns(this.appointmentForm.value);
      }
    });
  }
  submitQtns(value) {
    this.loader = true;
    for (let i = 0; i < this.checkedRadioQtns.length; i++) {
      this.questionId.push(this.checkedRadioQtns[i].question_id);
    }
    let mySet = new Set(this.questionId);
    this.questionId = [...mySet];
    for (let i = 0; i < this.questionId.length; i++) {
      var lastIndex = this.checkedRadioQtns
        .map((e) => e.question_id)
        .lastIndexOf(this.questionId[i]);
      this.finalCheckedQtns.push(this.checkedRadioQtns[lastIndex]);
    }
    for (var k = 0; k < this.finalCheckedQtns.length; k++) {
      this.answersObj = {
        question_id: this.finalCheckedQtns[k].question_id,
        answer_id: this.finalCheckedQtns[k].answer_id,
      };
      this.answersArr.push(this.answersObj);
    }
    let obj = {
      email: value.email,
      company_id: this.companyId,
      firstname: value.firstname,
      lastname: value.lastname,
      answerSheet: [],
    };
    obj.answerSheet = this.answersArr;

    var url = 'getAgentTestResult' + '/' + this.companyId;
    this.services.postData(url, obj).subscribe(
      (Object: any) => {
          console.log(Object);
        this.apiReturnedData = Object.data;
        this.loader = false;
        this.visibledom = 'result';
        let filterpercantage =
          (this.apiReturnedData.correct_answer * 100) /
          this.apiReturnedData.total_question;
        if (filterpercantage >= 80) {
          this.messagearea = 'pass';
        } else {
          this.messagearea = 'fail';
        }

        this.apiReturnedAnswers = Object.data.answerSheetWithCorrectAnswer;
        window.scrollBy(0, -280);
        // this.getQuestions();
        document.getElementById('submitbtn').style.display = 'none';
        this.checkedRadioQtns = [];
        this.finalCheckedQtns = [];
        this.questionId = [];
        this.appointmentForm.reset();
        this.answersArr = [];
        this.submitAttempt = false;
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        this.loader = false;
      },
      (err) => {
        console.log(err.error.message);
        this.loader = false;
        window.scrollBy(0, 0);
        document.getElementById('submitbtn').style.display = 'none';
        this.visibledom = 'alreadyattempted';
        this.showalreadytakentest = err.error.message;
        this.testTaken = 'Yes';
      }
    );
  }

  viewResult() {
    this.visibledom = 'results';
    setTimeout(() => {
      this.results();
    }, 10);
  }

  results() {
    this.useranswerd = this.apiReturnedAnswers;
    this.data = this.questions;
    for (var j = 0; j < this.useranswerd.length; j++) {
      if (this.useranswerd[j].user_answer) {
        //user answered Qtns
        if(this.useranswerd[j].correct_answer !=undefined)
        {
            if ( this.useranswerd[j].correct_answer == this.useranswerd[j].user_answer) {
                //  console.log(this.useranswerd[j]);         //correct answer
                let x = <HTMLInputElement>(document.getElementById('question' + this.useranswerd[j].question_id));
                x.classList.add('question-valid');
      
                let y = <HTMLInputElement>( document.getElementById('radio' + this.useranswerd[j].user_answer));
                y.classList.add('answer-valid');
                let l = <HTMLInputElement>(
                  document.getElementById(
                    'radiobtn' + this.useranswerd[j].user_answer
                  )
                );
              
                l.click();
              }
               else {
                //  console.log(this.useranswerd[j]);      //wrong answer
                let y = <HTMLInputElement>(
                  document.getElementById(
                    'question' + this.useranswerd[j].question_id
                  )
                );
                y.classList.add('question-invalid');
                let z = <HTMLInputElement>(
                  document.getElementById(
                    'radio' + this.useranswerd[j].correct_answer
                  )
                );
                z.classList.add('answer-valid');
      
                let x = <HTMLInputElement>(
                  document.getElementById('radio' + this.useranswerd[j].user_answer)
                );
      
                let l = <HTMLInputElement>(
                  document.getElementById(
                    'radiobtn' + this.useranswerd[j].user_answer
                  )
                );
      
                setTimeout(() => {
                  l.click();
                }, 10);
      
                x.classList.add('answer-invalid');
              }
        }
       
      } else {
      }
    }
  }
  retakeTest() {
    window.location.reload();
  }
}
