import { Component, OnInit } from '@angular/core';
import { TestService } from '../services/behaviourService';
declare var $: any;
@Component({
  selector: 'app-what-we-do',
  templateUrl: './what-we-do.component.html',
  styleUrls: ['./what-we-do.component.scss']
})
export class WhatWeDoComponent implements OnInit {

  constructor(private objectService: TestService) { }
  
  currentSection = 'What-we-do';


  onWindowScroll() {
      let element = document.querySelector('.navbar') as HTMLElement;
      if (window.pageYOffset > element.clientHeight) {
        element.classList.add('navbar-inverse');
      } else {
        element.classList.remove('navbar-inverse');
      }
    }
  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  scrollTo(section) {
    
    document.querySelector('#' + section)
    .scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
  ngOnInit(): void {
    
    
 
    this.objectService.pushheaderCondition("showheader");
  }
}
 