import { Component, OnInit } from '@angular/core';
import { TestService } from '../services/behaviourService';
@Component({
  selector: 'app-customlandingpage',
  templateUrl: './customlandingpage.component.html',
  styleUrls: ['./customlandingpage.component.scss']
})
export class CustomlandingpageComponent implements OnInit {

  constructor(
    private objectService: TestService,
  ) { }

  ngOnInit(): void {
    this.objectService.pushheaderCondition("showheader");
  }

}
