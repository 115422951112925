export enum EndpointActionTypes{
    SET_ENVIRONMENT_VARIABLES = "[Endpoint] Set Environment Variables",
    SET_ENVIRONMENT_VARIABLES_SUCCESS = "[Endpoint] Set Environment Variables Success",
    SET_ENVIRONMENT_VARIABLES_FAILURE = "[Endpoint] Set Environment Variables Failure",
    GET_ENVIRONMENT_VARIABLES = "[Endpoint] Get Environment Variables",
    GET_ENVIRONMENT_VARIABLES_SUCCESS = "[Endpoint] Get Environment Variables Success",
    GET_ENVIRONMENT_VARIABLES_FAILURE = "[Endpoint] Get Environment Variables Failure",
}

export enum BillingActionTypes{
    GET_STATES="[Billing] Get States",
    GET_STATES_SUCCESS="[Billing] Get States Success",
    GET_STATES_FAILURE="[Billing] Get States Failure",
}


