<div class="modal fade" id="hc2uDetailsPopup" role="dialog" tabindex="-1" [style.display]="showModal ? 'block' : 'none'"
  aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header d-block">
        <div class="d-flex">
          <img class="img-responsive serviceProviderLogo" src="assets/images/websitehealthc2u.png">
          <h3 class="modal-title titl" id="exampleModalLabel">HealthCare2U</h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <h4 class="modal-title subtitl">Unlimited direct primary care and urgent care, nationwide.</h4>
      </div>
      <div class="modal-body" #mtmDetails>
        <div class="row">
          <p class="desc">
            Healthcare2U provides unlimited primary and urgent care, nationwide. Its integrated model redefines the
            delivery of healthcare by providing a service for the early detection, treatment, and management of acute
            and chronic conditions before the onset of serious illness. The non-insurance healthcare membership provides
            members with 24/7/365 access to a comprehensive suite of integrated benefits across the U.S.
          </p>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <ul class="no-bullets">
              <li class="servicesHdr">Services Include:</li>
              <li>Patient Navigation</li>
              <li>No-Claims Healthcare™</li>
              <li>Unlimited In-Office Doctor Access</li>
              <li>Unlimited Urgent Care</li>
              <li>Preventative Care</li>
              <li>Unlimited Chronic Care</li>
              <li>Unlimited Virtual DPC</li>

            </ul>
          </div>
          <div class="col-sm-6"></div>
        </div>
        <div class="">
          <p class="extras">Extra Benefits are Provided Though:</p>
          <a class="lnk" href="https://wellcardsavings.com/Default.aspx">https://wellcardsavings.com/Default.aspx</a>
        </div>
        <div class="row">
          <p class="dsc">
            Members of HC2U will be given access to the Health Wallet mobile app where they can access all of the HC2U
            and WellCard Savings ® benefits from their smart device!
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-sm-6 text-left">
            <button type="button" class="btn btn-danger" style="float:left" #closebutton
              data-dismiss="modal">Close</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
