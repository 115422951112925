import { Component, OnInit } from '@angular/core';
import { TestService } from '../services/behaviourService';
@Component({
  selector: 'app-learnmore',
  templateUrl: './learnmore.component.html',
  styleUrls: ['./learnmore.component.scss']
})
export class LearnmoreComponent implements OnInit {

  constructor(   private objectService: TestService,) { }

  ngOnInit(): void {
    this.objectService.pushheaderCondition("showheader");
  }

}
