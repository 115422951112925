import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TestService } from '../services/behaviourService';
import { GetStatesAction } from '../store/actions/states.actions';

@Component({
    selector: 'payment-info-container',
    template: `<payment [applicantData]="applicantData | async"></payment>`
})
export class PaymentInfoContainerComponent implements OnInit {

    applicantData: Observable<any>;

    constructor(private objectService: TestService, private store : Store<any>) {
       this.applicantData = this.objectService.demographyObject;
       this.store.dispatch(new GetStatesAction());
    }

    ngOnInit(): void {
    }

}
