import { BrowserModule,Title } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { DemographicsDependentsComponent } from './demographics-dependents/demographics-dependents.component';
import { HealthBasedQuestionsComponent } from './health-based-questions/health-based-questions.component';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';
import { SummaryComponent } from './summary/summary.component';
import { ConfigService } from '../app/services/config.service';
import { appInitializerFn } from '../app/app-initializer';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { CalendarModule } from 'primeng/calendar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { ToastrModule } from 'ngx-toastr';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { WhatWeDoComponent } from './what-we-do/what-we-do.component';
import { WhoWeAreComponent } from './who-we-are/who-we-are.component';
import { ResourcesComponent } from './resources/resources.component';
import { GetInTouchComponent } from './get-in-touch/get-in-touch.component';
import { TermsofuseComponent } from './termsofuse/termsofuse.component';
import { StatespecificnoticesComponent } from './statespecificnotices/statespecificnotices.component';
import { DemographicComponent } from './A2C/demographic/demographic.component';
import { PaymentComponent } from './A2C/payment/payment.component';
import { HealthbasedquestionsComponent } from './A2C/healthbasedquestions/healthbasedquestions.component';
import { SummaryDetailsComponent } from './A2C/summary-details/summary-details.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { LearnmoreComponent } from './learnmore/learnmore.component';
import { EmployerGroupComponent } from './employer-group/employer-group.component';
import { RegistrationFormComponent } from './registration-form/registration-form.component';
import { CustomlandingpageComponent } from './customlandingpage/customlandingpage.component';
import { GlossaryComponent } from './Glossary/glossary.component';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { AdvOpinionEligibilityComponent } from './adv-opinion-eligibility/adv-opinion-eligibility.component';
import { ResetmemberpasswordComponent } from './resetmemberpassword/resetmemberpassword.component';
import { ScrollSpyDirective } from './scroll-spy.directive';
import { FaqComponent } from './faq/faq.component';
import { BlogComponent } from './blog/blog.component';
import { BlogdetailComponent } from './blogdetail/blogdetail.component'
import { MembershipComponent } from './membership/membership.component';
import { MembershipGuidelineComponent } from './membership-guideline/membership-guideline.component';
import { FormDocumentComponent } from './form-document/form-document.component';
import { ProgramBrochureComponent } from './program-brochure/program-brochure.component';
import { PaymentInfoModule } from './payment-info/payment-info.module';
import { httpInterceptorproviders } from './dataservices/http-Interceptors';
import { NgrxStoreModule } from './store/store.module';
import { ApplicationcartComponent } from './applicationcart/applicationcart.component';
import { AddOnProductsComponent } from './add-on-products/add-on-products.component';
import { MtmDetailsComponent } from './add-on-products/mtm-details/mtm-details.component';
import { Hc2uDetailsComponent } from './add-on-products/hc2u-details/hc2u-details.component';
import { AppointmenttestComponent } from './appointmenttest/appointmenttest.component';
import { 
  NgxAwesomePopupModule, 
  ConfirmBoxConfigModule 
} from '@costlydeveloper/ngx-awesome-popup';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    DemographicsDependentsComponent,
    HealthBasedQuestionsComponent,
    PaymentDetailsComponent,
    SummaryComponent,
    ThankyouComponent,
    PagenotfoundComponent,
    HowItWorksComponent,
    WhatWeDoComponent,
    WhoWeAreComponent,
    ResourcesComponent,
    GetInTouchComponent,
    TermsofuseComponent,
    StatespecificnoticesComponent,
    DemographicComponent,
    PaymentComponent,
    HealthbasedquestionsComponent,
    SummaryDetailsComponent,
    PrivacyPolicyComponent,
    SignUpComponent,
    LearnmoreComponent,
    EmployerGroupComponent,
    RegistrationFormComponent,
    CustomlandingpageComponent,
    GlossaryComponent,
    AdvOpinionEligibilityComponent,
    ResetmemberpasswordComponent,
    ScrollSpyDirective,
    FaqComponent,
    BlogComponent,
    BlogdetailComponent,
    MembershipComponent,
    MembershipGuidelineComponent,
    FormDocumentComponent,
    ProgramBrochureComponent,
    ApplicationcartComponent,
    AddOnProductsComponent,
    MtmDetailsComponent,
    Hc2uDetailsComponent,
    AppointmenttestComponent,
  ],
  imports: [BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
    PaymentInfoModule,
    NgrxStoreModule,
    ToastrModule.forRoot(),
    BackButtonDisableModule.forRoot({
      preserveScrollPosition: true
    }),
    BrowserModule,NgxAwesomePopupModule.forRoot(), 

    ConfirmBoxConfigModule.forRoot()
  ],
  providers: [ConfigService,
    httpInterceptorproviders,
    Title,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [ConfigService]
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
