<div class="container">
   <section id="two" class="wrapper style3 fade-up">
      <div class=" mb-9">
         <h1>
            Glossary of Terms
         </h1>
         <strong>A</strong>
         <h2>ACKNOWLEDGMENTS:</h2>
         <p>

         </p> Your agreement that you understand and accept all of the statements and conditions described in the
         Acknowledgements section of the Fellowship HealthShare Membership Enrollment Application.
         <h2>AFFILIATED PROVIDER:</h2>
         <p>


            Any healthcare provider that is within the American Provider Community (APC)or that works in conjunction
            with your Fellowship HealthShare Membership to provide services and/or care to you.
         </p>
         <h2>ALLOWED AMOUNT:</h2>
         <p>
            The maximum amount the Membership will share for an eligible medical need. This may also be referred to as
            the negotiated amount.
         </p>
         <h2>APPLIED TO:</h2>
         <p>
            This is when your Member Responsibility Amount is reduced by an amount charged for an eligible medical need,
            but you are responsible for paying it.
         </p>
         <h2>
            AUTO DENIAL:
         </h2>
         <p>
            A situation or condition that would render you ineligible to join the Membership or if later discovered,
            would render you ineligible to remain an active Member.
         </p>


         <strong>B</strong>

         <h2>
            BALANCE BILL:
         </h2>
         <p>
            A practice in which doctors or other healthcare providers bill you for charges that exceed the allowed
            amount.
         </p>

         <strong>c</strong>

         <h2>
            CPT/HCPCS FOR REV CODES A CPT (Current Procedural Terminology) Code:
         </h2>
         <p>
            is a code set to describe medical, surgical ,and diagnostic services; HCPCS (Healthcare Common Procedure
            Coding System) are codes based on the CPT to provide standardized coding when healthcare is delivered. UB-04
            claims must be billed with both a revenue code and a CPT or HCPCS code. Revenue (REV) codes are descriptions
            and dollar amounts charged for hospital services provided to a patient. The revenue code describes whether
            the procedure was performed in the emergency room, operating room, or another department. If multiple CPT or
            HCPCS are necessary to reflect multiple, distinct, or independent visits with the same revenue code, repeat
            the revenue code as required.
         </p>

         <h2>
            CALENDAR YEAR:
         </h2>
         <p>
            A calendar year is January 1st through December 31st of every year.
         </p>
         <h2>
            CASE MANAGEMENT:
         </h2>
         <p>
            A collaborative process available through
            Fellowship Health Share to help you assess your eligibility, and to assist you with planning, choosing, and
            coordinating your best possible care.

         </p>
         <h2>
            COMBINED MEMBERSHIP:
         </h2>
         <p>
            A Member plus one or more qualified dependents participating in Fellowship HealthShare under the same
            Membership.A Member plus one or more qualified dependents participating in Fellowship HealthShare under the
            same Membership.
         </p>


         <h2>
            COMMITMENTS:
         </h2>
         <p>
            The requirements you acknowledge you must follow in order to maintain an active membership in Fellowship
            HealthShare.
         </p>

         <h2>
            COMPLAINANT:
         </h2>
         <p>
            Also referred to as a grievance, a complainant is the person who filed the grievance, including the Member,
            a representative designated by the Member, or other individual with authority to act on behalf of the
            Member.
         </p>


         <strong>D</strong>
         <h2>
            DATE OF SERVICE (DOS):
         </h2>
         <p>
            The date medical services were rendered for you.
         </p>
         <h2>
            DEPENDENT:
         </h2>
         <p>
            Your spouse and/or any of your unmarried children (by birth, legal adoption, or marriage) through the age of
            22, whom you have included on a Membership Enrollment Application.
         </p>


         <strong>E</strong>
         <h2>
            EFFECTIVE DATE:
         </h2>
         <p>
            The date your Membership and limitations begin.
         </p>
         <h2>
            ELIGIBLE:
         </h2>
         <p>
            A status indicating that you have met the conditions that qualify for sharing as described in the Membership
            Guidelines, and your medical needs fall within the sharing limits.
         </p>

         <h2>
            ELIGIBLE MEDICAL NEED:
         </h2>
         <p>
            A medical need that meets all the conditions to qualify for sharing and falls within the sharing limits. In
            addition, all required documentation has been submitted by the Provider and/or the Member in order to
            determine eligibility.
         </p>

         <h2>
            ELIGIBILITY COMMITTEE:
         </h2>
         <p>
            A committee composed of Executives that serves as your impartial advocates to review and assess the
            eligibility of disputed, denied or appealed Member needs.
         </p>

         <h2>
            EMERGENCY:
         </h2>
         <p>
            An emergency is defined as treatment that must be rendered to the patient immediately for the alleviation of
            the sudden onset of an unforeseen illness or injury that, if not treated, would lead to further disability
            or death.
         </p>

         <h2>
            ENROLLMENT DATE:
         </h2>
         <p>
            The date when Fellowship HealthShare receives your completed Membership Enrollment Application.
         </p>
         <h2>
            EOS (EXPLANATION OF SHARING):
         </h2>
         <p>
            A statement sent to you and your providers once your sharing of medical needs has been processed, are
            pending, or are denied. This statement specifies the amount you owe—your Member Responsibility Amount
            (MRA)—and the amounts that were shared by the Membership Community.
         </p>

         <h2>
            ESCROW INSTRUCTIONS:
         </h2>
         <p>
            Authorized detailed instructions given to Fellowship HealthShare to manage the Community escrow account as
            the escrow agent.
         </p>

         <h2>
            EXCESSIVE:
         </h2>
         <p>
            Defined as the use of a substance resulting in a medical need.
         </p>
         <h2>
            EXTREME SPORTS:
         </h2>
         <p>
            Activities perceived as having a high level of dan- ger, often involving speed, height, a high level of
            physical exertion, and specialized gear.
         </p>


         <strong>G</strong>
         <h2>
            GAAP:
         </h2>
         <p>
            Generally accepted accounting principles, or GAAP, are a set of rules that encompass the details,
            complexities, and legalities of business and corporate accounting. The Financial Accounting Standards Board
            (FASB) uses GAAP as the foundation for its comprehensive set of approved accounting methods and practices.
            (https://www.accounting.com/resources/gaap/)
         </p>
         <h2>GAAS:

         </h2>
         <p>
            Generally accepted auditing standards (GAAS) are a set of systematic guidelines used by auditors when
            conducting audits on companies’ financial records. GAAS helps to ensure the accuracy, consistency, and
            verifiability of auditors’ actions and reports. (https://tinyurl.com/yxdgoqo9)
         </p>

         <h2>
            GRIEVANCE:
         </h2>
         <p>
            A written or oral expression of dissatisfaction regarding the Membership Community or the Program you
            selected and may include a complaint, dispute, request for reconsideration or appeal made by a Member or the
            Member’s representative to Fellowship HealthShare.
         </p>



         <strong>H</strong>
         <h2>
            HEAD OF HOUSEHOLD:
         </h2>
         <p>
            The oldest participating Member in your household, whether you are an individual Member with no dependents,
            a husband or father, a wife or mother, or a child.

         </p>
         <h2>
            HOUSEHOLD:
         </h2>
         <p>
            If you are an individual Member with no dependents, it is you. If you are a Member or a dependent, it is the
            members of your family group who have been accepted to a combined Membership.

         </p>

         <strong>I</strong>
         <h2>
            ILLICIT DRUGS:
         </h2>
         <p>
            Drugs which are classified as Class 1 in Title 21 United States Code Controlled Substances Act.

         </p>
         <h2>
            INELIGIBLE:
         </h2>
         <p>
            A status indicating that you have failed to meet the conditions that qualify for sharing as described in the
            Membership Guidelines, or that your medical needs do not fall within the sharing limits.
         </p>

         <h2>
            INELIGIBLE MEDICAL NEEDS:
         </h2>
         <p>
            A status indicating that you have failed to meet the conditions that qualify for sharing as described in the
            Membership Guidelines, or that your medical needs do not fall within the sharing limits.

         </p>

         <strong>L</strong>
         <h2>
            LEGAL REPRESENTATIVE:
         </h2>
         <p>
            Any adult who has decision-making capacity and who is willing to act on behalf of a Member. A legal
            representative would include an individual who has authority, by law or by agreement from the individual
            receiving treatment, to act in the place of the individual. This includes parents, legal guardians, or
            properly appointed agents, such as those identified in Power of Attorney documents, or individuals
            designated by state law.
         </p>

         <h2>
            LICENSED MEDICAL PROFESSIONAL:
         </h2>
         <p>
            An individual who has successfully completed a prescribed program of study in a healthcare field and who has
            obtained a license to practice in that field. Some examples of licensed medical professionals are doctors,
            nurses, chiropractors, physical therapists, and physician assistants.
         </p>

         <h2>
            AFFILIATED:
         </h2>
         <p>
            An indication that your health care facility or licensed medical professional has been approved by
            Fellowship HealthShare to receive shared contributions for your eligible medical needs.
         </p>

         <h2>
            NON-AFFILIATED:
         </h2>
         <p>
            A health care provider that is not part of the Fellowship HealthShare network, is not an affiliated
            provider, or does not accept Medicare plus 50% reimbursement.

         </p>
         <strong>M</strong>

         <h2>
            MATERNITY:
         </h2>
         <p>
            A mother’s or child’s medical needs relating to prenatal care and newborn delivery, including routine
            hospital expenses for your newborn child.
         </p>
         <h2>
            MEDICAL NEEDS:
         </h2>
         <p>
            Charges or expenses for medical services that are provided to you by a facility or by a licensed medical
            professional to ad- dress your illnesses, accidents, injuries, or routine medical needs.
         </p>
         <h2>
            MEDICAL REVIEW:
         </h2>
         <p>
            The process by which licensed medical professionals review medical records to make eligibility
            determinations in accordance with the Membership Guidelines.
         </p>
         <h2>
            MEDICAL TOURISM:
         </h2>
         <p>
            The act of intentionally traveling to another country for the specific purpose of having medical care
            performed outside the United States.
         </p>
         <h2>
            MEDICALLY NECESSARY:
         </h2>
         <p>
            A service, procedure, or medication that is necessary to restore or maintain your physical health in the
            most cost-effective way.

         </p>
         <h2>
            MEMBER:
         </h2>
         <p>
            A person or persons enrolled in the Fellowship HealthShare Community(whether you are a member or a qualified
            dependent)
         </p>
         <p>
            - ACTIVE MEMBER Your status when you have met all Membership obligations, providing you remain eligible for
            sharing of medical needs.
         </p>
         <p>
            - INACTIVE MEMBER Your status when you have failed to meet Membership obligations, making you ineligible for
            sharing of medical needs.
         </p>
         <p>



            - MARRIED INDIVIDUAL MEMBER Your status when you have met all Membership requirements to qualify for an
            eligible maternity on a Membership that allows it, with proof of marriage certificate.

         </p>
         <h2>
            MEMBERSHIP PROGRAM:
         </h2>
         <p>
            Choice, Essential, Basic sharing options that are available with different Member Responsibility Amounts
            (MRAs) and sharing limits, as selected and approved on your Membership Enrollment Application.

         </p>
         <h2>
            MEMBERSHIP COMMUNITY:
         </h2>
         <p>
            The individuals and families that make up the Fellowship of like-minded, health conscious people.

         </p>
         <h2>
            MEMBER APPEAL:
         </h2>
         <p>
            A request to reconsider an initial denial decision of clinical services that were requested but had not yet
            occurred.

         </p>
         <h2>
            MEMBER PORTAL:
         </h2>
         <p>
            Your personal online Membership access where you can manage your Membership.
         </p>
         <h2>
            MEMBER RESPONSIBILITY AMOUNT (MRA):
         </h2>
         <p>
            The portion of an eligible medical need that does not qualify for sharing and that is your obligation to
            pay.
         </p>
         <p>
            - ADOPTION MRA With Choice and Essential Membership Programs, the portion of charges for an eligible
            adoption that is the Members obligation to pay before the Membership Community shares in needs.
         </p>
         <p>
            - CANCER MRA With Choice and Essential Membership Programs, the portion of charges for eligible cancer
            treatment services that is the Members obligation to pay before the Membership Community shares in medical
            needs.
         </p>

         <p>
            - FIRST MEMBER RESPONSIBILITY AMOUNT (1ST MRA) The amount you are responsible for payment to the physician
            or facility before the Membership Community shares in eligible medical needs.
         </p>
         <p>
            - LABORATORY MRA With Choice and Essential Membership Programs, the portion of charges for eligible
            laboratory services that is the Members obligation to pay before the Membership Community shares in medical
            needs.
         </p>
         <p>
            - MATERNITY MRA With Choice and Essential Membership Programs, the portion of charges for an eligible
            maternity that is the Member’s obligation to pay before the Membership Community shares in medical needs.
         </p>
         <p>
            - SECOND MEMBER RESPONSIBILITY AMOUNT (2ND MRA) The percentage you are responsible for paying after the
            First Member Responsibility Amount (1st MRA) is met. The Membership Community shares simultaneously in your
            eligible medical needs as your 2nd MRA is being met.
         </p>
         <p>
            - OFFICE VISIT MRA/URGENT CARE MRA A contribution of $35 that is applicable for Choice and Essential
            Membership Programs, before Membership Community sharing takes place.

         </p>
         <h2>
            MEMBERSHIP ENROLLMENT APPLICATION:
         </h2>
         <p>
            An electronic application that you must complete and accept all Acknowledgments to, in order to enroll in
            Fellowship HealthShare for Membership.

         </p>
         <h2>MEMBERSHIP FORMS:</h2>
         <p>
            An electronic form used by the Membership Community.
         </p>
         <h2>
            MEMBERSHIP COMMITMENT FORM:
         </h2>
         <p>
            An electronic form you must complete and provide annually to Fellowship HealthShare to demonstrate your
            commitment to the Membership Community, Acknowledgments, Statement of Standards, Commitments, and the Escrow
            Instructions

         </p>
         <h2>
            MEMBERSHIP NEEDS PROCESSING FORM (NPF):
         </h2>
         <p>
            An electronic form you must complete and provide to Fellowship HealthShare to request eligibility for
            sharing of your medical needs.

            - MEMBERSHIP UPDATE FORM An electronic form you must complete and provide to Fellowship HealthShare when
            details of your membership change.

         </p>
         <h2>
            MEMBERSHIP GUIDELINES:
         </h2>
         <p>
            Your reference for acknowledging your commitments, assessing your eligible and ineligible medical needs, and
            understanding how contributions are shared in accordance with the Escrow Instructions.

         </p>
         <h2>
            MEMBERSHIP LIMITATION:
         </h2>
         <p>
            A two- or five-year waiting period or lifetime limitation on the eligibility for sharing of medical needs,
            or associated medical conditions, eligible for sharing. An associated condition is one that is caused
            directly and primarily by the medical condition that is specifically ineligible. Membership limitations can
            be placed at any time based on the information you, your physician, or facility provides and may be subject
            to medical record review.

         </p>

         <strong>R</strong>
         <h2>
            RETROACTIVE LIMITATION:
         </h2>
         <p>
            A two or five-year waiting period or lifetime limitation on the eligibility for sharing of medical needs or
            associated medical needs for an illness or medical condition for which you have received medical advice or
            treatment at any time during the 10-year look back period preceding your membership effective date. This
            limitation will be retroactive to your membership effective date.
         </p>

         <h2>MONTHLY CONTRIBUTIONS:</h2>
         <p>
            The money you contribute each month for sharing among the Fellowship HealthShare Members.
         </p>



         <strong>N</strong>
         <h2>
            NOTICE OF ACTION (NOA):
         </h2>
         <p>
            A formal letter telling Members that a medical service or medical need has been denied, deferred, or
            modified (such as a denial letter).

         </p>

         <strong>O</strong>
         <h2>
            OFFICE VISIT:
         </h2>
         <p>
            A visit to a doctor’s office or urgent care facility to ad- dress your illness, your specialty medical need,
            your emergency, or to obtain your preventative care (for example, when you schedule a wellness visit).

         </p>

         <strong>P</strong>
         <h2>
            PRE-EXISTING CONDITION:
         </h2>
         <p>
            An illness or medical condition for which you have received medical advice or treatment at any time during
            the ten-year look back period preceding your membership effective date that would result in a two-year,
            five-year or lifetime limitation.

         </p>
         <h2>
            PRIMARY MEMBER:
         </h2>
         <p>
            The member other than an affiliate member and who shall therefore be primarily accountable to Fellowship
            HealthShare.

         </p>
         <strong>R</strong>

         <h2>
            RECREATIONAL VEHICLE:
         </h2>
         <p>
            A licensed or unlicensed motor vehicle operated on land or water, or a licensed motor vehicle with less than
            four wheels.

         </p>
         <h2>
            REQUESTED DUE DATE:
         </h2>
         <p>
            The date of each month that you choose as a Member of Fellowship HealthShare to make your monthly
            contributions.

         </p>

         <strong>S</strong>
         <h2>
            SHARING:
         </h2>
         <p>
            The process in which the Membership Community shares any eligible medical needs.
         </p>
         <h2>
            SHARING LIMITS:
         </h2>
         <p>
            The amount(s) the Membership Community will share on your behalf.
         </p>

         <h2>
            ANNUAL LIMIT:
         </h2>
         <p>
            The maximum amount shared for eligible medical needs per Member, each calendar year. The calendar year
            starts on January 1st and continues through December 31st.
         </p>

         <h2>LIFETIME LIMITATION:</h2>
         <p>
            The maximum amount shared for eligible medical needs over your lifetime of membership.

         </p>
         <h2>
            STATEMENT OF ETHICS:
         </h2>
         <p> The religious and moral philosophy that you agree to live by during your membership.
         </p>

         <strong>T</strong>
         <h2>
            TELEMEDICINE:
         </h2>
         <p>
            A program that allows you 24/7/365 access to remote medical services via real-time, two-way communication
            with a contract- ed network of third-party telemedicine providers.

         </p>

         <strong>U</strong>

         <h2>
            USUAL, CUSTOMARY, AND REASONABLE (UCR):
         </h2>
         <p>
            The allowed
            amount for a medical service in a geographic area based on what providers in the area usually charge for the
            same or similar medical service.

         </p>

         <strong>W</strong>
         <h2>
            WAITING PERIOD:
         </h2>
         <p>
            A period of time from the Membership effective date that a Member must wait before specific medical needs
            are eligible for sharing.
         </p>

         <h2>
            WITHDRAWN:
         </h2>
         <p>
            When a membership is cancelled upon your request or when you have failed to meet your membership
            obligations.
         </p>



         <strong>Y</strong>
         <h2>
            YOU OR YOUR:
         </h2>
         <p>
            means an individual or household exercising rights under and complying with the terms of the Membership
            Guidelines and Commitment Statement.
         </p>



      </div>
   </section>
</div>