import { Component, OnInit } from '@angular/core';
import { TestService } from '../services/behaviourService';

@Component({
  selector: 'app-statespecificnotices',
  templateUrl: './statespecificnotices.component.html',
  styleUrls: ['./statespecificnotices.component.scss']
})
export class StatespecificnoticesComponent implements OnInit {

  constructor(private objectService: TestService) { }

  ngOnInit(): void {
    this.objectService.pushheaderCondition("showheader");
  }

}
