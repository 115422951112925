<div class="modal fade" id="detailsPopup" role="dialog" tabindex="-1" [style.display]="showModal ? 'block' : 'none'"
  aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header d-block">
        <div class="d-flex">
          <img class="img-responsive serviceProviderLogo" src="assets/images/mtm.png">
          <h3 class="modal-title header" id="exampleModalLabel">My Telemedicine</h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <h4 class="modal-title sub-header">Access to Virtual Care and Discounted Medical Programs.</h4>
      </div>
      <div class="modal-body " #mtmDetails>
        <div class="pd-20">
          <div class="row bg-white  ">
            <div class="col-sm-3 flex service-box-shadow">
              <div class="center">
                <img class="img-border max-width-9 mb-4 br-50" src="assets/img/icon-telehealth_0.png"
                  alt="Image Description">
                <h4 class="h6 text-dark mb-1">Telehealth</h4>
              </div>
            </div>
            <div class="col-sm-9 ">
              <p>
                Telehealth saves time and money. We provide you unparalleled services that will help you avoid busy
                waiting rooms and save your heard-earned dollars on office visits, urgent care and the ER. Fellowship
                Members have 24/7/365 access to quality physicians through their phone.</p>
              <p> Virtual consultations enable physicians to diagnose numerous common conditions and recommend treatment
                plans and/or write prescriptions that they may send to the pharmacy of your choosing.
              </p>
            </div>
          </div>
        </div>
        <div class="pd-20">
          <div class="row bg-white  ">
            <div class="col-sm-3 flex  service-box-shadow">
              <div class="center">
                <img class=" img-border max-width-9" src="assets/img/vision2.png" alt="Image Description">
                <h4 class="h6 text-dark">Vision</h4>
              </div>
            </div>
            <div class="col-sm-9 ">
              <p>
                This vision program offers members 10% to 60% off eyeglasses, contact lenses and other retail eyewear
                items as well as 10% to 30% off eye examinations and 40% to 50% off the national average on LASIK. Over
                20,000 eye care professionals nationwide – including many local opticians – participate in this Benefit.
              </p>
              <p>
                Some Major Participating Eye Care Professionals Include:
              </p>
              <ul>
                <li>
                  For Eyes Optical</li>
                <li>
                  JCPenney</li>
                <li> LensCrafters</li>
                <li> Pearle Vision</li>
                <li> Sterling Optical</li>
                <li> Target Optical</li>
              </ul>
              <p>
                May not be used in conjunction with other discounts or insurance.
              </p>
              <h5 class="red">THIS IS NOT INSURANCE</h5>
            </div>
          </div>
        </div>
        <div class="pd-20">
          <div class="row bg-white ">
            <div class="col-sm-3 flex service-box-shadow">
              <div class="center">
                <img class="img-border max-width-9" src="assets/img/dentistry.png" alt="Image Description">
                <h4 class="h6 text-dark">Dental</h4>
              </div>
            </div>
            <div class="col-sm-9 ">
              <p>
                Members can save 15% to 50%* per visit, in most instances, on services at any of the many available
                dental practice locations nationwide.
              </p>
              <p>
                Dental services include:
              </p>
              <ul>
                <li>
                  Cleanings</li>
                <li>
                  X-rays</li>
                <li> Fillings</li>
                <li> Root Canals</li>
                <li> Crowns</li>
              </ul>
              <p>
                The discount program provides access to the Aetna Dental Access® network. This network is administered
                by Aetna Life Insurance Company (ALIC). Neither ALIC nor any of its affiliates offers or administers the
                discount program. Neither ALIC nor any of its affiliates is an affiliate, agent, representative or
                employee of the discount program. Dental providers are independent contractors and not employees or
                agents of ALIC or its affiliates. ALIC does not provide dental care or treatment and is not responsible
                for outcomes.
              </p>
              <p>* Actual costs and savings vary by provider, service and geographical area.</p>
              <h5 class="red">THIS IS NOT INSURANCE</h5>
            </div>
          </div>
        </div>
        <div class="pd-20">
          <div class="row bg-white ">
            <div class="col-sm-3 flex service-box-shadow">
              <div class="center">
                <img class="img-border max-width-9 mb-4" src="assets/img/chripotic1.png" alt="Image Description">
                <h4 class="h6 text-dark mb-1">Chiropractic</h4>
              </div>
            </div>
            <div class="col-sm-9 ">
              <p>
                Millions of Americans rely on chiropractic care to reduce pain and stress and to function more
                productively. Members can save 20% to 40% on chiropractic fees at participating chiropractors across the
                country.</p>
              <p> While our provider lists are continually updated, provider status can change. We recommend that you
                confirm the provider you selected participates in the program when scheduling your appointment.</p>
              <p>Cannot be used in conjunction with any other discount program.</p>
              <h5 class="red">THIS IS NOT INSURANCE</h5>
            </div>
          </div>
        </div>
        <div class="pd-20">
          <div class="row bg-white ">
            <div class="col-sm-3 flex service-box-shadow">
              <div class="center">
                <img class="img-border max-width-9 mb-4 br-50" src="assets/img/labs2.jpg" alt="Image Description">
                <h4 class="h6 text-dark mb-1">Labs</h4>
              </div>
            </div>
            <div class="col-sm-9 ">
              <p>
                Serious medical conditions can go undetected for years without noticeable symptoms. The earlier a
                problem is detected, the easier and more likely it is to be treatable. DirectLabs® is the leader in
                direct access laboratory testing and provides access to major clinical labs nationwide. Confidential
                results are available online in as little as 24 hours for most tests.</p>
              <h5 class="red">THIS IS NOT INSURANCE</h5>
            </div>
          </div>
        </div>
        <div class="pd-20">
          <div class="row bg-white ">
            <div class="col-sm-3 flex service-box-shadow">
              <div class="center">
                <img class="img-border max-width-9 mb-4" src="assets/img/imaging-benifits.png" alt="Image Description">
                <h4 class="h6 text-dark mb-1">Imaging Benefits</h4>
              </div>
            </div>
            <div class="col-sm-9 ">
              <h5>Imaging</h5>
              <p>Members can save on imaging services such as MRIs, CT Scans, and XRays. A Service Representative will
                assist Members in finding the lowest
                price on procedures at a participating network provider and confirm the
                Member’s out of pocket responsibility before appointment scheduling. Our
                network providers can save members an average of 40% - 75% less than the
                usual costs for advanced radiology testing.
                <!-- <a   target="_blank" href="assets/document/Imaging.pdf">Read More</a> -->
              </p>
              <p>This Program cannot be used in conjunction with insurance. Providers may
                not be available in all areas. Savings may vary based on geographic
                location, provider and procedure performed. Available services may vary by
                provider.</p>
              <h5 class="red">THIS IS NOT INSURANCE</h5>
            </div>
          </div>
        </div>
        <div class="pd-20">
          <div class="row bg-white  ">
            <div class="col-sm-3  service-box-shadow">
              <div class="center">
                <img class="img-border max-width-9 mb-4" src="assets/img/home-supplies.jpg" alt="Image Description">
                <h4 class="h6 text-dark mb-1">Diabetic & Home Supplies</h4>
              </div>
            </div>
            <div class="col-sm-9 ">
              <p>
                Members enjoy fast, easy access to reliable and affordable diabetic, health and wellness, and pet health
                supplies. Save an additional 10% on products by ordering online or by phone.<br>
                Members can save on many items:</p>
              <ul>
                <li> Diabetic Supplies (test strips, insulin pump supplies, lancets and more)</li>
                <li> Home Medical Supplies (bath seats, walkers, and more)</li>
                <li>Pet Health Supplies (pet diabetic supplies, pet care, and pet food)</li>
                <li> Health and Wellness Supplies (blood pressure monitors, incontinence products, baby formula and much
                  more)</li>
              </ul>
              <h5 class="red">THIS IS NOT INSURANCE</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-sm-6 text-left">
            <button type="button" class="btn btn-danger" style="float:left" #closebutton
              data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
