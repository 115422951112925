export class PaymentType {
    public static CREDIT_CARD = 'credit_card';
    public static DEBIT_CARD = 'debit_card';
    public static BANK_ACH = 'bank';
    public static CHECKING = 'checking';
    public static SAVINGS = 'savings';
    public static CARD = 'card';
}

export class PaymentGroup {
    public static CARD = [
        { name: 'Credit Card', value: PaymentType.CREDIT_CARD },
        { name: 'Debit Card', value: PaymentType.DEBIT_CARD },
        { name: 'Bank ACH', value: PaymentType.BANK_ACH },
    ]
    public static BANK = [
        { name: 'Checking', value: PaymentType.CHECKING },
        { name: 'Savings', value: PaymentType.SAVINGS },
        { name: 'Credit/Debit Card', value: PaymentType.CARD },
    ]
}

