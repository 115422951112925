import { Component, OnInit } from '@angular/core';
import { TestService } from '../services/behaviourService';
import { Router } from '@angular/router';
import { SharingService } from '../services/sharing.service';
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  companyId: any;
  blogList: any[] = [];

  constructor(private objectService: TestService, private router: Router, private sharingService: SharingService) {
    this.sharingService.clientData.subscribe((data) => {
      if (data != '') {
        
        this.companyId =data.company_id
        this.getBlogList();
      }
    })
  }

  ngOnInit(): void {
    this.objectService.pushheaderCondition("showheader");
  }
  getBlogList() {
    var url = 'GetBlogList_sharing' + '/' + this.companyId
    this.sharingService.getData(url).subscribe((Object: any) => {
      if (Object !== null) {
        this.blogList = Object.data;
      }
    }, err => {
      console.log(err)
    })
  }
  onNavigate(item) {
    this.sharingService.pushBlogdetails(item);
    this.router.navigate(['/blogdetail']);
  }

}
