import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private config: any;
  constructor(private http: HttpClient) {

  }

  async loadConfig() {
    const response = await this.http.get("./config.json").toPromise();
    this.config = response;
  }

  getConfig() {
    return this.config;
  }
}
