import { StatesAction } from "../actions/states.actions";
import { BillingActionTypes } from "../state/state";

export interface StateReducerState {
    isLoading: boolean;
    isLoaded: boolean;
    error: string | any;
    states: any;
}

const initialState: StateReducerState = {
    isLoading: false,
    isLoaded: false,
    error: '',
    states: []
}

export function StateReducer(state: StateReducerState = initialState, action: StatesAction) {
    switch (action.type) {
        case BillingActionTypes.GET_STATES:
            {
                return {
                    ...state,
                    isLoading: true
                }
            }
        case BillingActionTypes.GET_STATES_SUCCESS:
            {
                return {
                    ...state,
                    isLoading: false,
                    isLoaded: true,
                    states: action.payload
                }
            }
        case BillingActionTypes.GET_STATES_FAILURE: {
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
                error: action.payload
            }
        }
        default:
            return state;
    }
}