import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ConfigService } from '../services/config.service';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SharingService {

  options: any;
  questionslist: any[] = [];
  previousQuestions: any[] = [];
  number = 0;
  orderDetails: any[] = [];
  initialcheck = 'front';
  selectedRadioQuestions: any[] = [];
  selectedcheckedQuestions: any[] = [];
  demographic = 'hideD2c';

  private client = new BehaviorSubject<any>('');
  clientData = this.client.asObservable();
  loadData: any;
  base_Url: any;
  apiKey: any;
  companyId: any;
  blogDetails: any;
  agentGroupdata: any;
  constructor(public http: HttpClient, private config: ConfigService) {
  }

  getClientData(data: any) {

    this.base_Url = data.apiURL;
    this.apiKey = data.api_Key;
    this.companyId = data.company_id;
    this.client.next(data);
  }

  baseApiUrl() {
    return this.base_Url;
  }


  getData(url) {
    this.options = {
      headers: new HttpHeaders({
        'X-Authorization': this.apiKey,
        'X-Company': this.companyId
      })
    };
    let apiUrl = this.base_Url + url;
    return this.http.get(apiUrl, this.options).pipe(map(res => res));
  }
  postData(url, body) {
    this.options = {
      headers: new HttpHeaders({
        'X-Authorization': this.apiKey,
        'X-Company': this.companyId
      })
    };
    let apiUrl = this.base_Url + url;
    return this.http.post(apiUrl, body, this.options).pipe(map(res => res));
  }
  putData(url, body) {
    this.options = {
      headers: new HttpHeaders({
        'X-Authorization': this.apiKey,
        'X-Company': this.companyId
      })
    };
    let apiUrl = this.base_Url + url;
    return this.http.put(apiUrl, body, this.options)
  }
  pushQuestionsList(data) {
    this.questionslist = data;
  }
  getQuestionsList() {
    return this.questionslist;
  }
  pushCondition(data) {
    this.number = data;
  }
  getCondition() {
    return this.number;
  }
  pushOrderDetails(data) {
    this.orderDetails = data;
  }
  getOrderDetails() {
    return this.orderDetails;
  }
  pushCheckCondition(data) {
    this.initialcheck = data;
  }
  getcheckCondition() {
    return this.initialcheck
  }
  pushRadioQuestions(data) {
    this.selectedRadioQuestions = data;
  }
  getRadioQuestions() {
    return this.selectedRadioQuestions
  }
  pushSelectedCheckedQuestions(data) {
    this.selectedcheckedQuestions = data;
  }
  getSelectedCheckedQuestions() {
    return this.selectedcheckedQuestions
  }
  // <!--D2C--->

  pushD2c(data) {
    this.demographic = data;
  }
  getD2c() {
    return this.demographic;
  }
  // <!----->
  pushBlogdetails(data) {
    this.blogDetails = data
  }
  getBlogdetails() {
    return this.blogDetails
  }
  //    Used in the sign-up page to save the agent/marketer/group information
  pushAgentGroupInfo(data) {
    this.agentGroupdata = data;
  }
  getAgentGroupInfo() {
    return this.agentGroupdata;
  }
  //    Method ends
}
