import { Component, OnInit } from '@angular/core';
import { SharingService } from '../services/sharing.service';
import { environment } from '../../environments/environment';
import html2pdf from 'html2pdf.js'
import { TestService } from '../services/behaviourService';
@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss']
})
export class ThankyouComponent implements OnInit {
  retrievedOrderDetails: any;
  orderDetails: any[] = [];
  cartData: any[] = [];
  cartProducts: any[] = [];
  paymentData: any[] = [];
  cartFee: any;
  memberDiscount: any;
  expdate: any;
  year: any;
  month: any;
  companyName: any;
  companyInfo: any;
  oneTimeFee: any;
  a2COneTimeFee: any;
  groupInfo: any;
  enrolmantType: any;
  companyInvoice: any;
  companyId: any;
  monthlyContribution: any;
  monthlyContributionStartDate: any;
  cart_addon_products: any;
  plannames: string;
  constructor(private sharingService: SharingService, private objectService: TestService,) {
    this.sharingService.clientData.subscribe((data) => {
      if (data != '') {
        this.companyInfo = data;
        this.companyId = this.companyInfo.company_id;
        this.companyName = this.companyInfo.company_name;
        this.companyInvoice = this.companyInfo.companyUrl
      }
    })
    var applicantData = JSON.parse(localStorage.getItem('appCart'));
    if (applicantData != null) {
      this.cartFee = applicantData.cart_fee;
      this.cartData = applicantData.cart_data;
      this.memberDiscount = applicantData.agent_cart_discount;
      this.oneTimeFee = applicantData.a2c_total_one_time_fee;
      this.cartProducts = applicantData.cart_products;
      if (this.cartProducts.length != 0) {
        if (this.cartProducts.length > 1) {
          this.plannames = this.cartProducts[0].name + ' ' + ', ' + this.cartProducts[1].name;
        } else {
          this.plannames = this.cartProducts[0].name;
        }
      }
      this.monthlyContribution = applicantData.monthly_contribution;
      this.monthlyContributionStartDate = applicantData.member_applications_data[0].monthly_contribution_start_date;
      this.a2COneTimeFee = applicantData.total_one_time_fee;
    }
    this.objectService.appcartData.subscribe((data) => {
      if (data != '') {
        this.cartFee = data.cart_fee;
        this.cartData = data.cart_data;
        this.cartProducts = data.cart_products;
        if (this.cartProducts.length != 0) {
          if (this.cartProducts.length > 1) {
            this.plannames = this.cartProducts[0].name + ', ' + ' ' + this.cartProducts[1].name;
          } else {
            this.plannames = this.cartProducts[0].name;
          }
        }
        this.monthlyContribution = data.monthly_contribution;
        this.monthlyContributionStartDate = data.member_applications_data[0].monthly_contribution_start_date;
        this.oneTimeFee = data.total_one_time_fee;
      }
    })
  }
  ngOnInit(): void {
    let data = this.sharingService.getAgentGroupInfo();//G2C flow
    if (data != "" && data != undefined && data != null) {
      if (data[0].groupID != null || data[0].groupID != undefined) {
        this.groupInfo = data;
      }
    }
    this.retrievedOrderDetails = this.sharingService.getOrderDetails();
    this.enrolmantType = this.retrievedOrderDetails.enrollment_type;
    this.orderDetails = this.retrievedOrderDetails.order_details;
    this.paymentData = this.retrievedOrderDetails.paymentdata;
    this.memberDiscount = this.retrievedOrderDetails.agent_cart_discount;
    this.a2COneTimeFee = this.retrievedOrderDetails.total_one_time_fee;
    this.cart_addon_products = this.retrievedOrderDetails.cart_addon_products;
    this.objectService.demoToA2c("");
  }
  exportPdf() {
    const invoice = document.getElementById("invoice");
    if (this.companyInvoice == "fellowshiphealthshare") {
      var opt = {
        margin: 0,
        filename: 'FellowshipInvoice.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
      };
    } else if (this.companyInvoice == "eternityhealthshare") {
      var opt = {
        margin: 0,
        filename: 'EternityInvoice.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
      };
    }
    html2pdf().from(invoice).set(opt).save();
  }
}

