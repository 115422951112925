<div class="container">
    <div class="row">
        <div class="col-lg-4">
        </div>
        <div class="col-lg-4">
            <br>
            <div class="row">
                <div class="col-lg-1"></div>
                <div class="col-lg-10 card" >
                    <br>
                    <form [formGroup]="forgotPasswordForm" class="resetForm">
                        <div class="row">
                            <div class="col-lg-12 form-data">
                                <div class="head_txt1"> Email <span class="fieldMan">*</span></div>
                                <input class="form-control" type="email" formControlName="email">
                                <div class="errormsg"
                                    *ngIf="!forgotPasswordForm.controls.email.valid && (forgotPasswordForm.controls.email.touched || submitAttempt)">
                                    Email is required
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 form-data">
                                <div class="head_txt1"> Password <span class="fieldMan">*</span></div>
                                <input type="password" formControlName="newPassword" placeholder=""
                                    class="form-control" #newPassword (keyup)="onKeyUpPassword(newPassword.value)" />
                                     <span>
                                        <a (click)="onShowHide(newPassword, passwordToggle)" class="show_hide"> {{passwordToggle}} </a>
                                    </span> 
                                <div class="errormsg"
                                    *ngIf="forgotPasswordForm.controls.newPassword.dirty && validPassword">
                                    Please enter a password including uppercase letter, lowercase letter, special character,
                                    number and minimum 6 characters.
                                </div>
                                <div class="errormsg"
                                    *ngIf="!forgotPasswordForm.controls.newPassword.valid && (forgotPasswordForm.controls.newPassword.touched || submitAttempt)">
                                    Password is required
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 form-data">
                                <div class="head_txt1"> Re-Enter Password <span class="fieldMan">*</span></div>
                                <input type="password" formControlName="reEnterNewPassword" placeholder=""
                                    class="form-control" #reEnterNewPassword
                                    (keyup)="onKeyUpReEnterPassword(reEnterNewPassword.value)" />
                                    <span>
                                        <a (click)="onShowHide1(reEnterNewPassword, passwordToggle1)" class="show_hide"> {{passwordToggle1}} </a>
                                    </span> 
                                <div class="errormsg"
                                    *ngIf="forgotPasswordForm.controls.reEnterNewPassword.dirty && passwordMismatch">
                                    New Password and Re-Enter New Password didn't match
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 form-data">
                                <div class="head_txt1"> Date Of Birth <span class="fieldMan">*</span></div>
                                <p-calendar placeholder="mm/dd/yyyy"
                                    [style]="{'width': '100%','display': 'inline-flex','height':'40px'}"
                                    formControlName="dob" [maxDate]="minimumDate" [disabledDates]="invalidDates1"
                                    [monthNavigator]="true" [yearNavigator]="true" yearRange="1955:2030"
                                    inputId="navigators">
                                </p-calendar>
                                <div class="errormsg"
                                    *ngIf="!forgotPasswordForm.controls.dob.valid && (forgotPasswordForm.controls.dob.touched || submitAttempt)">
                                    DOB is required
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col-lg-12 form-data">
                                <div class="head_txt1"> Last 4 digit of SSN <span class="fieldMan">*</span></div>
                                <input class="form-control" type="text" maxlength="4" (keypress)="numberOnly($event)"
                                    formControlName="ssn">
                                <div class="errormsg"
                                    *ngIf="!forgotPasswordForm.controls.ssn.valid && (forgotPasswordForm.controls.ssn.touched || submitAttempt)">
                                    SSN is required
                                </div>
                            </div>
                        </div> -->
                        <br>
                        <div class="" style="text-align: center;">
                            <div class="errormsg">{{doberror}}</div>
                            <button class="btn btn-primary"
                                (click)="onChangePassword(forgotPasswordForm.value)">Reset</button>
                        </div>
                        <br>
                    </form>
                </div>
            </div>
            <div class="col-lg-1"></div>
        </div>
        <div class="col-lg-4">
        </div>
    </div>
</div>
