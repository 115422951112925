import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { GetStatesService } from "src/app/dataservices/get-state.service";
import { GetStatesAction, GetStatesFailureAction, GetStatesSuccessAction } from "../actions/states.actions";
import { BillingActionTypes } from "../state/state";

@Injectable()
export class Effects {
    constructor(private actions$: Actions, private getStatesSvc: GetStatesService) { }

    @Effect() getStates$ = this.actions$
        .pipe(
            ofType<GetStatesAction>(BillingActionTypes.GET_STATES),
            mergeMap(
                () => this.getStatesSvc.get()
                    .pipe(
                        map(data => {
                            return new GetStatesSuccessAction(data)
                        }),
                        catchError(error => of(new GetStatesFailureAction(error)))
                    )
            ),
        )

}