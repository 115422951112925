import { Component, OnInit } from '@angular/core';
import { TestService } from '../services/behaviourService';
import { environment } from '../../environments/environment';
import { SharingService } from '../services/sharing.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  loadedData: any;
  url: string;
  companyId: string;
  companyName: string;
  companyInfo: any;
  companyContent:boolean=true;
  constructor(private behaviourservice: TestService, private service: SharingService) {
    this.service.clientData.subscribe((data) => {
      if (data != '' || data != undefined) {
        this.companyInfo = data;
        this.companyId = this.companyInfo.company_id;
        this.companyName = this.companyInfo.company_name;
        this.url = this.companyInfo.portalURL;
        if (this.companyInfo.companyUrl == 'fellowshiphealthshare') {
          this.companyContent = true;
        } else {
          this.companyContent = false
        }
      }
    })

  }

  ngOnInit(): void {
    this.behaviourservice.getheaderCondition().subscribe((data) => {
      this.loadedData = data;
    });
  }

}
