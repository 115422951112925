import { Component, ElementRef, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharingService } from '../services/sharing.service';
import { TestService } from '../services/behaviourService';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe]
})
export class SignUpComponent implements OnInit {
  companyId: any;
  newForm: FormGroup;
  submitAttempt: boolean = false;
  minimumDate: Date;
  invalidDates: Date[];
  invalidDates1: Date[];
  uniqueNames: any;
  value: any;
  inValue: any;
  errtxt: string = "";
  spouceerrtxt: string;
  Age: number;
  parentAge: string;
  er: string;
  weight: number;
  spouseAge: number;
  spouseAgeMsg: string;
  childAge: any;
  ageError: string;
  error: string;
  inchesAllowmode: boolean;
  spouseWtError: string;
  objectData: any = {}
  member_personal_data1: any = {}
  applicant: any;
  planeNames: any[] = [];
  showData: boolean = false
  plansInfo: any;
  plandata: any[] = [];
  productDetails: any = {};
  applicantInfo: any;
  spouseInfo: any;
  childInfo: any;
  arr: any[] = []
  spousearr: any[] = []
  testCase: number;
  primaryArr: any[] = []
  firstname: any;
  loader: boolean = false;
  emailError: any;
  emailerrmsg: boolean = false;
  phoneerrmsg: any;
  errorMsgShow: boolean = true;
  primaryInfo: any;
  indexvalueRe: any;
  disableSpouseArray: boolean = false;
  disableChildArray: boolean = false
  showPlanButton: boolean = true;
  effectiveDate: any;
  companyInfo: any;
  companyName: any;
  groupName: any;
  groupNAME: void;
  groupId: any;
  groupData: any;
  spouseHide: boolean = true;
  checkedCondition: boolean = false;
  disableGuardianFlow: boolean = true;
  referral_id: any;
  referralCode: any
  displayPlans: any;
  plans: any[] = [];
  member_info: any[] = [];
  planeData: any;
  feetArray = [
    { id: 0, feet: '0' }, { id: 1, feet: '1' }, { id: 2, feet: '2' }, { id: 3, feet: '3' }, { id: 4, feet: '4' },
    { id: 5, feet: '5' }, { id: 6, feet: '6' }, { id: 7, feet: '7' }, { id: 8, feet: '8' }, { id: 9, feet: '9' }, { id: 10, feet: '10' },
  ]
  inchArray = [
    { id: 0, inch: '0', inchValue: '00' }, { id: 1, inch: '1', inchValue: '01' }, { id: 2, inch: '2', inchValue: '02' },
    { id: 3, inch: '3', inchValue: '03' }, { id: 4, inch: '4', inchValue: '04' }, { id: 5, inch: '5', inchValue: '05' },
    { id: 6, inch: '6', inchValue: '06' }, { id: 7, inch: '7', inchValue: '07' }, { id: 8, inch: '8', inchValue: '08' },
    { id: 9, inch: '9', inchValue: '09' }, { id: 10, inch: '10', inchValue: '10' }, { id: 11, inch: '11', inchValue: '11' },
  ]
  agent_id: any = 0;
  guardianDependent: any;
  groupUrl:boolean=false;

  constructor(private objectService: TestService,
    private formBuilder: FormBuilder,
    private router: Router,
    private service: SharingService,
    private services: TestService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {
    //=============Fetch group name from url for group concept==========//
    localStorage.removeItem('urlGroupName')
    this.groupName = this.route.snapshot.paramMap.get('id');
    this.service.pushAgentGroupInfo("");
    localStorage.setItem('urlGroupName', this.groupName)
    this.service.clientData.subscribe((data) => {
      if (data != '') {
        this.companyInfo = data;
        this.companyId = this.companyInfo.company_id;
        this.companyName = this.companyInfo.company_name;
        this.effectiveStartDate();
        this.groupInfo(this.value);
      }
    })

    this.objectService.demotoA2C.subscribe(result => { //demographic to plans page 
      if (result != '') {
        this.applicant = result;
        if (this.applicant != null || this.applicant != "" || this.applicant != undefined) {
          if (this.applicant.member_personal_data[0].relation == "Guardian") {
            this.primaryInfo = this.applicant.member_personal_data.filter(x => x['relation'] == "Guardian");
            this.childInfo = this.applicant.member_personal_data.filter(x => x['relation'] == "Dependent");
          } else {
            this.primaryInfo = this.applicant.member_personal_data.filter(x => x['relation'] == "Primary");
            this.childInfo = this.applicant.member_personal_data.filter(x => x['relation'] == "Child");
          }
          this.spouseInfo = this.applicant.member_personal_data.filter(x => x['relation'] == "Spouse");
        }
        if (this.applicant.member_personal_data.length == 1) {
          this.showPlanButton = true;
          setTimeout(() => {
            document.getElementById("getmyplan1").click();
          }, 100);
        }
        else {
          this.showPlanButton = false;
          setTimeout(() => {
            document.getElementById("getmyplan").click();
          }, 100);
        }
      }
    })
  }
  showSpouse: boolean = false;
  showChild: boolean = false;
  maxDate: Date;
  typeOfCode: any;
  ngOnInit(): void {
    window.scrollTo(0, 0);
    let today = new Date();
    this.maxDate = new Date();
    let invalidDate1 = new Date();
    invalidDate1.setDate(today.getDate() + 3);
    this.invalidDates1 = [today, invalidDate1];
    this.minimumDate = new Date();
    this.newForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])],//, Validators.pattern(this.emailPattern)
      phone: ['', Validators.required],
      dob: ['', Validators.required,],
      age: ['', [Validators.required, Validators.min(18), Validators.max(64)]],
      feet: ['', [Validators.required, Validators.min(1), Validators.max(10)]],
      inch: ['',],
      startDate: ['', Validators.required],
      weight: ['', [Validators.required, Validators.min(1), Validators.max(500)]],
      gender: ['', Validators.required],
      relation: ['Primary'],
      member_id: [''],
      guardian_id: [''],
      disabled: [''],
      child_Array: this.formBuilder.array([]),
      spouse_Array: this.formBuilder.array([]),
      guardianCheckbox: ['']
    })

    this.objectService.pushheaderCondition("showheader");
    if (this.primaryInfo != null || this.primaryInfo != undefined) {
      this.newForm.controls.firstname.setValue(this.primaryInfo[0].firstname);
      this.newForm.controls.lastname.setValue(this.primaryInfo[0].lastname);
      this.newForm.controls.email.setValue(this.primaryInfo[0].email);
      var phone = this.primaryInfo[0].phone.substr(0, 3) + "-" + this.primaryInfo[0].phone.substr(3, 3) + "-" + this.primaryInfo[0].phone.substr(6, 4);
      this.newForm.controls.phone.setValue(phone);
      if (this.primaryInfo[0].relation != "Guardian") {
        var height = this.primaryInfo[0].height;
        var n = height.toString().includes(".");
        if (n == false) {
          var str2 = ".0";
          var height = height.toString().concat(str2);
        }
        const primaryHeight = height.toString();
        var inch = primaryHeight.substring(primaryHeight.lastIndexOf('.') + 1);
        var feet = primaryHeight.substring(0, primaryHeight.lastIndexOf('.'));
        this.newForm.controls.inch.setValue(inch);
        this.newForm.controls.feet.setValue(feet);
      }
      this.newForm.controls.age.setValue(this.primaryInfo[0].age);
      this.newForm.controls.startDate.setValue(this.datePipe.transform(this.applicant.startdate, "dd-MMM-yyyy"));
      this.newForm.controls.dob.setValue(this.datePipe.transform(this.primaryInfo[0].dob, "MM/dd/yyyy"));
      this.newForm.controls.gender.setValue(this.primaryInfo[0].gender);
      this.newForm.controls.weight.setValue(this.primaryInfo[0].weight);
      this.newForm.controls.member_id.setValue(this.primaryInfo[0].member_id);
      this.newForm.controls.guardian_id.setValue(this.primaryInfo[0].guardian_id);
      if (this.primaryInfo[0].relation == "Guardian") {
        this.checkedCondition = true;
        this.newForm.controls.guardianCheckbox.setValue(this.checkedCondition);
        this.spouseHide = false;
      } else {
        this.checkedCondition = false;
      }
      this.firstname = this.primaryInfo[0].firstname;
    }
    if (this.spouseInfo != null || this.spouseInfo != undefined) {
      if (this.spouseInfo.length > 0) {
        this.disableSpouseArray = true
        this.spouse_Array().push(this.newEmployee1());
        var height = this.spouseInfo[0].height;
        var n = height.toString().includes(".");
        if (n == false) {
          var str2 = ".0";
          var height = height.toString().concat(str2);
        }
        const spouseHeight = height.toString();
        var Inch = spouseHeight.substring(spouseHeight.lastIndexOf('.') + 1);
        var Feet = spouseHeight.substring(0, spouseHeight.lastIndexOf('.'));
        this.newForm.controls['spouse_Array']['controls'][0].patchValue({
          spouseFirstName: this.spouseInfo[0].firstname,
          spouseDob: this.datePipe.transform(this.spouseInfo[0].dob, "MM/dd/yyyy"),
          spouseWeight: this.spouseInfo[0].weight,
          spouseInch: Inch,
          spouseFeet: Feet,
          spouseAge: this.spouseInfo[0].age,
          spouseGender: this.spouseInfo[0].gender,
          spouse_id: this.spouseInfo[0].member_id,
        })
      }
    }
    if (this.childInfo != null || this.childInfo != undefined) {
      if (this.childInfo.length > 0) {
        this.disableChildArray = true
        this.showChild = true;
      }
      for (var i = 0; i < this.childInfo.length; i++) {
        this.child_Array().push(this.newChild());
        var height = this.childInfo[i].height;
        var n = height.toString().includes(".");
        if (n == false) {
          var str2 = ".0";
          var height = height.toString().concat(str2);
        }
        const childHeight = height.toString();
        var Inch = childHeight.substring(childHeight.lastIndexOf('.') + 1);
        var Feet = childHeight.substring(0, childHeight.lastIndexOf('.'));
        this.newForm.controls['child_Array']['controls'][i].patchValue({
          childFirstName: this.childInfo[i].firstname,
          childDob: this.datePipe.transform(this.childInfo[i].dob, "MM/dd/yyyy"),
          childGender: this.childInfo[i].gender,
          childAge: this.childInfo[i].age,
          childInch: Inch,
          childFeet: Feet,
          childWeight: this.childInfo[i].weight,
          child_id: this.childInfo[i].member_id
        })
      }
    }
  }

  //============group Info Api================//
  groupInfo(value) {

    this.agent_id = 0;
    if (value != null) {
      this.groupName = value
    }
    if (this.groupName != null) {
      // var lastUrlSegment = this.groupName.includes('RFL');
      if (this.groupName.includes('RFL')) {
        var url = 'GetReferral/' + this.groupName;;
      }
      else if (this.groupName.includes('MKT') || this.groupName.includes('AGT')) {
        var url = 'GetAgentInfoByCode/' + this.groupName;
      }
      else {
        var url = 'GetGroup/' + this.groupName;
      }
      this.service.getData(url).subscribe((Object: any) => {
        
        if (Object != null) {
          localStorage.setItem('urlGroupName', Object.data[0].code)
          localStorage.setItem('urlGroupName', Object.data[0].groupID)
          if (Object.data[0].referral_id != undefined || Object.data[0].referral_id != null) {
            this.referral_id = Object.data[0].referral_id;
            this.groupData = Object.data;
            this.typeOfCode = this.groupData[0].type;
            this.referralCode = this.groupData[0].referral_code
            //  this.disableGuardianFlow = false;
          }
          else if (Object.data[0].agent_id != undefined || Object.data[0].agent_id != null) {
            Object.data[0].name = Object.data[0].firstname + " " + Object.data[0].lastname;
            this.referralCode = Object.data[0].code;
            this.agent_id = Object.data[0].agent_id;
            this.groupData = Object.data;
            this.typeOfCode = this.groupData[0].type;
            this.service.pushAgentGroupInfo(this.groupData);
          }
          else if (Object.data[0].group_id != undefined || Object.data[0].group_id != null) {
            // Object.data[0].name = Object.data[0].firstname + " " + Object.data[0].lastname;
            this.referralCode = Object.data[0].groupID;
            this.groupId = Object.data[0].group_id;
            this.groupData = Object.data;
            this.typeOfCode = this.groupData[0].type;
            this.disableGuardianFlow = false;
            this.service.pushAgentGroupInfo(this.groupData);
            this.groupUrl=true;
            // this.service.pushAgentGroupInfo(this.groupData);
          }
         
          else {
            this.groupData = Object.data;
            this.typeOfCode = this.groupData[0].type;
            this.groupId = Object.data[0].group_id;
            this.service.pushAgentGroupInfo(this.groupData);
            // localStorage.setItem('groupinfo', JSON.stringify(this.groupData));
            this.disableGuardianFlow = false;
          }
        }
      }, err => {
        this.toastr.error(err.error.Message)
        console.log(err);
      })
    } else {
      // localStorage.removeItem('groupinfo');
     localStorage.removeItem('urlGroupName');
    }
  }

  newEmployee1(): FormGroup {
    return this.formBuilder.group({
      spouseFirstName: ['', Validators.required],
      spouseDob: ['', Validators.required],
      spouseGender: ['', Validators.required],
      spouseFeet: ['', [Validators.required, Validators.min(1), Validators.max(10)]],
      spouseInch: ['', [Validators.min(0), Validators.max(11)]],
      spouseAge: ['', [Validators.required, Validators.min(18), Validators.max(64)]],
      spouse_id: ['',],
      spouseWeight: ['', [Validators.required, Validators.min(1), Validators.max(500)]],
      relation: ['Spouse'],
      spouseCheckBox: [true]
    })
  }
  newChild(): FormGroup {
    return this.formBuilder.group({
      childFirstName: ['', Validators.required],
      childDob: ['', Validators.required],
      childGender: ['', Validators.required],
      childFeet: ['', Validators.required],
      childInch: ['',],
      childAge: ['', [Validators.required, Validators.min(0), Validators.max(26)]],
      child_id: [''],
      childWeight: ['', [Validators.required, Validators.min(1), Validators.max(500)]],
      relation: ['Child'],
      childCheckBox: [true]
    });
  }

  child_Array(): FormArray {
    return this.newForm.get("child_Array") as FormArray
  }
  spouse_Array(): FormArray {
    return this.newForm.get("spouse_Array") as FormArray
  }

  //*********effective start date**************//
  effectiveStartDate() {
    var url = 'GetEffectiveStartDates/' + this.companyId
    this.service.getData(url).subscribe((Object: any) => {
      this.effectiveDate = Object.data
    }, err => {
      console.log(err);
      this.toastr.error('Something went wrong. Please try again later.');
    })
  }

  //===========Guardion flow check box===========//
  guardianCheckbox(checkedValue) {

    if (checkedValue == true) {
      this.addDependent();
      this.spouseHide = false;
    } else {
      this.spouseHide = true;
      this.child_Array().removeAt(0);
      this.showChild = false;
      this.showPlanButton = true;
    }
  }
  addDependent() { // add dependent

    this.showPlanButton = false;
    this.showChild = true;
    if (this.child_Array().length <= 0) {
      //     this.disableChildArray = true
      this.child_Array().push(this.newChild());
    }
  }

  //**********Child check box*********88 */
  childCheckbox(checkedValue, childValue, values) {
    if (checkedValue == true) {
      if (childValue != '' || childValue != undefined) {
        if (childValue.child_id != '') {
          this.formSubmit(values)
        } else {
          // this.formSubmit(values)
        }
      }
    } else {
      if (childValue.child_id != '') {
        this.arr.push(childValue);
        this.formSubmit(values)
      }
    }
  }
  //************Spouse Check box***********/
  spouseCheckbox(checkedValue, spouseValues, values) {
    if (checkedValue == true) {
      if (spouseValues != '' || spouseValues != undefined) {
        if (spouseValues.spouse_id != '') {
          this.formSubmit(values)
        } else {
          //  this.formSubmit(values)
        }
      }
    }
    else {
      if (spouseValues.spouse_id != '') {
        this.spousearr.push(spouseValues);
        this.formSubmit(values)
      }
    }
  }

  //************************add chilkd*******************/
  addEmployee(va) {
    if (va == 'child') {
      this.child_Array().push(this.newChild());
    }
  }

  //**************Display first name in circle*******************//
  onlastNameleave() {
    let firstname = (<HTMLInputElement>document.getElementById("firstname")).value;
    this.firstname = firstname
  }

  //**************Add new ***********************/
  addSpouse() {//add new spouse
    this.showPlanButton = false;
    if (this.spouse_Array().length <= 0) {
      this.disableSpouseArray = true
      this.spouse_Array().push(this.newEmployee1());
    }
    this.disableGuardianFlow = false;
  }

  addChildren() {// add child
    this.showPlanButton = false;
    this.showChild = true;
    if (this.spouse_Array().length >= 0) {
      this.disableChildArray = true
      this.child_Array().push(this.newChild());
    }
  }

  //==================Remove form Array==================//
  removeEmployee(empIndex, childValue, values) {//remove child Array
    this.indexvalueRe = empIndex
    if (childValue.child_id != '') {
      this.arr.push(childValue);
      this.formSubmit(values)
    }
    this.child_Array().removeAt(empIndex);
    if (values.child_Array.length == 1) {
      this.disableChildArray = false
      this.showChild = false;
      if (values.spouse_Array.length == 0) {
        this.showPlanButton = true;
      }
    }
  }
  removeEmployee1(empIndex1, spouseValues, values) {//remove spouse array
    this.disableSpouseArray = false;
    this.disableGuardianFlow = true;
    // document.getElementById("spousestatus").click();
    if (spouseValues.spouse_id != '') {
      this.spousearr.push(spouseValues);
      this.formSubmit(values)
    }
    this.spouse_Array().removeAt(empIndex1);
    if (values.child_Array.length == 0) {
      this.showPlanButton = true;
    }
  }

  emailValidation(value) {
    if (this.emailError != null || this.phoneerrmsg != null) {
      this.errorMsgShow = false;
    }
  }
  //insert 1stname,lastname,email and phone in staging table
  onSearchChange1(values): void {
    if (values.firstname != '' && values.lastname != '' && values.email != '' && values.phone != '') {
      this.objectData.company_id = this.companyId
      this.objectData.zipcode = '';
      this.objectData.startdate = "";
      this.objectData.agent_id = this.agent_id;
      this.objectData.referral_id = this.referral_id;
      this.objectData.member_personal_data = new Array<any>();
      this.member_personal_data1.firstname = values.firstname;
      this.member_personal_data1.lastname = values.lastname;
      this.member_personal_data1.email = values.email;
      this.member_personal_data1.phone = values.phone.replace(/[^0-9\.]+/g, "");
      this.member_personal_data1.member_id = values.member_id;
      this.member_personal_data1.smoker = "";
      if (values.guardianCheckbox == true) {
        this.member_personal_data1['relation'] = "Guardian";
        this.objectData.guardian = values.guardianCheckbox;
      } else {
        this.member_personal_data1['relation'] = "Primary";
      }
      this.member_personal_data1.weight = "";
      this.member_personal_data1.dob = "";
      this.member_personal_data1.age = "";
      this.member_personal_data1.gender = "";
      this.member_personal_data1.height = "";
      this.objectData.member_personal_data.push(this.member_personal_data1);
      var finalObject: any = {}
      finalObject = this.objectData;
      var url = 'CreateMemberD2C';
      this.service.postData(url, finalObject).subscribe((Object: any) => {
        this.newForm.controls.member_id.setValue(Object.member_personal_data[0].member_id);
      }, err => {
        console.log(err);
        this.errorMsgShow = true;
        this.emailError = err.error.email;
        this.phoneerrmsg = err.error.phone;
      })
    }
  }
  formSubmit(values) {
    if (this.indexvalueRe != null) {
      this.submitAttempt = false;
      this.formSubmit1(values)
    } else {
      this.submitAttempt = true;
      this.formSubmit1(values)
    }
  }

  formSubmit1(values) {
    this.submitAttempt = true;
    if (values.guardianCheckbox == true) {
      if (values.height == '') {
        var height = values.height == '';
        var weight = values.weight == '';
        var gender = values.gender == '';
      } else {
        var height = values.height == undefined;
        var weight = values.weight == undefined;
        var gender = values.gender == undefined;
      }
    }
    if (this.newForm.valid && values.phone.length == 12 || gender || height || weight) {
      this.objectData.company_id = this.companyId
      if (this.applicant != undefined) {
        this.objectData.zipcode = this.applicant.zipcode;
      } else {
        this.objectData.zipcode = '';
      }
      this.objectData.startdate = values.startDate;
      this.objectData.agent_id = this.agent_id;
      this.objectData.group_id = this.groupId //groupId
      this.objectData.referral_id = this.referral_id; //referral
      this.objectData.member_personal_data = new Array<any>();
      if (values.guardianCheckbox == true) {
        this.member_personal_data1['relation'] = "Guardian";
        this.objectData.guardian = values.guardianCheckbox;
        this.member_personal_data1.guardian_id = values.guardian_id;
        this.guardianDependent = "Dependent";
      } else {
        this.member_personal_data1['relation'] = "Primary";
        this.member_personal_data1.member_id = values.member_id;
        this.guardianDependent = "Child";
      }
      this.member_personal_data1.firstname = values.firstname;
      this.member_personal_data1.lastname = values.lastname;
      this.member_personal_data1.smoker = "";
      this.member_personal_data1.weight = values.weight;
      this.member_personal_data1.dob = this.datePipe.transform(values.dob, "MM/dd/yyyy");
      this.member_personal_data1.age = values.age;
      this.member_personal_data1.gender = values.gender;
      this.member_personal_data1.email = values.email;
      this.member_personal_data1.phone = values.phone.replace(/[^0-9\.]+/g, "");
      this.member_personal_data1.height = values.feet + '.' + values.inch;
      this.objectData.member_personal_data.push(this.member_personal_data1);
      for (var k = 0; k < values.spouse_Array.length; k++) { //insert
        if (values.spouse_Array[k].spouseCheckBox == true) {
          this.member_personal_data1 = {};
          this.member_personal_data1.firstname = values.spouse_Array[k].spouseFirstName;
          this.member_personal_data1.lastname = "";
          this.member_personal_data1.smoker = "";
          this.member_personal_data1.member_id = values.spouse_Array[k].spouse_id;
          this.member_personal_data1['relation'] = "Spouse";
          this.member_personal_data1.weight = values.spouse_Array[k].spouseWeight;
          this.member_personal_data1.dob = this.datePipe.transform(values.spouse_Array[k].spouseDob, "MM/dd/yyyy");
          this.member_personal_data1.age = values.spouse_Array[k].spouseAge;
          this.member_personal_data1.gender = values.spouse_Array[k].spouseGender;
          this.member_personal_data1.active = 1;
          this.member_personal_data1.height = values.spouse_Array[k].spouseFeet + '.' + values.spouse_Array[k].spouseInch;
          this.objectData.member_personal_data.push(this.member_personal_data1);
        }
      }
      if (this.spousearr.length > 0) {//for remove option 
        for (var z = 0; z < this.spousearr.length; z++) {
          this.member_personal_data1 = {};
          this.member_personal_data1.firstname = this.spousearr[z].spouseFirstName;
          this.member_personal_data1.lastname = "";
          this.member_personal_data1.smoker = "";
          this.member_personal_data1.member_id = this.spousearr[z].spouse_id;
          this.member_personal_data1['relation'] = "Spouse";
          this.member_personal_data1.weight = this.spousearr[z].spouseWeight;
          this.member_personal_data1.dob = this.datePipe.transform(this.spousearr[z].spouseDob, "MM/dd/yyyy");
          this.member_personal_data1.age = this.spousearr[z].spouseAge;;
          this.member_personal_data1.gender = this.spousearr[z].spouseGender;
          this.member_personal_data1.active = 0;
          this.member_personal_data1.height = this.spousearr[z].spouseFeet + '.' + this.spousearr[z].spouseInch;
          this.objectData.member_personal_data.push(this.member_personal_data1);
        }
        this.spousearr = [];
      }
      for (var i = 0; i < values.child_Array.length; i++) {
        if (values.child_Array[i].childCheckBox == true) {
          this.member_personal_data1 = {};
          this.member_personal_data1.firstname = values.child_Array[i].childFirstName;
          this.member_personal_data1.lastname = "";
          this.member_personal_data1.smoker = "";
          this.member_personal_data1['relation'] = this.guardianDependent;
          this.member_personal_data1.member_id = values.child_Array[i].child_id;
          this.member_personal_data1.weight = values.child_Array[i].childWeight;
          this.member_personal_data1.dob = this.datePipe.transform(values.child_Array[i].childDob, "MM/dd/yyyy");
          this.member_personal_data1.age = values.child_Array[i].childAge;
          this.member_personal_data1.gender = values.child_Array[i].childGender;
          this.member_personal_data1.active = 1;
          this.member_personal_data1.height = values.child_Array[i].childFeet + '.' + values.child_Array[i].childInch;
          this.objectData.member_personal_data.push(this.member_personal_data1);
        }
      }
      if (this.arr.length > 0) {//for remove option 
        for (var j = 0; j < this.arr.length; j++) {
          this.member_personal_data1 = {};
          this.member_personal_data1.firstname = this.arr[j].childFirstName;
          this.member_personal_data1.lastname = "";
          this.member_personal_data1.smoker = "";
          this.member_personal_data1['relation'] = this.guardianDependent;
          this.member_personal_data1.member_id = this.arr[j].child_id;
          this.member_personal_data1.weight = this.arr[j].childWeight;
          this.member_personal_data1.dob = this.datePipe.transform(this.arr[j].childDob);
          this.member_personal_data1.age = this.arr[j].childAge;
          this.member_personal_data1.gender = this.arr[j].childGender;
          this.member_personal_data1.active = 0;
          this.member_personal_data1.height = this.arr[j].childFeet + '.' + this.arr[j].childInch;
          this.objectData.member_personal_data.push(this.member_personal_data1);
        }
        this.arr = [];
      }
      var finalObject: any = {}
      finalObject = this.objectData;
      var url = 'CreateMemberD2C';
      this.service.postData(url, finalObject).subscribe((Object: any) => {

        if (Object !== null) {
          this.applicantInfo = Object.member_personal_data;
          if (this.applicantInfo[0].relation == "Guardian") {
            var primaryInfo = this.applicantInfo.filter(x => x['relation'] == "Guardian");
            this.childInfo = this.applicantInfo.filter(x => x['relation'] == "Dependent");
          } else {
            var primaryInfo = this.applicantInfo.filter(x => x['relation'] == "Primary");
            this.childInfo = this.applicantInfo.filter(x => x['relation'] == "Child");
          }
          this.spouseInfo = this.applicantInfo.filter(x => x['relation'] == "Spouse");
          if (primaryInfo != null || primaryInfo != undefined) {
            this.newForm.controls.firstname.setValue(primaryInfo[0].firstname);
            this.newForm.controls.lastname.setValue(primaryInfo[0].lastname);
            this.newForm.controls.email.setValue(primaryInfo[0].email);
            var phone = primaryInfo[0].phone.substr(0, 3) + "-" + primaryInfo[0].phone.substr(3, 3) + "-" + primaryInfo[0].phone.substr(6, 4);
            this.newForm.controls.phone.setValue(phone);

            var height = primaryInfo[0].height;
            if (height != undefined) {
              var n = height.toString().includes(".");
              if (n == false) {
                var str2 = ".0";
                var height = height.toString().concat(str2);
              }
              const primaryHeight = height.toString();
              var inch = primaryHeight.substring(primaryHeight.lastIndexOf('.') + 1);
              var feet = primaryHeight.substring(0, primaryHeight.lastIndexOf('.'));
              this.newForm.controls.inch.setValue(inch);
              this.newForm.controls.feet.setValue(feet);
              this.newForm.controls.gender.setValue(primaryInfo[0].gender);
              this.newForm.controls.weight.setValue(primaryInfo[0].weight);
            }
            this.newForm.controls.age.setValue(primaryInfo[0].age);
            this.newForm.controls.startDate.setValue(this.datePipe.transform(Object.startdate, "dd-MMM-yyyy"));
            this.newForm.controls.dob.setValue(this.datePipe.transform(primaryInfo[0].dob, "MM/dd/yyyy"));
            this.newForm.controls.member_id.setValue(primaryInfo[0].member_id);
            this.newForm.controls.guardian_id.setValue(primaryInfo[0].guardian_id);
            this.firstname = primaryInfo[0].firstname;
          }
          if (this.spouseInfo.length > 0) {
            this.disableSpouseArray = true
            var height = this.spouseInfo[0].height;
            var n = height.toString().includes(".");
            if (n == false) {
              var str2 = ".0";
              var height = height.toString().concat(str2);
            }
            const spouseHeight = height.toString();
            var Inch = spouseHeight.substring(spouseHeight.lastIndexOf('.') + 1);
            var Feet = spouseHeight.substring(0, spouseHeight.lastIndexOf('.'));
            this.newForm.controls['spouse_Array']['controls'][0].patchValue({
              spouseFirstName: this.spouseInfo[0].firstname,
              spouseDob: this.datePipe.transform(this.spouseInfo[0].dob, "MM/dd/yyyy"),
              spouseWeight: this.spouseInfo[0].weight,
              spouseInch: Inch,
              spouseFeet: Feet,
              spouseAge: this.spouseInfo[0].age,
              spouseGender: this.spouseInfo[0].gender,
              spouse_id: this.spouseInfo[0].member_id
            })
          }
          if (this.childInfo.length > 0) {
            this.disableChildArray = true
            for (var i = 0; i < this.childInfo.length; i++) {
              var height = this.childInfo[i].height;
              var n = height.toString().includes(".");
              if (n == false) {
                var str2 = ".0";
                var height = height.toString().concat(str2);
              }
              const childHeight = height.toString();
              var Inch = childHeight.substring(childHeight.lastIndexOf('.') + 1);
              var Feet = childHeight.substring(0, childHeight.lastIndexOf('.'));
              this.newForm.controls['child_Array']['controls'][i].patchValue({
                childFirstName: this.childInfo[i].firstname,
                childDob: this.datePipe.transform(this.childInfo[i].dob, "MM/dd/yyyy"),
                childGender: this.childInfo[i].gender,
                childAge: this.childInfo[i].age,
                childInch: Inch,
                childFeet: Feet,
                childWeight: this.childInfo[i].weight,
                child_id: this.childInfo[i].member_id
              })
            }
          }
          this.loader = true;
          if (Object.guardian == true) {
            var objdata = {
              startdate: Object.startdate,
              guardian: Object.guardian,
              member_personal_data: this.childInfo,
              agent_id: this.agent_id
            }
            var url = 'Plan/List/' + this.companyId;
            this.service.postData(url, objdata).subscribe((Object: any) => {
              if (Object !== null) {
                this.loader = false;
                this.plansInfo = Object;
                this.displayPlans = this.plansInfo.ar;
                this.plandata = this.plansInfo.data;
                window.scrollBy(0, 240);

              }
            }, err => {
              console.log(err);
              this.loader = false;
              this.toastr.error('Something went wrong. Please try again later.');
            })
          } else {
            var data = {
              startdate: Object.startdate,
              member_personal_data: this.applicantInfo,
              agent_id: this.agent_id
            }
            var url = 'Plan/List/' + this.companyId;
            this.service.postData(url, data).subscribe((Object: any) => {
              if (Object !== null) {
                this.loader = false;
                this.plansInfo = Object;
                this.displayPlans = this.plansInfo.ar;
                this.plandata = this.plansInfo.data;
                window.scrollBy(0, 240);
              }
            }, err => {
              console.log(err);
              this.loader = false;
              this.toastr.error('Something went wrong. Please try again later.');
            })
          }
        }
      }, err => {
        console.log(err);
        this.errorMsgShow = true;
        this.emailError = err.error.email;
        this.phoneerrmsg = err.error.phone;
      })
    } else {
    }
  }

  addToCart(value) {// addto cart
    var updatePlan = localStorage.getItem('planupdate');
    var summaryToPlan = localStorage.getItem('summaryPlanupdate')
    if (updatePlan == 'updateplan') {//cart update
      if (summaryToPlan == 'summarytoPlan') {
        this.productDetails.company_id = this.companyId;
        if (this.agent_id > 0) {
          this.productDetails.enrollment_type = "A2C";
        } else {
          this.productDetails.enrollment_type = "D2C"
        }
        this.productDetails.cart_type = "enrollment";
        this.productDetails.family_id = this.plansInfo.member_personal_data[0].family_id;
        this.plans = [];
        this.member_info = [];
        if (value.plan_info != null) {
          for (var i = 0; i < value.plan_info.length; i++) {
            var member_info = value.plan_info[i].member_info;
            this.member_personal_data1 = {};
            this.member_personal_data1.product_pricing_id = value.plan_info[i].product_pricing_id;
            this.member_personal_data1.plan_price = value.plan_info[i].price;
            this.member_personal_data1.additional_member = value.plan_info[i].additional_member;
            this.member_personal_data1.quantity = 1;
            this.member_personal_data1.member_info = member_info;
            this.plans.push(this.member_personal_data1)
          }
        } else {
          this.member_personal_data1 = {};
          var member_info = value.member_info;
          this.member_personal_data1.member_info = member_info;
          this.member_personal_data1.product_pricing_id = value.product_pricing_id;
          this.member_personal_data1.plan_price = value.price;
          this.member_personal_data1.additional_member = value.additional_member;
          this.member_personal_data1.quantity = 1;
          this.member_personal_data1.member_info = member_info;
          this.plans.push(this.member_personal_data1)
        }
        var plan = {
          plans: this.plans
        }
        var finalObject: any = {};
        finalObject = Object.assign({}, plan, this.productDetails,);
        if (this.applicant.member_personal_data.length == this.plansInfo.member_personal_data.length) {
          var url = 'UpdateCartPlan' + '/' + this.applicant.cart_data[0].id;
          this.service.putData(url, finalObject).subscribe((newObj: any) => {
            this.planeData = newObj;
            var url = 'GetMemberDependents/' + this.companyId + '/' + newObj.member_personal_data[0].member_id + '/' + newObj.cart_data[0].id;
            this.service.getData(url).subscribe((Object: any) => {
              this.applicationCart();
              localStorage.removeItem('planupdate');
              localStorage.removeItem('summaryPlanupdate')
              this.objectService.paymentMethod(Object);
              this.router.navigate(['add_on_products', { routeTo: "summary" }]);
            })
          }, err => {
            console.log(err);
            this.toastr.error('Something went wrong. Please try again later.');
          })
        } else {
          var url = 'UpdateCartPlan' + '/' + this.applicant.cart_data[0].id;
          this.service.putData(url, finalObject).subscribe((newObj: any) => {
            this.planeData = newObj;
            var url = 'GetMemberDependents/' + this.companyId + '/' + newObj.member_personal_data[0].member_id + '/' + newObj.cart_data[0].id;
            this.service.getData(url).subscribe((Object: any) => {
              this.applicationCart();
              sessionStorage.setItem('d2cFlow', 'd2c');
              this.service.pushD2c('D2C');
              this.services.addCartPlan(Object);
              this.objectService.paymentMethod(Object);
              this.router.navigate(['add_on_products']);
            })
          }, err => {
            console.log(err);
            this.toastr.error('Something went wrong. Please try again later.');
          })
        }
      }
      else {
        this.productDetails.company_id = this.companyId;
        if (this.agent_id > 0) {
          this.productDetails.enrollment_type = "A2C";
        } else {
          this.productDetails.enrollment_type = "D2C"
        }
        this.productDetails.cart_type = "enrollment";
        this.productDetails.family_id = this.plansInfo.member_personal_data[0].family_id;
        this.plans = [];
        if (value.plan_info != null) {
          for (var i = 0; i < value.plan_info.length; i++) {
            var member_info = value.plan_info[i].member_info;
            this.member_personal_data1 = {};
            this.member_personal_data1.product_pricing_id = value.plan_info[i].product_pricing_id;
            this.member_personal_data1.plan_price = value.plan_info[i].price;
            this.member_personal_data1.additional_member = value.plan_info[i].additional_member;
            this.member_personal_data1.quantity = 1;
            this.member_personal_data1.member_info = member_info;
            this.plans.push(this.member_personal_data1)
          }
        } else {
          this.member_personal_data1 = {};
          var member_info = value.member_info;
          this.member_personal_data1.member_info = member_info;
          this.member_personal_data1.product_pricing_id = value.product_pricing_id;
          this.member_personal_data1.plan_price = value.price;
          this.member_personal_data1.additional_member = value.additional_member;
          this.member_personal_data1.quantity = 1;
          this.plans.push(this.member_personal_data1)
        }
        var plan = {
          plans: this.plans
        }
        var finalObject: any = {};
        finalObject = Object.assign({}, plan, this.productDetails,);
        var url = 'UpdateCartPlan' + '/' + this.applicant.cart_data[0].id;
        this.service.putData(url, finalObject).subscribe((newObj: any) => {
          this.planeData = newObj;
          var url = 'GetMemberDependents/' + this.companyId + '/' + newObj.member_personal_data[0].member_id + '/' + newObj.cart_data[0].id;
          this.service.getData(url).subscribe((Object: any) => {
            this.applicationCart();
            localStorage.removeItem('planupdate')
            sessionStorage.setItem('d2cFlow', 'd2c');
            this.service.pushD2c('D2C');
            this.services.addCartPlan(Object);
            this.router.navigate(['add_on_products']);
          })
        }, err => {
          console.log(err);
          this.toastr.error('Something went wrong. Please try again later.');
        })
      }
    } else {
      this.productDetails.company_id = this.companyId;
      if (this.agent_id > 0) {
        this.productDetails.enrollment_type = "A2C";
      } else {
        this.productDetails.enrollment_type = "D2C"
      }
      this.productDetails.cart_type = "enrollment";
      this.productDetails.family_id = this.plansInfo.member_personal_data[0].family_id;
      this.plans = [];
      this.member_info = [];
      if (value.plan_info != null) {
        for (var i = 0; i < value.plan_info.length; i++) {
          var member_info = value.plan_info[i].member_info;
          this.member_personal_data1 = {};
          this.member_personal_data1.product_pricing_id = value.plan_info[i].product_pricing_id;
          this.member_personal_data1.plan_price = value.plan_info[i].price;
          this.member_personal_data1.additional_member = value.plan_info[i].additional_member;
          this.member_personal_data1.quantity = 1;
          this.member_personal_data1.member_info = member_info;
          this.plans.push(this.member_personal_data1)
        }
      } else {
        this.member_personal_data1 = {};
        var member_info = value.member_info;
        this.member_personal_data1.member_info = member_info;
        this.member_personal_data1.product_pricing_id = value.product_pricing_id;
        this.member_personal_data1.plan_price = value.price;
        this.member_personal_data1.additional_member = value.additional_member;
        this.member_personal_data1.quantity = 1;
        this.plans.push(this.member_personal_data1)
      }
      var plan = {
        plans: this.plans
      }
      var finalObject: any = {};
      finalObject = Object.assign({}, plan, this.productDetails,);
      var url = 'AddCartPlan';
      this.service.postData(url, finalObject).subscribe((newObj: any) => {
        this.planeData = newObj;
        var url = 'GetMemberDependents/' + this.companyId + '/' + newObj.member_personal_data[0].member_id + '/' + newObj.cart_data[0].id;
        this.service.getData(url).subscribe((Object: any) => {
          this.applicationCart();
          sessionStorage.setItem('d2cFlow', 'd2c');
          this.service.pushD2c('D2C');
          this.services.addCartPlan(Object);
          this.services.appCartmemberInfoInfo(this.companyId, newObj.member_personal_data[0].member_id, newObj.cart_data[0].id);
          this.router.navigate(['add_on_products']);
        }, err => {
          console.log(err)
        })
      }, err => {
        console.log(err);
        this.toastr.error('Something went wrong. Please try again later.');
      })
    }
  }
  applicationCart() {
    var url = 'GetCartPlan/' + this.companyId + '/' + this.planeData.cart_data[0].id + '/' + this.planeData.member_personal_data[0].family_id;
    this.service.getData(url).subscribe((Object: any) => {
      if (!!Object) {
        this.services.appCartInfo(Object)
      }
    }, err => {
      console.log(err);
      this.toastr.error('Something went wrong. Please try again later.');
    })
  }
  //===============Age Calculation==========//
  onSearchChangeT(value) {
    if (value.dob != null) {
      const convertAge = new Date(value.dob);
      const timeDiff = Math.abs(Date.now() - convertAge.getTime());
      this.Age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
      this.newForm.controls.age.setValue(this.Age);
      if (value.guardianCheckbox == true) {
        if (this.Age <= 26) {
          this.parentAge = "Applicant  can't be younger than 26 years of age";
        }
      } else {
        if (this.Age <= 17) {
          this.parentAge = "Applicant  can't be younger than 18 years of age";
        }
      }
      if (this.Age > 64) {
        this.parentAge = "Applicant can't be older than 65 years of age";
      }
      if (this.Age < 66 && this.Age > 18) {
        this.parentAge = "";
      }
    }
  }
  onSearchChange(searchValue: string, checkValue): void {
    if (searchValue !== "") {
      const convertAge = new Date(searchValue);
      const timeDiff = Math.abs(Date.now() - convertAge.getTime());
      this.Age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
      this.newForm.controls.age.setValue(this.Age);
      if (checkValue.guardianCheckbox == true) {
        if (this.Age <= 26) {
          this.parentAge = "Applicant  can't be younger than 26 years of age";
        }
        if (this.Age > 64) {
          this.parentAge = "Applicant can't be older than 65 years of age";
        }
        if (this.Age < 65 && this.Age > 25) {
          this.parentAge = "";
        }
      } else {
        if (this.Age <= 17) {
          this.parentAge = "Applicant  can't be younger than 18 years of age";
        }
        if (this.Age > 64) {
          this.parentAge = "Applicant can't be older than 65 years of age";
        }
        if (this.Age < 65 && this.Age > 18) {
          this.parentAge = "";
        }
      }

    }
  }
  spouseAgeCal(searchValue, Index: number, dob): void {
    if (searchValue !== "") {
      const convertAge = new Date(searchValue);
      const timeDiff = Math.abs(Date.now() - convertAge.getTime());
      this.spouseAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
      // this.newForm.controls.spouse_Age.setValue(this.spouseAge);
      const formcontrol = this.spouse_Array()
      formcontrol.controls[Index].get('spouseAge').setValue(this.spouseAge);
      if (this.spouseAge <= 17) {
        this.spouseAgeMsg = "Applicant  can't be younger than 18 years of age";
      }
      if (this.spouseAge > 64) {
        this.spouseAgeMsg = "Applicant can't be older than 65 years of age";
      }
      if (this.spouseAge < 66 && this.spouseAge > 18) {
        this.spouseAgeMsg = "";
      }
    }
  }
  spouseAgeCalT(value, Index = 0) {
    if (value.spouse_Array[0].spouseDob) {
      const convertAge = new Date(value.spouse_Array[0].spouseDob);
      const timeDiff = Math.abs(Date.now() - convertAge.getTime());
      this.spouseAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
      const formcontrol = this.spouse_Array()
      formcontrol.controls[Index].get('spouseAge').setValue(this.spouseAge);
      if (this.spouseAge <= 17) {
        this.spouseAgeMsg = "Applicant  can't be younger than 18 years of age";
      }
      if (this.spouseAge > 64) {
        this.spouseAgeMsg = "Applicant can't be older than 65 years of age";
      }
      if (this.spouseAge < 65 && this.spouseAge > 18) {
        this.spouseAgeMsg = "";
      }
    }
  }
  childAgeCal(searchValue, Index: number, dob): void {
    let id = "dob" + Index;
    if (id == dob) {
      if (searchValue !== "") {
        {
          const convertAge = new Date(searchValue);
          const timeDiff = Math.abs(Date.now() - convertAge.getTime());
          this.childAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
          if (this.childAge > 26) {
            (<HTMLInputElement>document.getElementById("childDOBerror" + Index)).innerHTML = "Child over 26 years age should buy their own plan.";
          }
          if (this.childAge < 26) {
            (<HTMLInputElement>document.getElementById("childDOBerror" + Index)).innerHTML = "";
          }
        }
        const formcontrol = this.child_Array()
        formcontrol.controls[Index].get('childAge').setValue(this.childAge);
      } else {
        this.childAge = "";
        this.ageError = ""
        const formcontrol = this.child_Array()
        formcontrol.controls[Index].get('childAge').setValue(this.childAge)
      }
    }
  }
  childAgeCalT(searchValue, Index: number, dob, va): void {
    let id = "dob" + Index;
    if (id == dob) {
      if (va.child_Array.length > 0) {
        {
          for (var x = 0; x < va.child_Array.length; x++) {
            const convertAge = new Date(va.child_Array[x].childDob);
            const timeDiff = Math.abs(Date.now() - convertAge.getTime());
            this.childAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
            if (this.childAge > 26) {
              (<HTMLInputElement>document.getElementById("childDOBerror" + Index)).innerHTML = "Child over 26 years age should buy their own plan.";
            }
            if (this.childAge < 26) {
              (<HTMLInputElement>document.getElementById("childDOBerror" + Index)).innerHTML = "";
            }
          }
        }
        const formcontrol = this.child_Array()
        formcontrol.controls[Index].get('childAge').setValue(this.childAge);
      } else {
        this.childAge = "";
        this.ageError = ""
        const formcontrol = this.child_Array()
        formcontrol.controls[Index].get('childAge').setValue(this.childAge)
      }
    }
  }
  onSearch(wght: number, value) {
    if (value == 'primary') {
      if (wght >= 501 || wght < 1) {
        this.er = "Weight range 1-500 lb"
      }
      else {
        this.er = '';
        this.weight = wght;
      }
    }
    if (value == 'spouse') {
      if (wght >= 501 || wght < 1) {
        this.spouseWtError = "Weight range 1-500 lb"
      }
      else {
        this.spouseWtError = '';
        this.weight = wght;
      }
    }
  }

  onselectChildWeight(i, event) {
    this.value = (<HTMLInputElement>document.getElementById("weightfield" + i)).value;
    let id = "weightfield" + i;
    if (event.target.id = id) {
      this.error = "childerror" + i;
      if (this.value >= 501 || this.value < 1) {
        (<HTMLInputElement>document.getElementById("childerror" + i)).innerHTML = "Weight range 1-500 lb";
      }
      if (this.value <= 500 && this.value >= 1) {
        (<HTMLInputElement>document.getElementById("childerror" + i)).innerHTML = "";
        this.error = ""
      }
    }
  }
  onfocusoutphnumber(feature) {
    if (feature == "primary") {
      let text = (<HTMLInputElement>document.getElementById("phnumbererrmsg")).innerHTML;
      let value = (<HTMLInputElement>document.getElementById("phnumber")).value;
      if (value.length < 12) {
        (<HTMLInputElement>document.getElementById("phnumbererrmsg")).innerHTML = "Please enter a valid phone number";
      }
      if (value.length >= 12) {
        (<HTMLInputElement>document.getElementById("phnumbererrmsg")).innerHTML = "";
      }
    }
  }
  onkeyupPhone(e) {
    let phnumbervalue = (<HTMLInputElement>document.getElementById("phnumber")).value;
    let transformedInput = phnumbervalue.replace(/^0*/g, '');
  }
  //================number validation==============//
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  ngOnDestroy() {
    localStorage.removeItem('planupdate')
  }
}
