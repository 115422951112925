import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GetStatesService } from 'src/app/dataservices/get-state.service';

@Component({
  selector: 'billing-address',
  templateUrl: './billing-address.component.html',
  styleUrls: ['./billing-address.component.scss']
})
export class BillingAddressComponent implements OnInit {

  shippingAddress: boolean = true;
  countries = ['UnitedStates'];
  states:Array<any>;
  subscription: Subscription;
  Billing_Address_Form: FormGroup;

  constructor(private formBuilder: FormBuilder, private svc: GetStatesService){}

  ngOnInit() {
    this.createForm();
    this.getStates();
  }

  createForm(){
    this.Billing_Address_Form = this.formBuilder.group({
      country: ['',Validators.required],
      address1: ['', Validators.required],
      address2: ['',Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zipCode: ['',Validators.required ],
    })
  }

  getStates(){
    this.subscription = this.svc.get().subscribe(data =>{
      this.states = data;
    })
  }

    ngOnDestroy(): void {
      this.subscription.unsubscribe();
    }
}
