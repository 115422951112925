<section>
    <div class="container">
        <h2>Membership Guideline</h2>
     
         
           <div class="card">
            <div class="card-body" id="heading7Two">
                <div class="col-sm-12">
<div class="col-md-8">
    <h5 class="mb-0">
        Membership Guideline Document  </h5>
</div>
<div class="col-md-2">
    <a class="btn btn-secondary" download href="assets/document/Membershipguideline.pdf"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
    
</div>
<div class="col-md-2">
    <a class="btn btn-secondary mt-10"   target="_blank" href="assets/document/Membershipguideline.pdf"><i class="fas fa-file-pdf"></i>  View File</a>
</div>
                </div>
                
            </div>
           
        </div>
       
      
    </div>

</section>