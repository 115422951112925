import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { AddOnService } from '../add-on-products/add-on-products.service';
import { TestService } from '../services/behaviourService';
import { SharingService } from '../services/sharing.service';
@Component({
  selector: 'app-applicationcart',
  templateUrl: './applicationcart.component.html',
  styleUrls: ['./applicationcart.component.scss']
})
export class ApplicationcartComponent implements OnInit {
  cartFee: any;
  cartData: any;
  memberDiscount: any;
  bmiinfo: any;
  additionalMember: any;
  orderDetails: any;
  oneTimeFee: any;
  cartProducts: any;
  groupInfo: any;
  monthlyContribution: any;
  monthlyContributionStartDate: any;
  addOnProducts = [];
  supplementalProducts = [];
  totalAddOnsPrice: number = 0;
  @Output() valueChange = new EventEmitter();
  company_id: any;
  cart_data: any;
  member_id: any;
  family_id: any;
  cart_id: void;
  constructor(
    private objectService: TestService,
    private services: SharingService,
  ) {
    this.updateCart();
  }


  ngOnInit(): void {
    this.groupInfo = this.services.getAgentGroupInfo();//G2C flow
  }

 

  updateCart() {
    this.objectService.appcartData.subscribe((data) => {
      if (data != '') {
        this.cartFee = data.cart_fee;
        this.cartData = data.cart_data;
        this.cartProducts = data.cart_products;
        this.monthlyContribution = data.monthly_contribution;
        this.monthlyContributionStartDate = data.member_applications_data[0].monthly_contribution_start_date;
        this.oneTimeFee = data.total_one_time_fee;
        this.addOnProducts = data.cart_addon_products;
        this.supplementalProducts = data.cart_supplement_products;
        this.totalAddOnsPrice = ((this.cartProducts.reduce((subtotal, item) => subtotal + item.total_price, 0)) +
          this.addOnProducts.reduce((subtotal, item) => subtotal + item.retail_price, 0) +
          this.supplementalProducts.reduce((subtotal, item) => subtotal + item.retail_price, 0));

      }
    })
  }

}
