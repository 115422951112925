import { Component, OnInit } from '@angular/core';
import { TestService } from '../services/behaviourService';
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(private objectService: TestService) { }

  ngOnInit(): void {
    this.objectService.pushheaderCondition("showheader");
  }

}
