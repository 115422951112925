import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
    selector: '[appAlphabetOnly]'
})

export class AlphabetOnlyDirective {
    key;
    @HostListener('keydown', ['$event']) onKeydown(event: KeyboardEvent) {
        this.key = event.keyCode;
        if ((this.key >= 15 && this.key <= 31) || (this.key >= 40 && this.key <= 64) || (this.key >= 96 && this.key < 189) || (this.key > 189 && this.key < 222)) {
            event.preventDefault();
        }
    }
}