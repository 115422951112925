<div class="loader" *ngIf="loader">
  <div id="loading"></div>
</div>
<section class="appointment-component pt-2">
  <h2 class="primary-title mt-0 mb-0 py-5">Fellowship HealthShare Appointment Test</h2>
  <form [formGroup]="appointmentForm" class="contactForm mb-30">
    <div class="container">
      <div class="row" *ngIf="visibledom=='qtns'">
        <div class="col-sm-12">
          <div class="card-one">
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <div class="head_txt1"> First Name <span class="fieldMan">*</span></div>
                    <input class="form-control" type="text" formControlName="firstname" autocomplete="off"
                      id="firstname" maxlength="25" placeholder="firstname">
                    <small class="errormsg" *ngIf="
                  !appointmentForm.controls.firstname.valid &&
                  (appointmentForm.controls.firstname.touched || submitAttempt)
                ">
                      First Name is required
                    </small>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <div class="head_txt1"> Last Name <span class="fieldMan">*</span></div>
                    <input class="form-control" type="text" formControlName="lastname" autocomplete="off" id="lastname"
                      maxlength="25" placeholder="lastname">
                    <small class="errormsg" *ngIf="
                  !appointmentForm.controls.lastname.valid &&
                  (appointmentForm.controls.lastname.touched || submitAttempt)
                ">
                      Last Name is required
                    </small>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <div class="head_txt1"> Email <span class="fieldMan">*</span></div>
                    <input class="form-control" type="text" formControlName="email" autocomplete="off" id="email"
                      placeholder="email">
                    <small class="errormsg" *ngIf="
                  !appointmentForm.controls.email.valid &&
                  (appointmentForm.controls.email.touched || submitAttempt)
                ">
                      Email is required
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <h6 class="text-danger font-weight-bold mt-0">
                NOTE:
              </h6>
              <ul>
                <li class="font-size mb-1">
                  Out of 30 questions, you need 24 correct answers to pass
                  &nbsp;(80%).
                </li>
                <li class="font-size">
                  There is no limit to the number of times one can take this test.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12" *ngIf="visibledom == 'result'">
          <div class="questioneer-card text-center">

            <div>
              <img *ngIf="messagearea == 'pass'" class="successImg" src="./assets/images/success.png" />
              <img *ngIf="messagearea == 'fail'" class="successImg" src="./assets/images/failure.png" />
            </div>
            <div *ngIf="messagearea == 'pass'">
              <h6 class="title-success">Congratulations !</h6>
              <h6 class="title-message">
                You Passed with Score:&nbsp; <span
                class="text-score pl-2"> {{ apiReturnedData.correct_answer }}/{{ apiReturnedData.total_question }}</span>
              </h6>
              <button class="btn btn-primary py-3 px-5" (click)="viewResult()">
                View Result
              </button>
            </div>
            <div class="" *ngIf="messagearea == 'fail'">

              <h6 class="title-message">
                You Failed with Score:&nbsp; <span
                  class="text-score pl-2">{{ apiReturnedData.correct_answer }}/{{ apiReturnedData.total_question }}</span>
              </h6>
              <button class="btn btn-primary py-3 px-5" (click)="viewResult()">
                View Result
              </button>
            </div>
          </div>

        </div>
        <div class="col-sm-12 text-center" *ngIf="visibledom == 'results'">
          <div class="questioneer-card">
            <h6 class="title-message">
              Your Score :
               <span class="text-score pl-2">{{ apiReturnedData.correct_answer }}/{{ apiReturnedData.total_question }}</span>
            </h6>
            <button class="btn btn-primary py-3 px-5 " (click)="retakeTest()">Retake Test</button>
          </div>
        </div>
        <!-- <div class="col-sm-6"  >
        <button class="btn btn-primary" (click)="retakeTest()">
          Re-Take Test
        </button>
      </div> -->
      </div>
      <div *ngIf="visibledom=='qtns'">
        <div class="" *ngFor="let question of questions">
          <div class="row">
            <div class="col-sm-12">
              <div class="questioneer-card " id="{{ 'question' + question.question_id }}">
                <h6 class="qstn-title">
                  <span> {{ question.index + ")" }}</span>
                  <span> {{question.question}} </span>
                </h6>
                <div class="form-check  " [ngClass]="[item.classname]" *ngFor="let item of question.answer">
                  <input type="radio" name="options" (change)="setAnswer(question,$event,item)"
                    id="{{ 'radio' + item.answer_id }}" value="{{ item.answer }}" name="{{ question.question }}">
                  <label class="form-check-label " for="{{ 'radio' + item.answer_id }}">
                    {{item.answer}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-md-2 " *ngIf="questions.length>0">
          <button class="btn btn-submit " id="submitbtn" (click)="submit(appointmentForm.value)">
            Submit
          </button>
        </div>
      </div>
    </div>
  </form>
  <section *ngIf="visibledom=='results'">
    <div class="container" *ngFor="let question of questions">
      <div class="row">
        <div class="col-sm-12">
          <div class="questioneer-card  " id="{{ 'question' + question.question_id }}">
            <h6 class="qstn-title">
              <span> {{ question.index + ")" }}</span>
              <span> {{question.question}} </span>
            </h6>
            <div class="form-check " id="{{ 'radio' + item.answer_id }}" *ngFor="let item of question.answer">
              <input type="radio" name="options" 
                id="{{ 'radiobtn' + item.answer_id }}" value="{{ item.answer }}" name="{{ question.question }}">
              <label class="form-check-label " for="{{ 'radio' + item.answer_id }}">
                {{item.answer}}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div *ngIf="visibledom == 'alreadyattempted'" >
      <div class="text-center">
        <h2>{{ showalreadytakentest }}</h2>
        <button class="btn btn-primary margin-bottom" (click)="retakeTest()">Retake Test</button>
      </div>
    </div>
  </section>
  
</section>


