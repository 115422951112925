<div class="container policy"  style="text-align: justify;">
    <div class="row">
        <div class="heading">
            <h3  >A. Objective:</h3>
        </div>
        <p>
            This Privacy Statement conveys the data and privacy protection principles followed by Fellowship HealthShare
            (“Fellowship”) and its units across the globe in respect of the private information of its patrons
            (including products, outsourcing and other clients), employees (current or former), trainees, contractors,
            applicants, partners, prospects, vendors and current or former members of the Board of Directors, whose
            personal data are processed by Fellowship.
        </p>

        <p>
            This Privacy Policy describes the usage and security of private information collected by Fellowship. It is
            applicable to any personal data you provide to Fellowship and, subject to regional law, any personal data we
            collect from additional sources.
        </p>
        <p>
            Throughout this Statement, “Fellowship” refers to Fellowship HealthShare, including its affiliates and
            divisions (also referred to as “we”, “us”, or “our”).
        </p>
    </div>
    <div class="row">
        <div class="heading">
            <h3>
                B. Scope:
            </h3>
        </div>
        <p>
            This Privacy Statement includes our data practices, including collection, usage and sharing of personal
            data, provided by you, via our websites that are associated with this Privacy Statement collectively
            referred to as “Fellowship’s Web sites”).
        </p>
        <p>
            Fellowship may offer links to third-party websites for transparency. In case, you click on any of these
            links, you will be redirected to the related website and leave the Fellowship site. Fellowship has no
            authority over those websites or of their privacy policies, which may diverge from Fellowship practices. We
            do not sanction or advertise any third-party websites. The information you give access to or that is
            collected by these websites (including featured social media websites on our website), does not come under
            the Fellowship Privacy Statement. We urge you to go through the privacy policies of any website prior to
            providing your personal information.
        </p>
        <p>
            Social media features may also be provided on our website that allows you to share Fellowship information
            with your social connections and to interact with Fellowship on varied social media platforms. Depending on
            the feature you use, sharing or collection of personal information may take place. We urge you to go through
            the policies and settings available on these websites, in order to be informed about the usage of the
            information provided by you to these sites.
        </p>
        <h4>
                Private Data collected by Fellowship HealthShare:
        </h4>
        <p>
            We may collect data such as your First Name, Last Name, Height, Weight, Age, Phone Number, City, Country,
            E-mail Address, Job Title and Organization Name. When you give us access to personal information for
            products or service(s) request, we will, as permitted by law, collect, use and share this data to third
            parties, for the purposes mentioned in this Statement.
        </p>
    </div>
    <div class="row">
        <div class="heading">
            <h3>
                C. Private Data provided by you to Fellowship directly through websites or emails
            </h3>
        </div>
        <p>
            We may collect personal data when you send emails, interact with us, fill out forms on or utilize the live
            chat function through our websites. This data could be your First Name, Last Name, Phone Number, City,
            Country E-mail Address, Job Title and Organization Name.
        </p>
    </div>

    <div class="row">
        <div class="heading">
            <h3>
                D. Private Data, which is automatically collected by
                <span style="color: #504646; font-weight: normal;">
                    Fellowship HealthShare
                </span>
                when you access
                <span style="color: #504646;font-weight: normal;">
                    Fellowship
                </span>
                websites
            </h3>
        </div>
        <p>
            Access Fellowship Websites
        </p>
        <p>
            Occasionally, Fellowship collects some personal information about you, when you visit our websites or
            through email exchange. Fellowship may use various technologies (including web server logs) for gathering of
            data related to IP addresses, cookies, web beacons and various device details. This information will
            increase the efficiency of the website and assist with numerous marketing activities. Please see the below
            section on cookies for further details.
        </p>
        <p>
            By using Fellowship’s websites, corresponding websites and mobile applications, you agree to the processing
            of your personal data as described in this Privacy Statement.
        </p>
        <p>
            Fellowship websites may include links to other websites, which are beyond our authority and do not follow
            our Privacy Policies. We urge you to read through the Privacy Notice of every website you visit thoroughly.
        </p>
    </div>
    <div class="row">
        <div class="heading">
            <h3>
                E. Private Data, which is collected from third party vendors or sources
            </h3>
        </div>
        <p>
            Engage with Fellowship HealthShare through social media
        </p>
        <p>
            You may interact with us through various social media platforms or the available social media features on
            the Fellowship websites. In such cases, you may allow us to have access to specific data available on your
            social media accounts, according to your data privacy settings.
        </p>
    </div>
    <div class="row">
        <div class="heading">
            <h3>
                F. Private Data, which is collected, when you visit our Websites through mobile devices
            </h3>
        </div>
        <p>
            Access our Websites through Mobile Devices
        </p>
        <p>
            In case you visit our websites through your mobile device, we may collect some personal data like, First
            Name, Last Name, Phone Number, City, Country E-mail Address, Job Title and Organization Name.
        </p>
        <p>
            By providing us your mobile number, you approve of the usage of this number for the objectives presented in
            this Privacy Statement. If you wish to avoid text notifications on your device from us, we will withhold
            from using your mobile number for such activities unless it is legally required or necessary for business
            objectives. We do not collect data beyond our requirements and will not reserve it past the essential
            timeline.
        </p>
    </div>
    
    <div class="row row-grid">
        <div class="heading">
            <h3>
                G. How We Handle your Personal Information:
            </h3>
        </div>        
        <p>
            The collected data may be used for : 
        </p>
        <ul>
            <li>
                providing you with the requested services;
            </li>
            <li>
                providing you with the requested information;
            </li>
            <li>
                resolving services and product related issues, offering you uninterrupted customer support;
            </li>
            <li>
                performing data analysis;
            </li>
            <li>
                website performance and usage evaluation;
            </li>
            <li>
                client management;
            </li>
            <li>
                leads generation and management;
            </li>
            <li>
                marketing campaigns and creating brand awareness;
            </li>
            <li>
                marketing of services and products according to authorized business demands, based on local law;
            </li>
            <li>
                processing of other necessary contractual commitments for you;
            </li>
        </ul>
        <p>
            Additional usage of your personal data and collection of sensitive information will be based on your
            explicit consent. Unless it is imperative for legal business objectives, should you wish to refrain from
            providing any private information for specific activities, you will be able to perform such activities
            without any hindrance nonetheless.
        </p>
    </div>
    <div class="row">
        <div class="heading">
            <h3>
                H. Sharing of private data with the third parties:
            </h3>
        </div>
        <p>
            Fellowship does not share your personal data with other entities like, partners, affiliates, groups, service
            providers and non-affiliated organizations except:
        </p>
        <ol class="d">
            <li> to provide requested products and services;</li>
            <li> when you give us your consent;</li>
            <li> under the following conditions:</li>
        </ol>

        <ul>
            <li>
                We may share the data with affiliated establishments/ subdivisions/ branches for legal business
                objectives.
            </li>
            <li>
                We may share the information with trusted third-party organizations, who operate on behalf of or with
                Fellowship under confidentiality agreements. These organizations may use the data for conveying various
                offers from Fellowship and for other legal business objectives. However, these entities do not have the
                legal right to broadcast this information any further.
            </li>
            <li>
                We may share your personal data with government institutions, statutory authorities or other legal
                entities, where and when required for judicial purposes.
            </li>
            <li>
                We may use the data to deal with court orders, subpoenas, legal activities, initiate or apply legal
                authorities and provide security against legal claims;
            </li>
            <li>
                We may share the data under various circumstances such as, necessary legal investigations, taking steps
                against illegal activities, potential or suspected fraud, events related to possible threats to the
                physical or mental safety of an individual, or as mandated by law;
            </li>
            <li>
                We may share the data in case we find the necessity to safeguard or impose Fellowship’s legal rights,
                usage terms and statements, intellectual or physical resources or for the welfare of Fellowship or
                related entities;
            </li>
            <li>
                We may share your personal data in case of mergers, acquisitions, and/or joint ventures with other
                organizations.
            </li>

        </ul>
        <p>
            Personal details like, your name, address, e-mail identification address (‘ID’) may be required for various
            purposes, for example, for communication, in order to provide you with various subscription related details,
            for assisting you with downloading our white papers, etc. We will always strive to safeguard the standard
            and integrity of your private information.
        </p>
        <p>
            Any unsought information provided by you will not be counted as one of Fellowship’s responsibilities. You
            allow Fellowship to use this information by agreeing to Fellowship’s Privacy Statement.
        </p>
        <p>
            Fellowship will endeavor to provide prompt response to your requests regarding rectification of imprecise
            personal data. For this purpose, please submit the message comprising the imprecise data to the sender.
        </p>
        <p>
            Fellowship’s websites are not meant for children. No individual below the age of 13 may access the websites
            without the supervision of an adult.
        </p>
        <p>
            By visiting this website, you affirm that you are 13 years of age or older.
        </p>
    </div>
    <div class="row">
        <div class="heading">
            <h3>
                I. Preferences:
            </h3>
        </div>
        <p>
            Fellowship takes fair actions to keep your private information precise and intact. You may view or update
            this information in the ways mentioned below.
        </p>
        <ul>
            <li>
                <h4  >
                    Profile
                </h4>
                <p>
                    You can login to you Fellowship account in order to update your personal information.
                </p>
            </li>
            <li>
                <h4  >
                    Newsletters
                </h4>
                <p>
                    In case of electronic communications like, an e-newsletter, you may unsubscribe at any moment by
                    taking the mention steps in the communication.
                </p>
            </li>
            <li>
                <h4  >
                    Mobile Devices
                </h4>
                <p>
                    You can stop receiving push notifications from us by managing your preferences through your device
                    or application settings. If you wish not to have your personal data collected by the mobile
                    application altogether, you can uninstall the application by following the uninstallation procedure
                    present on your device.
                </p>
            </li>
        </ul>
    </div>
    <div class="row">
        <div class="heading">
            <h3>
                J. Cookies and other technical methods
            </h3>


        </div>
        <p>
            A cookie, which is a small file, usually containing numbers and letters, is downloaded to an individual’s
            mobile device as and when the individual visits certain websites. These cookies are subsequently sent back
            to the original website for identifying the mobile device, website analytics, targeted marketing,
            calculating visitor count and visitor behavior, saving user preferences or for validation and
            authentication.
        </p>
        <p>
            <b> Strictly Necessary Cookies -</b> Such cookies, which are normally set in return of your actions related
            to logging in to your account, filling forms, services requests, managing privacy preferences etc., are
            mandatory for the website to function properly and cannot be turned off in our systems. You can manage,
            block or set alerts for these cookies by accessing your browser settings, however, various sections of the
            website will fail to function. These cookies do not reserve any private data of the users.
        </p>
        <p>
            <b>Performance Cookies -</b> These cookies are required for numerous purposes like, calculating visitor
            count and traffic sources, visitor movement and behavior, analyzing most and least popular pages of the
            website etc. These information are aggregated, anonymous and help us measure and refine website performance.
            In case you refuse to allow these cookies, we will fail to know when you visit our website and will
            consequently fail to monitor its performance.
        </p>
        <p>
            <b>Functionality Cookies -</b> The cookies may be activated by us or by third-party sources, whose services
            we have included in our website pages, in order to allow the site to offer upgraded functionality and
            customization.
        </p>
        <p>
            <b>
                Targeting Cookies - </b> The cookies, which do not reserve any personal information directly, but are
            based on identification of your browser and internet device, may be activated through our website by the
            advertising partners for creating a profile of your preferences and communicate suitable adverts on various
            other sites. In case you choose not to allow these cookies, you will see less targeted advertising.
        </p>
        <p>
            These cookies are utilized for analyzing website traffic and performance, providing personalized ads and
            content, providing social media features and for the operation of the website.
        </p>
        <p>
            We disclose the collected information related to your usage of our website to our advertising/social
            media/analytics partners, who may merge it with additional data that you have given to them or which they
            have gathered from the usage of their services. You accept these cookies in order to use this website.
        </p>
        <p>
            You can control the functional or non-essential cookies by accessing the ‘privacy’ section of your browser.
        </p>
        <p>
            Chrome:<a target="_blank" href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform=Desktop&hl=en">
                https://support.google.com/chrome/answer/95647?co=GENIE.Platform=Desktop&hl=en
            </a>
        </p>
        <p>
            Firefox: <a target="_blank" href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
                https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
            </a>
        </p>
        <p>
            Internet Explorer/: <a target="_blank" href="https://support.microsoft.com/en-in/help/17442/windows-internet-explorer-delete-manage-cookies">
                https://support.microsoft.com/en-in/help/17442/windows-internet-explorer-delete-manage-cookies
            </a>
        </p>
        <p>
            Microsoft Edge: <a target="_blank" href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">
                https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d
            </a>
        </p>
        <p>
            You can choose to accept or reject cookies, either by selecting accept or decline option available in the
            banner on your first visit (in case you decline, we will activate a cookie to reserve your preference for
            further visits), or by managing the settings in your browser, which usually set to accept cookies by
            default. Such setting can be found in the ‘privacy’ section of your web browser or within the browser app
            settings, in case of mobile devices.
        </p>

        <p>
            Choosing to accept our various cookies will result in failure in proper utilization of the website. In case
            you want to erase cookies that have already been downloaded on your computer’s hard drive, you can do so
            through your browser. Further details can be found here:
        </p>
        <p>
            <a target="_blank" href="https://www.aboutcookies.org/page/2/">
                https://www.aboutcookies.org/page/2/

            </a>
        </p>
        <p>
            We also employ tracking pixels or web bacons (a technique used on web pages or emails to discreetly allow
            checking if a user has accessed some content), which assist us with monitoring whether you have opened any
            newsletters we may have sent. These are commonly used for email tracking and page tagging for web analytics.
            We apply this data to provide you a personalized service by sending you information that may be of interest
            to you, based on your previous engagement with websites.
        </p>
        <p>
            Please visit the below mentioned link for further details about third-party analytics cookies used on
            Fellowship websites-
        </p>
        <p>
            Google Analytics Cookies:
        </p>
        <p>
            <a target="_blank" href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">
                https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage
            </a>
        </p>
        <p>
            Google has developed the<a> Google Analytics Opt-out Browser Add-on </a>in order to provide numerous options
            to the website visitors, related to data collection by Google. The add-on is linked to the Google Analytics
            JavaScript (ga.js) to specify that information regarding the website visit must not be sent to Google
            Analytics. The Google Analytics Opt-out Browser Add-on does not stop data from being delivered to the
            website itself or to various other web analytics services.
        </p>
        <p>
            Adobe Analytics Cookies:
        </p>
        <p>
            <a target="_blank" href="https://marketing.adobe.com/resources/help/en_US/whitepapers/cookies/cookies_analytics.html">
                https://marketing.adobe.com/resources/help/en_US/whitepapers/cookies/cookies_analytics.html
            </a>
        </p>
        <p>
            Fellowship also incorporates functionality to engage with social media sites, where you may have accounts
            (also known as ‘Third Part Accounts’) like, Twitter, Facebook, LinkedIn. You must be well informed about the
            fact that these sites may set cookies, which may result in collection or sharing of data related to you. We
            urge you to go through your privacy settings and policies on the social media platforms to understand the
            data that could be shared by those platforms. The bellow mentioned Pixel/Tags are used for social media-
        </p>
        <ul>
            <li>
                <a>
                    Facebook Pixel - Terms and Conditions
                </a>for use
            </li>
            <li>
                LinkedIn Insights Tag for Conversion Tracking.<a>
                    Terms and Conditions
                </a>
            </li>
            <li>
                Twitter <a>Tailored Audiences</a> and <a>Conversion Tracking</a> Program. <a>Terms and Conditions. </a>
            </li>
        </ul>
    </div>
    <div class="row">
        <div class="heading">
            <h3>
                K. Email:
            </h3>

        </div>
        <p>
            You can reach out to us via the e-mail or postal address mentioned in the ‘Contact Us’ section. We request
            that you to state the data you wish to access, your request regarding the same if any and your current
            contact details. We will strive to get back to you with an answer, subject to appropriate constraints
            provided by law, provided it does not violate the privacy of other people.
        </p>
        <p>
            We will provide you with a fair explanation, in case we fail or refuse to respond to your request and will
            notify you of any exceptions relied upon.
        </p>
    </div>
    <div class="row">
        <div class="heading">
            <h3>
                L. Anti-Spam Policy:
            </h3>
        </div>
        <p>
            Fellowship acknowledges the receiving, circulating or broadcasting of spam emails as a serious issue and has
            taken practical actions against it. Every email received by Fellowship goes through spam check and any email
            that fails this test will be dismissed with adequate details to the sender for taking mandatory steps
            against it. Fellowship strives to minimize the consequence of such emails with the aforementioned test and
            various other technical spam limiting measures. Fellowship has the authority to decline and/or report any
            suspicious emails to the concerned officials for taking further actions.
        </p>
    </div>
    <div class="row">
        <div class="heading">
            <h3>
                M. Data Protection and Confidentiality:
            </h3>
        </div>
        <p>
            Fellowship has administered practicable technical, physical and administrative safety measures to safeguard
            private data from mislaying, misuse, modification or destruction.
        </p>
        <p>
            Fellowship divulges data to third parties (agents and service providers) bound by confidentiality agreement,
            for only legal purposes. Fellowship will warrant via formally enforced contracts that the third parties
            commit to ‘same level of data protection’ as applicable data protection laws and regulations.
        </p>
        <p>
            Additionally, Fellowship may release data to government authorities or regulatory bodies based as and when
            required by law.
        </p>
        <p>
            Fellowship will take actions to make sure that actions related to private data, shall:
        </p>
        <ul>
            <li>
                be managed justly and legally;
            </li>
            <li>
                be acquired only with the assent of the user;
            </li>
            <li>
                be accessible to the information provider, as and when wished for;
            </li>
            <li>
                be gathered in case of;
                <dl>

                    <dd>
                        -legal necessity regarding a business event of Fellowship; and
                    </dd>

                    <dd>
                        -the data is considered to be mandatory for that particular event.
                    </dd>
                </dl>
            </li>
            <li>
                be preserved against uncertified and illegal actions, unexpected mislaying and defilement, according to
                the directed standards.
            </li>

        </ul>
    </div>
    <div class="row row-grid">
        <div class="heading">
            <h3>
                N. Your rights in regard to your private data:
            </h3>
        </div>
        <p>
            We value your right to be well informed, access, manage, demand deletion or limitation, disapproval,
            portability and entitlement in regard to profiling and automated decision making, in our handling of your
            private data according to law. We also ensure that the private data we gather is precise and updated.
        </p>
        <ul>
            <li>
                You are entitled to be aware of the private information we keep about you;
            </li>
            <li>
                We will issue you a copy of your data in an organized, common and machine-readable structure;
            </li>
            <li>
                You are entitled to request us to update your information if required;
            </li>
            <li>
                You are entitled to oppose the handling of private data by us;
            </li>
            <li>
                You are entitled to access your private information as and when wished for;
            </li>
            <li>
                You are entitled to oppose to the management of your data in case it is run by automated methods and
                includes decision making.
            </li>

            <li>
                You are entitled to request for deletion or restriction of the usage of your private data, based on
                applicable law, which may have an impact on the access of certain services.
            </li>
        </ul>
        <h3 style="color: #6551a0;">
            Right to withdraw
        </h3>
        <p>
            Fellowship values your safety concerns, therefore gives you an option not to provide the information asked.
        </p>
        <p>
            Furthermore, you may withdraw your consent, given to Fellowship prior, and the same must be submitted to
            Fellowship in writing.
        </p>
        <h3 style="color: #6551a0;">
            Access and corrections
        </h3>
        <p>
            You are entitled to access your private data and can request us for the same.
        </p>
        <p>
            You are entitled to correct and errors, if required, through the ‘your account’ or ‘your profile’ section of
            Fellowship website (given you have an account). You may also send us an email requesting for the desired
            rectification at-
        </p>
        <p>
            <a>support@fellowshiphealthshare.org</a>
        </p>
        <p>
            Furthermore, you may file a complaint for suspected or confirmed breach of data protection rights with the
            appropriate authorities.
        </p>
    </div>
    <div class="row">
        <div class="heading">
            <h3>
                O. Retention of private data:
            </h3>
        </div>
        <p>
            We will keep your private data based the required duration in order to provide you with requested services,
            or for crucial tasks like, implementation of an official contract, settlement of conflicts, following legal
            duties and imposing Fellowship’s policies.
        </p>
    </div>
    <div class="row">
        <div class="heading">
            <h3>
                P. Disclosure of private information:
            </h3>
        </div>
        <p>
            Fellowship may disclose your private data with your permission, or under unavoidable circumstance like,
            required by government bodies warranted by law or acting as per legal commitments. Fellowship will also make
            sure that the data shared with a third-party agency is not broadcasted any further, except where allowed by
            law.
        </p>
    </div>
    <div class="row">
        <div class="heading">
            <h3>
                Q. Global transfer of Private Data:
            </h3>
        </div>
        <p>
            Fellowship may send specific data across different geographical regions to different Fellowship
            establishments or service providers in varied nations functioning on Fellowship’s behalf according to the
            legal policies. Sending or transferring of data or information may imply to movement, access, communication
            of data to a different province or otherwise.
        </p>
        <p>
            Distinct requirements or policies may apply, in case some private data is sent beyond national borders,
            where Fellowship operates under separate jurisdictions.
        </p>
        <p>
            For example, data or information protection laws of the EEA and of Switzerland sanction the sharing of
            private data:
        </p>
        <ul>
            <li>
                To countries, which are granted by the European Commission as presenting an appropriate measure of
                safety of private information;
            </li>
            <li>
                In case an adequate level of compliance with the European Union data protection legal requirements is
                available in the nations that do not provide sufficient safety measures.
            </li>
        </ul>
        <p>
            In such conditions, the recipient Fellowship establishment or a Third-Party vendor follows the European
            Union standard contractual clauses, to secure the safety of private data.
        </p>
        <p>
            Fellowship may also send private data to a Third-Party located outside the EEA or Switzerland, without
            following the above rules, where any of the below mentioned requirements is met-
        </p>
        <ul>
            <li>
                the data provider has assent to the sharing of private data by Fellowship;
            </li>
            <li>
                the sharing includes publicly available data (e.g., from social media platforms or public registers);
            </li>
            <li>
                Fellowship requires to share the private data to perform or conclude an agreement with the data
                provider; the sharing of data is mandatory a) for the safety of the data provider’s crucial affairs
                (i.e., a life-or-death situation), or b) for establishment, exercise or defense of Fellowship’s lawful
                claims, or c) for public interest.
            </li>
        </ul>
        <p>
            Fellowship maintains ‘same level of data protection’ according to the relevant laws and regulations in case
            of sharing of data to other entities or across geographical boundaries.
        </p>
    </div>
    <div class="row">
        <div class="heading">
            <h3>
                R. Genuineness:
            </h3>
        </div>
        <p>
            Fellowship will not be accountable for the genuineness of the private data given to it by its service
            providers, who are responsible for the genuineness of the data furnished by them or their employees.
        </p>
    </div>
    <div class="row">
        <div class="heading">
            <h3>
                S. Grievance Redressal:
            </h3>
        </div>
        <p>
            All the objections or grievances regarding the usage or management of private data, must be conveyed to
            Fellowship in writing to the below mentioned email ID. Fellowship will respond to these complaints as
            promptly as possible.
        </p>
        <p>
            Email: <a>support@fellowshiphealthshare.org</a>
        </p>
        <p>
            It should be duly noted that this Privacy Statement may be changed or updated in a timely manner. The
            updated Privacy Statement will be published on this page. Fellowship will not lessen your rights without
            your full knowledge and assent. We urge you to be review this Privacy Statement from time to time in order
            to be in the know about the latest changes.
        </p>
        <p style="font-style: italic; margin: 30px 0;">
            This Privacy Statement was last updated on April 5, 2021.
        </p>
    </div>
</div>