<!-- <div class="container">
    <section id="two" class="wrapper style3 fade-up">
        <div class="inner mb-9">
            <h2>Who we are</h2>

            <p>Fellowship Healthshare is a nonprofit healthcare sharing ministry. We guide our faith community to share what
                they have to bless those in need. Our members give and receive financial support for medical needs. We believe
                that healthcare in God’s image is open to everyone, easy to enroll in, and free from stress..</p> 
          
            
        </div>
    </section>
</div>
              -->

<section>
    <h2>Who we are</h2>
    <p>Fellowship Healthshare is a nonprofit healthcare sharing ministry. We guide our faith community to share what
        they have to bless those in need. Our members give and receive financial support for medical needs. We believe
        that healthcare in God’s image is open to everyone, easy to enroll in, and free from stress..</p> 
</section>