import { Component, OnInit } from '@angular/core';
import { TestService } from '../services/behaviourService';

@Component({
  selector: 'app-program-brochure',
  templateUrl: './program-brochure.component.html',
  styleUrls: ['./program-brochure.component.scss']
})
export class ProgramBrochureComponent implements OnInit {

  constructor(private objectService: TestService ) { }

  ngOnInit(): void {
    this.objectService.pushheaderCondition("showheader");
  }


}
