<div class="card">
  <div class="plan-card-header apclnt-card-header ">
    <h6 class="mb-0 py-2 f-600"> Payment Details</h6>
  </div>
  <div class="cardsection">
    <radio-button [items]="radioGroup" [(ngModel)]="paymentType" (changeHandler)="paymentTypeChange($event)">
    </radio-button>
  </div>
  <bank-payment *ngIf="isBankType"></bank-payment>
  <card-payment *ngIf="!isBankType"></card-payment>
  <div class="row">
    <div class="col-sm-12">
      <i class="transactions">Transactions are secure and encrypted.</i>
    </div>
  </div>
  <billing-address></billing-address>
</div>
