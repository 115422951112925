import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { TestService } from '../services/behaviourService';
import { SharingService } from '../services/sharing.service';
import { ProductsModel } from './add-on-products.model';
import { AddOnService } from './add-on-products.service';
import { Hc2uDetailsComponent } from './hc2u-details/hc2u-details.component';
import { MtmDetailsComponent } from './mtm-details/mtm-details.component';

@Component({
  selector: 'app-add-on-products',
  templateUrl: './add-on-products.component.html',
  styleUrls: ['./add-on-products.component.scss']
})

export class AddOnProductsComponent implements OnInit {

  applicant: any;
  add_on_products_choice: Array<ProductsModel> = [];
  add_on_products_basic: Array<ProductsModel> = [];
  add_on_products_essential: Array<ProductsModel> = [];
  supplemetal_products: Array<ProductsModel> = [];
  company_id: string;
  plan_ids = [];
  products: any;
  cart_data: any;
  imgPath: any;

  @ViewChild('mtmDetails') mtmDetails: MtmDetailsComponent;
  @ViewChild('hc2uModal') hc2u: Hc2uDetailsComponent;
  memberdata: any;
  groupID: any;
  applicantData: any;
  supplementalProducts: Array<any> = [];
  addOnProducts: Array<any> = [];
  member_id: any;
  cart_id: any;
  family_id: any;
  subscription: Subscription;


  constructor(
    private objectService: TestService,
    private addOnService: AddOnService,
    private svc: SharingService,
    private router: Router,
    private route: ActivatedRoute,
    private services: SharingService,
  ) {
    this.svc.clientData.subscribe((data) => {
      if (data != '') {
        this.company_id = data.company_id;
        this.imgPath = data.emailURL;
      }
    })
    this.objectService.cartMemberId.subscribe((data) => {
      this.member_id = data.member_id;
      this.cart_id = data.cart_id
    })

    var url = 'GetMemberDependents/' + this.company_id + '/' + this.member_id + '/' + this.cart_id;
    this.services.getData(url).subscribe((Object: any) => {
      this.family_id = Object.member_personal_data[0].family_id;

    })

  }

  ngOnInit(): void {
    this.subscription =  this.objectService.appcartData.subscribe((data: any) => {
        if (data != '') {
          this.addOnProducts = data.cart_addon_products;
          this.supplementalProducts = data.cart_supplement_products;
          this.cart_data = data.cart_data[0];
          this.applicant = data.member_applications_data[0];

          let cart_products = data.cart_products;
          this.plan_ids = [];
          cart_products.forEach(x => {
            this.plan_ids.push(x.plan_id)
          });
          if (this.plan_ids.length > 0) {
            this.getProductList();
          }


        }
      })

    this.objectService.cartPlan.subscribe(data => {
      if (data != '') {
        
        this.memberdata = data.member_personal_data;
        this.groupID = data.member_personal_data[0].group_id;//G2C flow
        this.applicantData = data;
      }
    })
    setTimeout(() => {
      this.clearAppCart();
    }, 600);

  }

  clearAppCart() {
    if (this.addOnProducts.length > 0) {
      this.addOnProducts.forEach(product => {
        this.updateAppCart(product.product_id, 0)
      });
    }
    if (this.supplementalProducts.length > 0) {
      this.supplementalProducts.forEach(product => {
        this.updateAppCart(product.product_id, 0)
      });
    }
  }

  getProductList() {
    let params = { plan_ids: this.plan_ids }
    var url = 'GetPlanProducts' + '/' + this.company_id;
    this.svc.postData(url, params).subscribe((data: any) => {
      this.products = data;
      this.supplemetal_products = data.data.supplement;
      if (data.data.addon) {
        if (data.data.addon[1]) {
          this.add_on_products_choice = data.data.addon[1];
        }
        if (data.data.addon[2]) {
          this.add_on_products_essential = data.data.addon[2];
        }
        if (data.data.addon[3]) {
          this.add_on_products_basic = data.data.addon[3];
        }
      }
    })
  }
  checkStatus(product) {
    let status = false;
    product.active = true;
    this.objectService.appcartData?.subscribe((data) => {
      if (!!data) {
        data.cart_addon_products?.forEach(x => {
          if (x.product_id == product.product_id) {
            status = true;
            product.active = false;
          }
        });
        data.cart_supplement_products?.forEach(x => {
          if (x.product_id == product.product_id) {
            status = true;
            product.active = false;
          }
        });
      }
    })
    return status;
  }
  updateAppCart(product_id, active) {
    var url = 'UpdateCartProduct' + '/' + this.cart_data.id;
    let product_quantity = 1;
    let obj = {
      company_id: this.company_id,
      product_id: product_id,
      product_quantity: product_quantity,
      active: active
    }
    this.svc.putData(url, obj).subscribe((x: any) => {
      if (x.status == "success")
        this.applicationCart();
    })
  }
  applicationCart() {
    if (this.company_id != undefined && this.family_id != undefined && this.cart_id != undefined) {
      var url = 'GetCartPlan/' + this.company_id + '/' + this.cart_id + '/' + this.family_id;
      this.services.getData(url).pipe(take(1)).subscribe((Object: any) => {
        if (!!Object) {
          this.objectService.appCartInfo(Object);
        }
      })
    }
  }
  goTODemography() {
    let route = this.route.snapshot.paramMap.get('routeTo');
    if (route == "summary") {
      this.router.navigate(['summary']);
    } else {
      this.router.navigate(['demographic']);
    }
  }
  addProduct(product) {
    this.updateAppCart(product.product_id, 1)
  }
  removeProduct(product) {
    this.updateAppCart(product.product_id, 0)
  }
  viewProductDetails(product) {
    if (product.plan_id == 1 || product.plan_id == 2) {
      this.mtmDetails.show();
    }
    if (product.plan_id == 3) {
      this.hc2u.show();
    }
  }
  backtoD2C() {
    
    if (this.memberdata[0].relation == "Guardian") {
      var url = 'GetMemberDependents/' + this.company_id + '/' + this.memberdata[1].member_id + '/' + this.applicantData.cart_data[0].id;
    } else {
      var url = 'GetMemberDependents/' + this.company_id + '/' + this.memberdata[0].member_id + '/' + this.applicantData.cart_data[0].id;
    }
    this.svc.getData(url).subscribe((Object: any) => {
      
      this.objectService.demoToA2c(Object);
      if (this.groupID != null) {
        this.router.navigate(['dynamicplan/' + localStorage.getItem('urlGroupName')]);
      } else if(localStorage.getItem('urlGroupName') !=''){
        this.router.navigate(['dynamicplan/' + localStorage.getItem('urlGroupName')]);
      } else {
        this.router.navigate(['dynamicplan']);
      }
      localStorage.setItem('planupdate', 'updateplan')
    }, err => {
      console.log(err)
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
