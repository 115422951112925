<div class="container m-top" style="padding: 0 0 30px 0;">
    <div class="applicationProgress">
        <div class="appProgStep " style="z-index:5; padding-left: 30px;" id="app1">Demographics &amp; Dependents</div>
        <div class="appProgStep active" style="z-index:3;" id="app3">Health-Based Questions</div>
        <div class="appProgStep" style="z-index:2;" id="app4">Payment Details</div>
        <div class="appProgStep appProgStep-last" style="z-index:1;" id="app5">
            <span>Summary</span>
        </div>
    </div>
    <div class="container m-top">
        <form [formGroup]="Form">
            <div class="row demomargin">
                <div class="col-lg-4 col-md-12" style="padding: 0;">
                    <div class="card">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-xs-6">
                                <div class="planehead">Zip Code :</div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-6" style="text-align: right;">
                                <div class="planehead"> {{applicant.zipcode}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-xs-6">
                                <div class="planeh">Effective Start Date :</div>
                            </div>
                            <div class="col-lg-6 col-md-6  col-xs-6" style="text-align: right;">
                                <div class="planeh"> {{applicant.startdate | date:'MM/dd/yyyy'}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="card  ">
                        <div class="row">
                            <div class="col-lg-6">
                                <div>Application Cart</div>
                            </div>
                        </div>
                    </div>
                    <app-applicationcart></app-applicationcart>
                    <div class="card" *ngFor="let item of agentInfo">
                        <div class="row">
                            <div class="col-lg-12 cartHead">
                                Agent Information
                            </div>
                            <div class="col-lg-12"> {{item.firstname}} {{item.lastname}}</div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-xs-4">
                                <div class="planehead">Mobile &nbsp; : </div>
                                <!-- <div class="planeh">E-mail &nbsp; :</div> -->
                            </div>
                            <div class="col-lg-8 col-md-8 col-xs-8">
                                <div class="planehead">{{item.mobile}}</div>
                                <!-- <div class="planeh">{{item.email}}</div> -->
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-xs-4">
                                <!-- <div class="planehead">Mobile &nbsp; : </div> -->
                                <div class="planeh">E-mail &nbsp; :</div>
                            </div>
                            <div class="col-lg-8 col-md-8 col-xs-8">
                                <!-- <div class="planehead">{{item.mobile}}</div> -->
                                <div class="planeh">{{item.email}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 qtns" style="padding-right: 0;">
                    <div id="accordion" role="tablist" aria-multiselectable="true">
                        <div class="card bg_acc" *ngFor="let item of healthQuestions; let i=index;">
                            <div class="card-header1" role="tab" id="accordionHeadingOne">
                                <div class="mb-0 row">
                                    <div class="col-12 no-padding accordion-head">
                                        <a data-toggle="collapse" data-parent="#accordion" id="openitem1"
                                            [attr.data-target]="'#accordionBodyOne'+i" aria-expanded="false"
                                            aria-controls="accordionBodyOne" class="collapsed ">
                                            <i class="fa fa-angle-down" aria-hidden="true"
                                                style="font-size: 20px;color: #000"></i>
                                            <div class="qtsHeared">{{i+1}}) {{item.name}}</div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div [attr.id]="'accordionBodyOne'+i" class="collapse" role="tabpanel"
                                aria-labelledby="accordionHeadingOne" aria-expanded="false" data-parent="accordion">
                                <div class="card-block row">
                                    <div class="col-lg-4" *ngFor="let product of item.healthissue;let j=index">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="qtsvalue" *ngIf="product.field_type=='Checkbox'">
                                                    <label>
                                                        <input type="checkbox" value="{{product.health_issue_id}}"
                                                            name="{{product.health_issue_id}}"
                                                            [disabled]="product.disabled" [checked]="product.Checked"
                                                            (change)="onChangeQts(product, $event.target.checked, i)">
                                                        {{product.value}}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="qtsvalue" *ngIf="product.field_type=='checkbox'">
                                                    <label>
                                                        <input type="checkbox" [disabled]="product.disabled"
                                                            value="{{product.health_id}" name="{{product.member_id}}"
                                                            [checked]="product.Checked"
                                                            (change)="onChange(product, $event.target.checked, i)">
                                                        {{product.name}}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12" *ngFor="let member of item.memberhealthissue">
                                        <div class="memr_name">{{member.name | titlecase}}
                                            <hr>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6 qtsvalue" *ngFor="let x of member.healthissue">
                                                <label>
                                                    <input type="checkbox" [checked]="x.Checked"
                                                        (change)="onChangeMember(x,member, $event.target.checked, i)">
                                                    {{x.value}}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row conditions" *ngFor="let item of mgalert; let i=index">
                <div class="col-lg-12 alert2" style="padding: 0;">
                    {{i+1}}) {{item.alert}}
                </div>
            </div>
            <div class="row conditions">
                <div class="col-lg-12" style="padding: 0; text-align: justify;">
                    <div class="head_op">
                        <label><input type="checkbox" formControlName="acknowledgeCheck"> By checking this box I
                            acknowledge
                            the above information is true and
                            accurate, any information that is determined to be inaccurate may result in a medical need
                            being
                            ineligible for sharing.
                        </label>
                        <div class="errormsg"
                            *ngIf="!Form.controls.acknowledgeCheck.valid && (Form.controls.acknowledgeCheck.touched || submitAttempt)">
                            Acknowledge checkbox is required
                        </div>
                    </div>
                    <div class="head_op">
                        <label> <input type="checkbox" formControlName="acknowledgeCheck1">&nbsp;
                            By checking this box I acknowledge that I understand the testing
                            requirements listed above based upon my current and future age.
                        </label>
                        <div class="errormsg"
                            *ngIf="!Form.controls.acknowledgeCheck1.valid && (Form.controls.acknowledgeCheck1.touched || submitAttempt)">
                            Acknowledge checkbox is required
                        </div>
                    </div>
                    <div class="head_op1">
                        By Clicking the <b> "Next" </b> button, you confirm that the above information is correct.
                        You will not be able to make changes to this information again in this application.
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-6 col-xs-6" style="text-align: left;padding: 0;">
                            <button type="button" class="btn btn-primary"
                                (click)="changeToDemography(1)">Previous</button>
                        </div>
                        <div class="col-lg-6 col-xs-6" style="text-align: right;padding: 0;">
                            <button type="button " (click)="submitHealthQts(Form.value)"
                                class="btn btn-success">Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>