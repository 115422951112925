 
<div class="container mt-3 mb-3" *ngIf="blogDetails">
  <ul class="mt-3 mb-3  p-0">
    <li class="list-inline-item mr-4">
      <a routerLink="/blog">« Back To Blog </a>
    </li>
  </ul>
  <h2>
    {{blogDetails.blog_title}}
  </h2>
  <h3>
    {{blogDetails.blog_shortdesc}}
  </h3>

  <div class="dynamicblogdata" id="dynamicblogdata">
    <div [innerHTML]="blogDetails.blog_longdesc">

    </div>
  </div>
</div>