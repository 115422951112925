<main id="content" style="background-color: #f5f8fb;">
    <!-- Hire Us Form -->
    <div class="container  space-2 space-3--lg">
      <form class="js-validate w-lg-75 px-3 px-sm-9 mx-lg-auto nss"  novalidate="novalidate">
        <!-- Title -->
        <div class="w-md-80  text-center mx-md-auto mb-9">
          <!-- <span class="u-label u-label--sm u-label--purple mb-3">Hire us</span> -->
          <h1 class="h3">Advance Opinion for Eligibility</h1>
          <hr>
        </div>
        <!-- End Title -->
        <h4 class="u-label u-label--sm u-label--purple mb-4" style="font-weight: 600;">Procedure Information:</h4>
        
        <!-- Input Form -->
        <div class="row align-items-center mb-3">
          <div class="col-md-4">
            <label class="h6 small d-block text-uppercase mb-3 mb-lg-0">Procedure Description<span class="mandatory-red">*</span></label>
          </div>
          <div class="col-md-8">
            <div class="js-form-message">
              <div class="js-focus-state input-group form">
                <input class="form-control form__input" type="text" name="fullName" required="" placeholder="Procedure Description" aria-label="Jack Wayley" data-msg="Please enter your Procedure Description" data-error-class="u-has-error" data-success-class="u-has-success">
              </div>
            </div>
          </div>
        </div>
        <!-- End Input Form -->

        <div class="row align-items-center mb-3">
            <div class="col-md-4">
              <label class="h6 small d-block text-uppercase mb-3 mb-lg-0">Priority<span class="mandatory-red">*</span></label>
            </div>
            <div class="col-md-8">
              <select class="custom-select form-control">
                <option selected="0">Non-Urgent</option>
                <option value="budget1">Online </option>
                <option value="budget2">Blog</option>
                <option value="budget3">Other</option>
              </select>
            </div>
          </div>
          <div class="row align-items-center mb-3">
            <div class="col-md-4">
              <label class="h6 small d-block text-uppercase mb-3 mb-lg-0">Priority Reason:</label>
            </div>
            <div class="col-md-8">
              <div class="js-form-message">
                <div class="js-focus-state input-group form">
                  <input class="form-control form__input" type="text" name="fullName" required="" placeholder="Priority Reason" aria-label="Jack Wayley" data-msg="Please enter your Priority Reason" data-error-class="u-has-error" data-success-class="u-has-success">
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center mb-3">
            <div class="col-md-4">
              <label class="h6 small d-block text-uppercase mb-3 mb-lg-0">Associated ICD-10 Diagnosis Code<span class="mandatory-red">*</span></label>
            </div>
            <div class="col-md-8">
              <div class="js-form-message">
                <div class="js-focus-state input-group form">
                  <input class="form-control form__input" type="text" name="fullName" required=""  aria-label="Jack Wayley" data-msg="Please enter your Associated ICD-10 Diagnosis Code" data-error-class="u-has-error" data-success-class="u-has-success">
                </div>
              </div>
            </div>
          </div>
           <div class="row align-items-center mb-3">
            <div class="col-md-4">
              <label class="h6 small d-block text-uppercase mb-3 mb-lg-0">Requested CPT Code(s)<span class="mandatory-red">*</span></label>
            </div>
            <div class="col-md-8">
              <div class="js-form-message">
                <div class="js-focus-state input-group form">
                  <input class="form-control form__input" type="text" name="fullName" required=""  aria-label="Jack Wayley" data-msg="Requested CPT Code(s)" data-error-class="u-has-error" data-success-class="u-has-success">
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center mb-3">
            <div class="col-md-4">
              <label class="h6 small d-block text-uppercase mb-3 mb-lg-0">Anticipated Date Of Service<span class="mandatory-red">*</span></label>
            </div>
            <div class="col-md-8">
              <div class="js-form-message">
                <div class="js-focus-state input-group form">
                  <input class="form-control form__input" type="date" name="fullName" required=""  aria-label="Jack Wayley" data-msg="Enter a date" data-error-class="u-has-error" data-success-class="u-has-success">
                </div>
              </div>
            </div>
          </div>
          <h4 class="u-label u-label--sm u-label--purple mt-4 mb-4" style="font-weight: 600;">Provider Information</h4>
        
        <!-- Input Form -->
        <div class="row align-items-center mb-3">
          <div class="col-md-4">
            <label class="h6 small d-block text-uppercase mb-3 mb-lg-0">Treating Physician<span class="mandatory-red">*</span></label>
          </div>
          <div class="col-md-8">
            <div class="js-form-message">
              <div class="js-focus-state input-group form">
                <input class="form-control form__input" type="text" name="email" required=""  data-msg="can't be blank" data-error-class="u-has-error" data-success-class="u-has-success">
              </div>
            </div>
          </div>
        </div>
        <!-- End Input Form -->
        <div class="row align-items-center mb-3">
            <div class="col-md-4">
              <label class="h6 small d-block text-uppercase mb-3 mb-lg-0">Physician Phone<span class="mandatory-red">*</span></label>
            </div>
            <div class="col-md-8">
              <div class="js-form-message">
                <div class="js-focus-state input-group form">
                  <input class="form-control form__input" type="text" name="email" required=""  data-msg="can't be blank" data-error-class="u-has-error" data-success-class="u-has-success">
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center mb-3">
            <div class="col-md-4">
              <label class="h6 small d-block text-uppercase mb-3 mb-lg-0">Physician Fax<span class="mandatory-red">*</span></label>
            </div>
            <div class="col-md-8">
              <div class="js-form-message">
                <div class="js-focus-state input-group form">
                  <input class="form-control form__input" type="text" name="email" required=""  data-msg="can't be blank" data-error-class="u-has-error" data-success-class="u-has-success">
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center mb-3">
            <div class="col-md-4">
              <label class="h6 small d-block text-uppercase mb-3 mb-lg-0">Facility Name</label>
            </div>
            <div class="col-md-8">
              <div class="js-form-message">
                <div class="js-focus-state input-group form">
                  <input class="form-control form__input" type="text" name="email" required=""  data-msg="can't be blank" data-error-class="u-has-error" data-success-class="u-has-success">
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center mb-3">
            <div class="col-md-4">
              <label class="h6 small d-block text-uppercase mb-3 mb-lg-0">Facility Phone</label>
            </div>
            <div class="col-md-8">
              <div class="js-form-message">
                <div class="js-focus-state input-group form">
                  <input class="form-control form__input" type="text" name="email" required=""  data-msg="can't be blank" data-error-class="u-has-error" data-success-class="u-has-success">
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center mb-3">
            <div class="col-md-4">
              <label class="h6 small d-block text-uppercase mb-3 mb-lg-0">Facility Fax</label>
            </div>
            <div class="col-md-8">
              <div class="js-form-message">
                <div class="js-focus-state input-group form">
                  <input class="form-control form__input" type="text" name="email" required=""  data-msg="can't be blank" data-error-class="u-has-error" data-success-class="u-has-success">
                </div>
              </div>
            </div>
          </div>
          <h4 class="u-label u-label--sm u-label--purple mb-4 mt-4" style="font-weight: 600;">Patient Information</h4>
          <span class="u-label u-label--sm u-label--purple mb-3"> Patient First Name,Last Name and birthdate must match ID card exactly</span>
       
        <!-- Input Form -->
        <div class="row align-items-center mb-3">
          <div class="col-md-4">
            <label class="h6 small d-block text-uppercase mb-3 mb-lg-0">First Name<span class="mandatory-red">*</span></label>
          </div>
          <div class="col-md-8">
            <div class="js-form-message">
              <div class="js-focus-state input-group form">
                <input class="form-control form__input" type="text" name="deadline" required=""  data-error-class="u-has-error" data-success-class="u-has-success">
              </div>
            </div>
          </div>
        </div>
        <div class="row align-items-center mb-3">
            <div class="col-md-4">
              <label class="h6 small d-block text-uppercase mb-3 mb-lg-0">Last Name<span class="mandatory-red">*</span></label>
            </div>
            <div class="col-md-8">
              <div class="js-form-message">
                <div class="js-focus-state input-group form">
                  <input class="form-control form__input" type="text" name="deadline" required=""  data-error-class="u-has-error" data-success-class="u-has-success">
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center mb-3">
            <div class="col-md-4">
              <label class="h6 small d-block text-uppercase mb-3 mb-lg-0">Birth Date<span class="mandatory-red">*</span></label>
            </div>
            <div class="col-md-8">
              <div class="js-form-message">
                <div class="js-focus-state input-group form">
                  <input class="form-control form__input" type="date" name="deadline" required=""  data-error-class="u-has-error" data-success-class="u-has-success">
                </div>
              </div>
            </div>
          </div>
          <span class="u-label u-label--sm u-label--purple mb-3"> Medical Records. You may Upload Up to 3 Files</span>
          <hr class="my-5">
         <div class="row align-items-center mb-3">
          <div class="col-md-4">
            <label class="h6 small d-block text-uppercase mb-3 mb-lg-0">How Many Files You want to upload?<span class="mandatory-red">*</span></label>
          </div>
          <div class="col-md-8">
            <select class="custom-select form-control">
                <option selected="">1</option>
                <option value="budget1">2</option>
                <option value="budget2">3</option>
                <option value="budget3">>5</option>
              </select>
          </div>
        </div>
        <div class="row align-items-center mb-3">
            <div class="col-md-4">
              <label class="h6 small d-block text-uppercase mb-3 mb-lg-0">Select File</label>
            </div>
            <div class="col-md-8">
              <div class="js-form-message">
                <div class="js-focus-state input-group form">
                  <input class="form-control form__input" type="file" name="deadline" required=""  data-error-class="u-has-error" data-success-class="u-has-success">
                </div>
              </div>
            </div>
          </div>
        <!-- End Input Form -->

  

        <!-- Input Form -->
        
        <!-- End Input Form -->

      
        <div class="captcha">
          <div class="spinner">
            <label>
              <input type="checkbox" onclick="$(this).attr('disabled','disabled');">
              <span class="checkmark"><span>&nbsp;</span></span>
            </label>
          </div>
          <div class="text">
            I'm not a robot
          </div>
          <div class="logo">
            <img src="https://forum.nox.tv/core/index.php?media/9-recaptcha-png/"/>
            <p>reCAPTCHA</p>
            <small>Privacy - Terms</small>
          </div>
        </div>
  
        <!-- End Input Form -->
        <div class="text-center mt-4 ">
            <button type="submit" class="btn btn-primary mb-4">Submit</button>
            
          </div>
     

        
      </form>
    </div>
    <!-- End Hire Us Form -->
  </main>
