import { Component, OnInit } from '@angular/core';
import { TestService } from '../services/behaviourService';
declare var $: any;


@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent implements OnInit {
  
  constructor(private objectService: TestService) { }

  ngOnInit(): void {
    
   
// $(document).ready(function() {
//   //toggle the component with class accordion_body
//   $(".accordion_head").click(function() {
//     if ($('.accordion_body').is(':visible')) {
//       $(".accordion_body").slideUp(300);
//       $(".plusminus").text('+');
//     }
//     if ($(this).next(".accordion_body").is(':visible')) {
//       $(this).next(".accordion_body").slideUp(300);
//       $(this).children(".plusminus").text('+');
//     } else {
//       $(this).next(".accordion_body").slideDown(300);
//       $(this).children(".plusminus").text('-');
//     }
//   });
// });
$(document).ready(function () {
  $('.accordion-toggle').on('click', function(event){
    
    event.preventDefault();
    
    var accordion = $(this);
    var accordionContent = accordion.next('.accordion-content');
    if ($(this).next(".accordion-content").is(':visible')) {
      
      $(this).children(".plusminus").text('+');
      
    } else {
      $(this).children(".plusminus").text('-');
      
    }
    
    accordion.toggleClass("open");
   
    accordionContent.slideToggle(250);
  
   
  });
});
    this.objectService.pushheaderCondition("showheader");
  }

}
