<!-- <div class="bg-gray-100"> -->

<div class="container space-3--lg space-2">
    <section id="two" class="wrapper style3 fade-up">
        <h2>&lsquo;Frequently Asked Questions&rsquo;</h2>
        <hr class="sep-2">
        
         <div class="accordion_container">
          <div class="accordion_head">What is Fellowship Healthshare?<span class="plusminus">-</span></div>
          <div class="accordion_body">
         
          <p>Fellowship is a not-for-profit Medical Cost Sharing community that offers an innovative, non-insurance approach to managing large healthcare costs. As a not-for-profit organization, Fellowship is focused on serving our members and carrying out our community-centric mission. Fellowship Members join a community of like-minded individuals who are active and engaged participants in their healthcare decision making. Together, we are tapping the power of community to create a new normal in healthcare that is high-quality, human, and transparent.
       </p>
          </div>
          <div class="accordion_head"> Is Fellowship available in all states?<span class="plusminus">+</span></div>
          <div class="accordion_body" style="display: none;">
            <p>
                Membership in the Fellowship Medical Cost Sharing Community is available in almost every state. Fellowship, however, is currently not accepting new Members in Illinois, New York, Vermont, and Washington. We will update this page as soon as new states become available.</p>

              
          </div>
          <div class="accordion_head">What Kind of company is Fellowship?<span class="plusminus">+</span></div>
          <div class="accordion_body" style="display: none;">
            <p>
                The Fellowship Medical Cost Sharing Community is a not-for-profit Medical Cost Sharing community, focused on serving the engaged healthcare consumer. Fellowship facilitates the direct sharing of medical costs among our participating Members (the Community).
            </p>
            
          </div>
          <div class="accordion_head">What other features are included with a Fellowship Medical cost Sharing Community Membership?<span class="plusminus">+</span></div>
          <div class="accordion_body" style="display: none;">
            <p>
                Fellowship membership also comes with access to additional tools including convenient telehealth services for basic healthcare needs, expert second opinions, wellness support and resources to help Members live their healthiest lives, and medical bill negotiation services when necessary to ensure the Members are not overpaying for their healthcare costs.
</p><p>                Some Memberships may include add-ons and/or additional products that are not provided by Fellowship but instead are provided, serviced and maintained by third parties. Fellowship makes no representations or warranties that every or all Memberships include any add-on/additional product(s). Members should utilize the Member Portal to better understand what their Membership(s) includes.
                 </p>
          </div>
        </div>
        <!-- <div class="accordion-container">
          <div href="#" class="accordion-toggle">Who Can receive<span class="plusminus">-</span></div>
          <div class="accordion-content">
            <ul style="list-style: square;">
              <li>
               Groups
              </li>
              <li>
               Individuals
              </li>
              <li>
               Families
              </li>
              <li>
               ANYONE who meets the membership eligibility requirements
              </li>
          </ul>
          <p>Unlike traditional health insurance, there are: NO enrollment restrictions. NO enrollment periods. NO penalties.
       </p>
          </div>
        </div>
        <div class="accordion-container">
          <div href="#" class="accordion-toggle">How Do I Contribute?<span class="plusminus">+</span></div>
          <div class="accordion-content" style="display: none;">
            <p>
              You’ll make an affordable monthly contribution according to the plan you choose.</p>
          <p>    Your monthly contribution is combined with those of other individuals and families that share your faith.</p>
          <p> All this money is used to cover member’s medical bills when a need arises. This is much more affordable than COBRA or buying Major Medical Health Insurance without government subsidy.</p>
              
          </div>
        </div>
        <div class="accordion-container">
          <div href="#" class="accordion-toggle">When Can I Receive?<span class="plusminus">+</span></div>
          <div class="accordion-content" style="display: none;">
            <p>
              We welcome you to join at any time. There are NO ENROLLMENT PERIODS.
            </p>
            <p>
              Each member or family chooses a plan with a Member Responsibility Amount (MRA), which is the minimum dollar amount they must contribute to the community before their own needs can be paid. The higher the MRA, the lower their monthly contribution and vice versa.
            </p>
            <p>
              Once a member’s MRA has been met, the community begins to pay his or her qualified medical bills.
            </p>
          </div>
        </div>
        <div class="accordion-container">
          <div href="#" class="accordion-toggle">How Do I Receive?<span class="plusminus">+</span></div>
          <div class="accordion-content" style="display: none;">
            <p>
              See our Programs to learn more about what your extensive health benefits could be. There’s an option for every budget and lifestyle.
                 </p>
          </div>
        </div> -->
    </section>
</div>
<!-- </div> -->