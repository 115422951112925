<div class="loader" *ngIf="loader">
    <div id="loading"></div>
</div>
<div class="container mb-4">
    <form class="formMargin" [formGroup]="newForm">
        <div class="row">
            <div class="col-lg-4 mb-3">
                <ng-container *ngIf="groupUrl">
                    <div *ngFor="let item of groupData" >
                        <div class="companyLogo" *ngIf="item?.group_logo !=null; else elseGroupName">
                            <img src="{{item.group_logo}}">
                        </div>
                        <ng-template #elseGroupName>
                            <div class="groupC">{{item.name}}</div>
                        </ng-template>
                    </div>
                </ng-container>

                <ng-container *ngIf="!groupUrl">

                <div *ngFor="let item of groupData" >
                    <div class="companyLogo" *ngIf="item?.group_logo; else elseGroupName">
                        <img src="{{item.group_logo}}">
                    </div>
                    <ng-template #elseGroupName>
                        <div class="groupC">{{item.name}}</div>
                    </ng-template>
                </div>
            </ng-container>
                <div class="circle" [ngClass]="{'refCircleTop':groupData != null,'circleTop':groupData == undefined }">
                    <div class="circle__inner">
                        <div class="circle__wrapper">
                            <div class="circle__content">
                                <div class="head_0">Hello</div>
                                <div class="head_1">{{firstname}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-10 rflCenter">
                        <div class="rflCode">{{typeOfCode}} Code </div>
                        <input class="form-control txtCenter" type="text" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="referralCode">
                        <button type="button" (click)="groupInfo(referralCode)"
                            class="btn btn-primary applyBtn">Apply</button>
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="">
                    <p class="basicinfo">LET'S GET SOME BASIC INFORMATION</p>
                    <p class="basicinfo">TO DETERMINE A MONTHLY CONTRIBUTION THAT BEST SUITS YOUR NEED...</p>
                    <p *ngIf="disableGuardianFlow" style="margin-top: 4%;">
                        <label class="basicinfo">
                            <input type="checkbox" formControlName="guardianCheckbox"
                                (change)="guardianCheckbox($event.target.checked)">
                            Are you a Guardian looking for
                            Healthcare
                            Sharing for
                            your dependents?
                        </label>
                    </p>
                    <p class="additionalmem" *ngIf="!spouseHide">
                        GUARDIAN INFORMATION:
                    </p>
                </div>
                <div class="row">
                    <div class="col-lg-6 form-group">
                        <div class="head_txt1"> First Name <span class="fieldMan">*</span></div>
                        <input class="form-control" type="text" formControlName="firstname" autocomplete="off"
                            (focusout)="onlastNameleave();" id="firstname" maxlength="25" placeholder="First Name">
                        <div class="errormsg"
                            *ngIf="!newForm.controls.firstname.valid && (newForm.controls.firstname.touched || submitAttempt)">
                            First Name is required
                        </div>
                    </div>
                    <div class="col-lg-6 form-group">
                        <div class="head_txt1"> Last Name <span class="fieldMan">*</span></div>
                        <input class="form-control" type="text" autocomplete="off" maxlength="25"
                            placeholder="Last Name" formControlName="lastname">
                        <div class="errormsg"
                            *ngIf="!newForm.controls.lastname.valid && (newForm.controls.lastname.touched || submitAttempt)">
                            Last Name is required
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 form-group">
                        <div class="head_txt1"> Email <span class="fieldMan">*</span></div>
                        <input class="form-control" autocomplete="off" (keypress)="emailValidation($event.target.value)"
                            type="text" placeholder="Email" formControlName="email">
                        <div class="errormsg" *ngIf="errorMsgShow">
                            {{emailError}}
                        </div>
                        <div class="errormsg"
                            *ngIf="!newForm.controls.email.valid && (newForm.controls.email.touched || submitAttempt)">
                            Email Address is required
                        </div>
                    </div>
                    <div class="col-lg-6 form-group">
                        <div class="head_txt1"> Phone <span class="fieldMan">*</span></div>
                        <input class="form-control" maxlength="12" autocomplete="off" (keyup)="onkeyupPhone($event)"
                            id="phnumber" (keypress)="numberOnly($event)"
                            (keypress)="emailValidation($event.target.value)"
                            (focusout)="onSearchChange1(newForm.value)" (focusout)="onfocusoutphnumber('primary')"
                            class="form-control" appPhonenumberValidation type="text" placeholder="Phone"
                            formControlName="phone">
                        <div class="errormsg" *ngIf="errorMsgShow"> {{phoneerrmsg}}</div>
                        <div class="errormsg" *ngIf="!newForm.controls.phone.valid && (submitAttempt)">
                            Phone Number is required
                        </div>
                        <div class="errormsg" id="phnumbererrmsg">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-xs-8 form-group">
                        <div class="head_txt1"> Date Of Birth <span class="fieldMan">*</span></div>
                        <p-calendar placeholder="mm/dd/yyyy"
                            [style]="{'width': '100%','display': 'inline-flex','height':'40px'}" formControlName="dob"
                            [maxDate]="minimumDate" [disabledDates]="invalidDates1" [monthNavigator]="true"
                            [yearNavigator]="true" [yearRange]="'1956:' + maxDate.getFullYear()" inputId="navigators"
                            (onSelect)="onSearchChange($event,newForm.value)"
                            (onInput)="onSearchChangeT(newForm.value)">
                        </p-calendar>
                        <div class="errormsg"
                            *ngIf="!newForm.controls.dob.valid && (newForm.controls.dob.touched || submitAttempt)">
                            DOB is required
                        </div>
                        <div class="errormsg">{{parentAge}}</div>
                    </div>
                    <div class="col-lg-2 col-xs-4 form-group">
                        <div class="head_txt1">Age</div>
                        <input class="form-control" placeholder="0" [readonly]="true" formControlName="age">
                    </div>
                    <div class="col-lg-6 form-group">
                        <div class="head_txt1">Start Date <span class="fieldMan">*</span>
                            <span style="font-size: 9.4px;">(Contact us, if you would like a different start
                                date.)</span>
                        </div>
                        <select formControlName="startDate" class="form-control">
                            <option value="" disabled>
                                Select Your Effective Start Date</option>
                            <option value={{item.date}} *ngFor="let item of effectiveDate">
                                {{item.date}}
                            </option>
                        </select>
                        <div class="errormsg"
                            *ngIf="!newForm.controls.startDate.valid && (newForm.controls.startDate.touched || submitAttempt)">
                            Start Date is required
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="spouseHide">
                    <div class="col-lg-4">
                        <div class="head_txt1"> Height <span class="fieldMan">*</span></div>
                        <div class="row">
                            <div class="col-lg-6 paddingFt form-group">
                                <div class="row">
                                    <div class="col-lg-8 paddingFt">
                                        <select formControlName="feet" class="form-control">
                                            <option value="" disabled>Feet</option>
                                            <option value={{item.feet}} *ngFor="let item of feetArray| slice:1">
                                                {{item.feet}} </option>
                                        </select>
                                    </div>
                                    <div class="col-lg-4 marginFt"> Ft</div>
                                </div>
                            </div>
                            <div class="col-lg-6 paddingFt form-group">
                                <div class="row">
                                    <div class="col-lg-8 paddingFt">
                                        <select formControlName="inch" class="form-control">
                                            <option value="" disabled>Inches</option>
                                            <option value={{item.inchValue}}
                                                *ngFor="let item of inchArray; let i=index">
                                                {{item.inch}} </option>
                                        </select>
                                    </div>
                                    <div class="col-lg-4 marginFt"> In</div>
                                </div>
                            </div>
                        </div>
                        <div class="errormsg"
                            *ngIf="!newForm.controls.feet.valid && (newForm.controls.feet.touched || submitAttempt)">
                            Height is required
                        </div>
                        <!--  <div class="input-group">
                             <input class="form-control" type="text" name="surname" placeholder="Feet" autocomplete="off"
                                (keypress)="numberOnly($event)" (keyup)="onselectFeet('applicant')" id="ft"
                                formControlName="feet" maxlength="2"><span class="input-group-addon">Ft</span> 
                             <input style="margin-left: 8px;" (focusout)="onselectIn('applicant')" id="in" maxlength="2"
                                formControlName="inch" autocomplete="off" class="form-control" type="text"
                                name="surname" (keypress)="numberOnly($event)" placeholder="Inches"><span
                                class="input-group-addon">In</span></div> 
                              <div class="errormsg" *ngIf="errtxt!=''">
                            {{errtxt}}
                        </div> -->
                    </div>
                    <div class="col-lg-4 form-group">
                        <div class="head_txt1">Weight <span class="fieldMan">*</span></div>
                        <div class="input-group">
                            <input class="form-control" autocomplete="off" maxlength="3" type="text"
                                placeholder="Weight" (keypress)="numberOnly($event)"
                                (input)="onSearch($event.target.value,'primary')" formControlName="weight"><span
                                class="input-group-addon">lbs</span>
                        </div>
                        <div class="errormsg">
                            {{er}}
                        </div>
                        <div class="errormsg"
                            *ngIf="!newForm.controls.weight.valid && (newForm.controls.weight.touched || submitAttempt)">
                            Weight is required
                        </div>
                    </div>
                    <div class="col-lg-4 form-group">
                        <div class="head_txt1">Gender At Birth <span class="fieldMan">*</span></div>
                        <div class="genderSpace">
                            <label>
                                <input type="radio" value="Male" formControlName="gender">
                                <span> &nbsp;Male</span> &nbsp;&nbsp;&nbsp;
                            </label>
                            <label>
                                <input type="radio" value="Female" formControlName="gender">
                                <span> &nbsp;Female</span>
                            </label>
                        </div>
                        <div class="errormsg"
                            *ngIf="!newForm.controls.gender.valid && (newForm.controls.gender.touched || submitAttempt)">
                            Gender is required
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-sm-12 details" *ngIf="spouseHide">
                        <p class="additionalmem">
                            ADDITIONAL MEMBERS:
                        </p>
                        <p class="additionalmem additionalfamily">
                            In addition to you, how many other family members will be on your plan
                        </p>
                    </div>
                    <div class="col-sm-12 details" *ngIf="!spouseHide">
                        <p class="additionalmem">
                            DEPENDENT INFORMATION :
                        </p>
                    </div>
                    <div class="col-lg-4 col-xs-4">
                        <div *ngIf="spouseHide">
                            <button type="button" class="btn btn-sm btn-primary" style="margin-bottom: 10%;"
                                (click)="addSpouse()" [disabled]="disableSpouseArray">
                                <i class="fa fa-user" aria-hidden="true"></i> Add Spouse</button>
                        </div>
                    </div>
                    <div class="col-lg-4  col-xs-4">
                        <div *ngIf="spouseHide">
                            <button type="button" class="btn btn-sm btn-primary" (click)="addChildren()"
                                [disabled]="disableChildArray">
                                <i class="fa fa-users" aria-hidden="true"></i> Add Children </button>
                        </div>
                    </div>
                    <div class="col-lg-4  col-xs-4">
                        <button type="button" *ngIf="showPlanButton" (click)="formSubmit(newForm.value)"
                            class="btn btn-sm btn-primary " id="getmyplan1">Get
                            Me My Price</button>
                    </div>
                </div>
                <div class="row">
                    <div formArrayName="spouse_Array" class="col-lg-12">
                        <div *ngFor="let spouseForm of spouse_Array().controls; let empIndex1=index">
                            <div [formGroupName]="empIndex1">
                                <div class="childForm"> Spouse details</div>
                                <div class="row">
                                    <div class="col-lg-4 form-group">
                                        <div class="head_txt1"> First Name <span class="fieldMan">*</span></div>
                                        <input class="form-control" maxlength="25" type="text" autocomplete="off"
                                            placeholder="First Name" formControlName="spouseFirstName">
                                        <div class="errormsg"
                                            *ngIf="!spouseForm.controls.spouseFirstName.valid && (spouseForm.controls.spouseFirstName.touched || submitAttempt)">
                                            First name is required
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-xs-8 form-group">
                                        <div class="head_txt1">DOB <span class="fieldMan">*</span>
                                            (MM/DD/YYYY)
                                        </div>
                                        <p-calendar placeholder="mm/dd/yyyy"
                                            [style]="{'width': '100%','display': 'inline-flex','height':'40px'}"
                                            formControlName="spouseDob" [maxDate]="minimumDate"
                                            [disabledDates]="invalidDates1" [monthNavigator]="true"
                                            [yearNavigator]="true" [yearRange]="'1956:'+ maxDate.getFullYear()"
                                            inputId="navigators" inputId="{{'spouseDob' + empIndex1}}"
                                            (onSelect)="spouseAgeCal($event,empIndex1,'spouseDob' + empIndex1)"
                                            (onInput)="spouseAgeCalT(newForm.value)">
                                        </p-calendar>
                                        <div class="errormsg"
                                            *ngIf="!spouseForm.controls.spouseDob.valid && (spouseForm.controls.spouseDob.touched || submitAttempt)">
                                            DOB is required
                                        </div>
                                        <div class="errormsg">{{spouseAgeMsg}}</div>
                                    </div>
                                    <div class="col-lg-1 col-xs-4 form-group" style="padding: 0px;">
                                        <div class="head_txt1">Age</div>
                                        <input class="form-control" placeholder="0" [readonly]="true"
                                            formControlName="spouseAge">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4 col-xs-12">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="head_txt1"> Height <span class="fieldMan">*</span>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-6 paddingFt">
                                                        <div class="row">
                                                            <div class="col-lg-8 paddingFt form-group">
                                                                <select formControlName="spouseFeet"
                                                                    class="form-control">
                                                                    <option value="" disabled>Feet</option>
                                                                    <option value={{item.feet}}
                                                                        *ngFor="let item of feetArray | slice:1">
                                                                        {{item.feet}} </option>
                                                                </select>
                                                            </div>
                                                            <div class="col-lg-4 marginFt"> Ft</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 paddingFt">
                                                        <div class="row">
                                                            <div class="col-lg-8 paddingFt form-group">
                                                                <select formControlName="spouseInch"
                                                                    class="form-control">
                                                                    <option value="" disabled>Inches</option>
                                                                    <option value={{item.inchValue}}
                                                                        *ngFor="let item of inchArray; let i=index">
                                                                        {{item.inch}} </option>
                                                                </select>
                                                            </div>
                                                            <div class="col-lg-4 marginFt"> In</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="input-group">
                                                    <input class="form-control" autocomplete="off" maxlength="2"
                                                        type="text" name="surname" placeholder="Feet" id="spouceft"
                                                        maxlength="2" (keyup)="onselectFeet('spouce')"
                                                        (keypress)="numberOnly($event)"
                                                        formControlName="spouseFeet"><span
                                                        class="input-group-addon">Ft</span>
                                                    <input style="margin-left: 8px;" maxlength="2" class="form-control"
                                                        type="text" name="surname" id="spoucein" maxlength="2"
                                                        (focusout)="onselectIn('spouce')" autocomplete="off"
                                                        (keypress)="numberOnly($event)" formControlName="spouseInch"
                                                        placeholder="Inches"><span class="input-group-addon">In</span>
                                                </div> -->
                                                <div class="errormsg"
                                                    *ngIf="!spouseForm.controls.spouseFeet.valid && (spouseForm.controls.spouseFeet.touched || submitAttempt)">
                                                    Height is required
                                                </div>
                                                <div class="errormsg" *ngIf="spouceerrtxt!=''">
                                                    {{spouceerrtxt}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-xs-12 form-group">
                                        <div class="head_txt1">Weight <span class="fieldMan">*</span>
                                        </div>
                                        <div class="input-group">
                                            <input class="form-control" type="text" maxlength="3" placeholder="Weight"
                                                (keypress)="numberOnly($event)" autocomplete="off"
                                                (input)="onSearch($event.target.value, 'spouse')"
                                                formControlName="spouseWeight"><span
                                                class="input-group-addon">lbs</span>
                                        </div>
                                        <div class="errormsg"
                                            *ngIf="!spouseForm.controls.spouseWeight.valid && (spouseForm.controls.spouseWeight.touched || submitAttempt)">
                                            Weight is required
                                        </div>
                                        <div class="errormsg">
                                            {{spouseWtError}}
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-xs-6 form-group">
                                        <div class="head_txt1">Gender At Birth <span class="fieldMan">*</span></div>
                                        <div class="genderSpace">
                                            <label>
                                                <input type="radio" value="Male" formControlName="spouseGender">
                                                <span> &nbsp;Male</span> &nbsp;&nbsp;&nbsp;
                                            </label>
                                            <label>
                                                <input type="radio" value="Female" formControlName="spouseGender">
                                                <span> &nbsp;Female</span>
                                            </label>
                                        </div>
                                        <div class="errormsg"
                                            *ngIf="!spouseForm.controls.spouseGender.valid && (spouseForm.controls.spouseGender.touched || submitAttempt)">
                                            Gender is required
                                        </div>
                                    </div>
                                    <div class="col-lg-1 enrollRemove">
                                        <input class="checkbox_css " type="checkbox" formControlName="spouseCheckBox"
                                            (change)="spouseCheckbox($event.target.checked,spouseForm.value, newForm.value)">
                                        <button type="button" class="btn btn-sm1 btn-danger btn-remove"
                                            (click)="removeEmployee1(empIndex1,spouseForm.value,newForm.value)">Remove</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-------------------child and dependent------------------->
                <div style="height: 25px;" *ngIf="spouseHide"></div>
                <div class="row" *ngIf="showChild">
                    <div formArrayName="child_Array" class="col-lg-12">
                        <div *ngFor="let childForm of child_Array().controls; let empIndex=index"
                            style="    margin-bottom: 4%;">
                            <div [formGroupName]="empIndex">
                                <div class="childForm" *ngIf="!spouseHide"> Dependent - {{empIndex +1}} </div>
                                <div class="childForm" *ngIf="spouseHide"> Child - {{empIndex +1}} </div>
                                <div class="row">
                                    <div class="col-lg-4 form-group">
                                        <div class="head_txt1"> First Name <span class="fieldMan">*</span></div>
                                        <input class="form-control" maxlength="25" autocomplete="off" type="text"
                                            placeholder="First Name" formControlName="childFirstName">
                                        <div class="errormsg"
                                            *ngIf="!childForm.controls.childFirstName.valid && (childForm.controls.childFirstName.touched || submitAttempt)">
                                            First Name is required
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-xs-8 form-group">
                                        <div class="head_txt1">DOB <span class="fieldMan">*</span> (MM/DD/YYYY)
                                        </div>
                                        <p-calendar placeholder="mm/dd/yyyy"
                                            [style]="{'width': '100%','display': 'inline-flex','height':'40px'}"
                                            formControlName="childDob" [maxDate]="minimumDate"
                                            [disabledDates]="invalidDates1" [monthNavigator]="true"
                                            [yearNavigator]="true" [yearRange]="'1994:'+ maxDate.getFullYear()"
                                            inputId="navigators" inputId="{{ 'dob' + empIndex}}"
                                            (onSelect)="childAgeCal($event,empIndex,'dob' + empIndex)"
                                            (onInput)="childAgeCalT($event,empIndex,'dob' + empIndex, newForm.value)">
                                        </p-calendar>
                                        <div class="errormsg"
                                            *ngIf="!childForm.controls.childDob.valid && (childForm.controls.childDob.touched || submitAttempt)">
                                            DOB is required
                                        </div>
                                        <div class="errormsg" id="{{'childDOBerror' + empIndex}}">
                                        </div>
                                    </div>
                                    <div class="col-lg-1 col-xs-4 form-group" style="padding: 0px;">
                                        <div class="head_txt1">Age</div>
                                        <input class="form-control" placeholder="0" [readonly]="true"
                                            formControlName="childAge">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4 col-xs-12">
                                        <div class="row">
                                            <div class="col-lg-12 form-group">
                                                <div class="head_txt1"> Height <span class="fieldMan">*</span></div>
                                                <div class="row">
                                                    <div class="col-lg-6 paddingFt">
                                                        <div class="row">
                                                            <div class="col-lg-8 paddingFt">
                                                                <select formControlName="childFeet"
                                                                    class="form-control">
                                                                    <option value="" disabled>Feet</option>
                                                                    <option value={{item.feet}}
                                                                        *ngFor="let item of feetArray; let i=index">
                                                                        {{item.feet}} </option>
                                                                </select>
                                                            </div>
                                                            <div class="col-lg-4 marginFt"> Ft</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 paddingFt form-group">
                                                        <div class="row">
                                                            <div class="col-lg-8 paddingFt">
                                                                <select formControlName="childInch"
                                                                    class="form-control">
                                                                    <option value="" disabled>Inches</option>
                                                                    <option value={{item.inchValue}}
                                                                        *ngFor="let item of inchArray ">
                                                                        {{item.inch}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <div class="col-lg-4 marginFt"> In</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="input-group">
                                                    <input class="form-control" type="text" name="surname"
                                                        placeholder="Feet" (keypress)="numberOnly($event)"
                                                        (keyup)="onselectChildFeet('child',empIndex,$event)"
                                                        id="{{ 'field' + empIndex}}" maxlength="2" autocomplete="off"
                                                        formControlName="childFeet"><span
                                                        class="input-group-addon">Ft</span>
                                                    <input style="margin-left: 8px;" class="form-control" type="text"
                                                        name="surname"
                                                        (focusout)="onselectChildInch('child',empIndex,$event)"
                                                        id="{{ 'fields' + empIndex }}" maxlength="2" autocomplete="off"
                                                        (keypress)="numberOnly($event)" formControlName="childInch"
                                                        placeholder="Inches"><span class="input-group-addon">In</span>
                                                </div> -->
                                                <div class="errormsg"
                                                    *ngIf="!childForm.controls.childFeet.valid && (childForm.controls.childFeet.touched || submitAttempt)">
                                                    Height is required
                                                </div>
                                                <!-- <div class="errormsg childerrorhtml" id="{{ 'error' + empIndex}}">
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-xs-12 form-group">
                                        <div class="head_txt1">Weight <span class="fieldMan">*</span></div>
                                        <div class="input-group">
                                            <input class="form-control" type="text" placeholder="Weight"
                                                id="{{ 'weightfield' + empIndex }}" autocomplete="off"
                                                (keyup)="onselectChildWeight(empIndex,$event)" maxlength="3"
                                                (keypress)="numberOnly($event)" formControlName="childWeight"><span
                                                class="input-group-addon">lbs</span>
                                        </div>
                                        <div class="errormsg"
                                            *ngIf="!childForm.controls.childWeight.valid && (childForm.controls.childWeight.touched || submitAttempt)">
                                            Weight is required
                                        </div>
                                        <div class="errormsg childerrorhtml" id="{{ 'childerror' + empIndex}}">
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-xs-6 form-group">
                                        <div class="head_txt1">Gender At Birth <span class="fieldMan">*</span></div>
                                        <div class="genderSpace">
                                            <label>
                                                <input type="radio" value="Male" formControlName="childGender">
                                                <span> &nbsp;Male</span> &nbsp;&nbsp;&nbsp;
                                            </label>
                                            <label>
                                                <input type="radio" value="Female" formControlName="childGender">
                                                <span> &nbsp;Female</span>
                                            </label>
                                        </div>
                                        <div class="errormsg"
                                            *ngIf="!childForm.controls.childGender.valid && (childForm.controls.childGender.touched || submitAttempt)">
                                            Gender is required
                                        </div>
                                    </div>
                                    <div class="col-lg-1 col-xs-6 enrollRemove form-group">
                                        <span *ngIf="!spouseHide && empIndex > 0 ">
                                            <button type="button" class="btn btn-sm1 btn-danger btn-remove"
                                                (click)="removeEmployee(empIndex,childForm.value,newForm.value);">Remove
                                            </button>
                                            <input type="checkbox" formControlName="childCheckBox" class="checkbox_css"
                                                (change)="childCheckbox($event.target.checked,childForm.value, newForm.value)">
                                        </span>
                                        <span *ngIf="spouseHide">
                                            <input type="checkbox" formControlName="childCheckBox" class="checkbox_css"
                                                (change)="childCheckbox($event.target.checked,childForm.value, newForm.value)">
                                            <button type="button" class="btn btn-sm1 btn-danger btn-remove"
                                                (click)="removeEmployee(empIndex,childForm.value,newForm.value);">Remove</button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12" style="text-align: right;margin-top: 16px;">
                        <span *ngIf="!spouseHide">
                            <button type="button" class="btn btn-sm1 btn-success" *ngIf="showChild"
                                (click)="addEmployee('child')">
                                <i class="fa fa-user" aria-hidden="true"></i> Add Dependent</button>&nbsp;
                        </span>
                        <span *ngIf="spouseHide">
                            <button type="button" class="btn btn-sm1 btn-success" *ngIf="showChild"
                                (click)="addEmployee('child')">
                                <i class="fa fa-user" aria-hidden="true"></i> Add Child</button>&nbsp;
                        </span>
                        <button type="button" *ngIf="!showPlanButton" (click)="formSubmit(newForm.value)"
                            class="btn  btn-primary " id="getmyplan">Get Me My Price</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="bottomMargin" *ngIf="plandata">
        <div class="col-lg-12" style="padding: 0;">
            <div class="basic" *ngIf="plandata.length !=0">
                Based on the information provided above your monthly contribution choices are as below :
            </div>
            <div *ngIf="plandata.length !=0" class="memberNames">
                (<span>{{firstname | titlecase}}</span>
                <span *ngFor="let item of spouseInfo">, {{item.firstname | titlecase}}</span>
                <span *ngFor="let item of childInfo">, {{item.firstname | titlecase}}</span>)
            </div>
        </div>
        <div class="container" *ngIf="displayPlans=='ar'">
            <div class="row">
                <div class="col-md-12">
                    <div class="row plans-container" *ngFor="let p of plandata.slice().reverse() let i = index;">
                        <div class="plans-list col-lg-10">
                            <div class=" " *ngFor="let item of p.plan_info; let x = index;">
                                <div class="plan-card plan-basic" *ngIf="item.Name=='Basic'">
                                    <h6>
                                        <img src="./assets/images/Basic.svg" alt="">
                                        <span> {{item.Name}}</span>
                                    </h6>
                                    <div>{{item.family_info | titlecase}}</div>
                                    <h5>
                                        {{item.price | currency:'USD'}}<span>/Month</span>
                                    </h5>
                                </div>
                                <div class="plan-card plan-choice" *ngIf="item.Name=='Choice'">
                                    <h6>
                                        <img src="./assets/images/Choice.svg" alt="">
                                        <span> {{item.Name}}</span>
                                    </h6>
                                    <div>{{item.family_info | titlecase}}</div>
                                    <h5>
                                        {{item.price | currency:'USD'}}<span>/Month</span>
                                    </h5>
                                </div>
                                <div class="plan-card plan-essential" *ngIf="item.Name=='Essential'">
                                    <h6>
                                        <img src="./assets/images/Essential.svg" alt="">
                                        <span> {{item.Name}}</span>
                                    </h6>
                                    <div>{{item.family_info | titlecase}}</div>
                                    <h5>
                                        {{item.price | currency:'USD'}}<span>/Month</span>
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div class="plan-total-price col-lg-2">
                            <h5>
                                {{p.total | currency:'USD'}}<span>/Month</span>
                            </h5>
                            <button class="btn btn-primary" (click)="addToCart(p)">Buy Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="displayPlans!='ar'">
            <div class="plans-list col-lg-4" *ngFor="let item of plandata.slice().reverse() let i = index;">
                <div class="plan-card plan-basic" *ngIf="item.Name=='Basic'">
                    <h6>
                        <img src="./assets/images/Basic.svg" alt="">
                        <span> {{item.Name}}</span>
                    </h6>
                    <div>{{item.family_info | titlecase}}</div>
                    <h5>
                        {{item.price | currency:'USD'}}<span>/Month</span>
                    </h5>
                    <button type="button" (click)="addToCart(item)" class="btn btn-primary programbtn">
                        Choose {{item.Name}} Program
                    </button>
                </div>
                <div class="plan-card plan-choice" *ngIf="item.Name=='Choice'">
                    <h6>
                        <img src="./assets/images/Choice.svg" alt="">
                        <span> {{item.Name}}</span>
                    </h6>
                    <div>{{item.family_info | titlecase}}</div>
                    <h5>
                        {{item.price | currency:'USD'}}<span>/Month</span>
                    </h5>
                    <button type="button" (click)="addToCart(item)" class="btn btn-primary programbtn">
                        Choose {{item.Name}} Program
                    </button>
                </div>
                <div class="plan-card plan-essential" *ngIf="item.Name=='Essential'">
                    <h6>
                        <img src="./assets/images/Essential.svg" alt="">
                        <span> {{item.Name}}</span>
                    </h6>
                    <div>{{item.family_info | titlecase}}</div>
                    <h5>
                        {{item.price | currency:'USD'}}<span>/Month</span>
                    </h5>
                    <button type="button" (click)="addToCart(item)" class="btn btn-primary programbtn">
                        Choose {{item.Name}} Program
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>