<div class="loader" *ngIf="loader">
  <div id="loading"></div>
</div>
<section class="getintouch">
  <div class="container space-2 space-3--lg">
    <div class="row">
      <div class="col-lg-6 mb-7 mb-lg-0">
        <!-- Contacts Info -->
        <h5 class=" mb-4" ><span>WHERE WE ARE</span></h5>
        <div class="row">
          <div class="col-sm-12 mb-4 mb-sm-0">
            <address class="mb-0">
              <ul class="list-unstyled font-size-14 text-secondary mb-0">
                <li class="py-1">555 Sun Valley Drive, Suite K-4
                  <br>
                  Roswell, GA 30076
                </li>
                <li class="py-2"></li>
                <li class="py-1">Phone No: <a href="#">800-956-5081</a></li>
                <li class="py-1">Email: <a href="#">sales@fellowshiphealthshare.org</a></li>
                <!-- <li class="py-1">Phone: <a href="#">
                  (678) 691-7120</a></li> -->
              </ul>
            </address>
          </div>
          <div class="col-sm-12 space-3--lg mb-4 mb-sm-0">
            <h5 class="mb-4 mt-5" style="font-weight:500;">Follow Us:</h5>
            <div>
              <div>
                <!-- Social Networks -->
                <ul class="list-inline mb-0">
                  <li class="list-inline-item">
                    <a class="u-icon u-icon--secondary u-icon--sm" target="_blank"
                      href="https://www.facebook.com/fellowshiphealthshare">
                      <i class="bi bi-facebook"></i>
                    </a>
                  </li>
                  <!-- <li class="list-inline-item">
                  <a class="u-icon u-icon--secondary u-icon--sm" href="#">
                    <i class="fab fa-google u-icon__inner"></i>
                  </a>
                </li> -->
                  <li class="list-inline-item">
                    <a class="u-icon u-icon--secondary u-icon--sm" target="_blank"
                      href="https://twitter.com/FHealthshare">
                      <i class="bi bi-twitter"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a class="u-icon u-icon--secondary u-icon--sm" target="_blank"
                      href="https://www.instagram.com/fellowshiphealthshare01/">
                      <i class="bi bi-instagram"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a class="u-icon u-icon--secondary u-icon--sm" target="_blank"
                      href="https://www.linkedin.com/company/fellowship-healthshare">
                      <i class="bi bi-linkedin"></i>
                    </a>
                  </li>
                </ul>
                <!-- End Social Networks -->
              </div>
            </div>
          </div>
          <!-- <div class="col-sm-6">
          <h4 class="h6">Business Hours</h4>
          <span class="d-block font-size-14 text-secondary mb-3">Mon - Sat: 9.30AM - 06.00PM</span>
        </div> -->
        </div>
        <!-- End Contacts Info -->
      </div>
      <div class="col-lg-6">
        <h5 class=" mb-4" style="font-weight:600;text-transform: none;">CONTACT US-</h5>
        <form [formGroup]="newForm">
          <div class="row">
            <div class="col-lg-6">
              <div class="head_txt1"> First Name <span class="fieldMan">*</span></div>
              <input class="form-control" type="text" formControlName="firstname" placeholder="">
              <div class="errormsg"
                *ngIf="!newForm.controls.firstname.valid && (newForm.controls.firstname.touched || submitAttempt)">
                First Name is required
              </div>
            </div>
            <div class="col-lg-6">
              <div class="head_txt1"> Last Name <span class="fieldMan">*</span></div>
              <input class="form-control" type="text" placeholder="" formControlName="lastname">
              <div class="errormsg"
                *ngIf="!newForm.controls.lastname.valid && (newForm.controls.lastname.touched || submitAttempt)">
                Last Name is required
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="head_txt1"> Email <span class="fieldMan">*</span></div>
              <input class="form-control" type="text" placeholder="" formControlName="email">
              <div class="errormsg"
                *ngIf="!newForm.controls.email.valid && (newForm.controls.email.touched || submitAttempt)">
                Please enter valid Email
              </div>
            </div>
            <div class="col-lg-12">
              <div class="head_txt1"> Phone <span class="fieldMan">*</span></div>
              <input class="form-control" maxlength="12" (keyup)="onkeyupPhone($event)" id="phnumber"
                (keypress)="numberOnly($event)" (focusout)="onfocusoutphnumber('primary')" class="form-control"
                appPhonenumberValidation type="text" placeholder="" formControlName="phone">
              <div class="errormsg"
                *ngIf="!newForm.controls.phone.valid && (newForm.controls.phone.touched || submitAttempt)">
                Phone is required
              </div>
              <div class="errormsg" id="phnumbererrmsg">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="head_txt1"> Message <span class="fieldMan">*</span></div>
              <textarea rows="5" class="form-control" formControlName="message"></textarea>
              <div class="errormsg"
                *ngIf="!newForm.controls.message.valid && (newForm.controls.message.touched || submitAttempt)">
                Message is required
              </div>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-lg-12" style="text-align: right;">
              <button type="button" (click)="submit(newForm.value)" class="btn btn-primary">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
