import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillingDetailsComponent } from './billing-details/billing-details.component';
import { PaymentSectionComponent } from './payment-section/payment-section.component';
import { RadioButtonModule } from '../featuresModule/radio-button/radio-button.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaymentComponent } from './payment/payment.component';
import { PaymentInfoContainerComponent } from './payment-info.container.component';
import { BankPaymentComponent } from './payment-section/bank-payment/bank-payment.component';
import { CardPaymentComponent } from './payment-section/card-payment/card-payment.component';
import { CustomizeInputDirective } from '../featuresModule/directives/input.directive';
import { BillingAddressComponent } from './payment-section/billing-address/billing-address.component';



@NgModule({
  declarations: [
    BillingDetailsComponent, 
    PaymentComponent, 
    PaymentSectionComponent,
    PaymentInfoContainerComponent,
    BankPaymentComponent,
    CardPaymentComponent,
    CustomizeInputDirective,
    BillingAddressComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RadioButtonModule,
  ],
  exports:[
    BillingDetailsComponent, 
    PaymentComponent, 
    PaymentSectionComponent,
    PaymentInfoContainerComponent,
  ]
})
export class PaymentInfoModule { }
