import { Component, OnInit } from '@angular/core';
import { TestService } from '../services/behaviourService';
import { FormBuilder, FormArray, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { SharingService } from '../services/sharing.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-form-document',
  templateUrl: './form-document.component.html',
  styleUrls: ['./form-document.component.scss']
})
export class FormDocumentComponent implements OnInit {

  constructor(private objectService: TestService ,private formBuilder: FormBuilder,
    private router: Router,
    private service: SharingService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.objectService.pushheaderCondition("showheader");
  }

}
