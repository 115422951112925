import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../app/home/home.component'
import { DemographicsDependentsComponent } from './demographics-dependents/demographics-dependents.component';
import { HealthBasedQuestionsComponent } from './health-based-questions/health-based-questions.component';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';
import { SummaryComponent } from './summary/summary.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { PhonenumberValidationDirective } from './services/phonenumber-validation.directive';
import { SocialSecurityDirective } from './services/social-security.directive';
import { AlphabetOnlyDirective } from './services/alphabet-only.directive'
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { WhatWeDoComponent } from './what-we-do/what-we-do.component';
import { WhoWeAreComponent } from './who-we-are/who-we-are.component';
import { ResourcesComponent } from './resources/resources.component';
import { GetInTouchComponent } from './get-in-touch/get-in-touch.component';
import { TermsofuseComponent } from './termsofuse/termsofuse.component';
import { StatespecificnoticesComponent } from './statespecificnotices/statespecificnotices.component';

import { DemographicComponent } from './A2C/demographic/demographic.component';
import { HealthbasedquestionsComponent } from './A2C/healthbasedquestions/healthbasedquestions.component';
import { PaymentComponent } from './A2C/payment/payment.component'
import { SummaryDetailsComponent } from './A2C/summary-details/summary-details.component'
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { LearnmoreComponent } from './learnmore/learnmore.component';
import { EmployerGroupComponent } from './employer-group/employer-group.component';
import { RegistrationFormComponent } from './registration-form/registration-form.component';
import { CustomlandingpageComponent } from './customlandingpage/customlandingpage.component';
import { GlossaryComponent } from './Glossary/glossary.component';
import { AdvOpinionEligibilityComponent } from './adv-opinion-eligibility/adv-opinion-eligibility.component';
import { ResetmemberpasswordComponent } from './resetmemberpassword/resetmemberpassword.component';
import { FaqComponent } from './faq/faq.component';
import { BlogComponent } from './blog/blog.component';
import { BlogdetailComponent } from './blogdetail/blogdetail.component';
import { MembershipComponent } from './membership/membership.component';
import { MembershipGuidelineComponent } from './membership-guideline/membership-guideline.component';
import { FormDocumentComponent } from './form-document/form-document.component';
import { ProgramBrochureComponent } from './program-brochure/program-brochure.component';
import { PaymentInfoContainerComponent } from './payment-info/payment-info.container.component';
import { ApplicationcartComponent } from './applicationcart/applicationcart.component';
import { AddOnProductsComponent } from './add-on-products/add-on-products.component';
import { AppointmenttestComponent } from './appointmenttest/appointmenttest.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  //================Enrolmant components================//
  { path: 'dynamicplan', component: SignUpComponent },
  { path: 'demographic', component: DemographicsDependentsComponent },
  { path: 'add_on_products', component: AddOnProductsComponent },
  { path: 'health-basedquestions', component: HealthBasedQuestionsComponent },
  { path: 'paymentdetails', component: PaymentDetailsComponent },
  { path: 'summary', component: SummaryComponent },
  { path: 'thankyou', component: ThankyouComponent },
  {path:'applicationcart', component:ApplicationcartComponent},

  //=======For group related routing ===================//
  { path: 'dynamicplan/:id', component: SignUpComponent },

  //============User service components    =================//
  { path: 'get-in-touch', component: GetInTouchComponent },
  { path: 'employer-group', component: EmployerGroupComponent },
  { path: 'resetmemberpassword/:id', component: ResetmemberpasswordComponent },

  //===========Non Enrolment components s/ui =-============//
  { path: 'learnmore', component: LearnmoreComponent },
  { path: 'registration-form', component: RegistrationFormComponent },
  { path: 'OperahW2021', component: CustomlandingpageComponent },
  { path: 'Glossary', component: GlossaryComponent },
  { path: 'adv-opinion-eligibility', component: AdvOpinionEligibilityComponent },
  { path: 'how-it-works', component: HowItWorksComponent },
  { path: 'membership', component: MembershipComponent },
  { path: 'what-we-do', component: WhatWeDoComponent },
  { path: 'who-we-are', component: WhoWeAreComponent },
  { path: 'resources', component: ResourcesComponent },
  { path: 'TermOfUse', component: TermsofuseComponent },
  { path: 'stateSpecificNotices', component: StatespecificnoticesComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blogdetail', component: BlogdetailComponent },
  { path: 'membership-guideline', component: MembershipGuidelineComponent },
  { path: 'form-document', component: FormDocumentComponent },
  { path: 'program-brochure', component: ProgramBrochureComponent },
  {path:'appointmenttest',component:AppointmenttestComponent},
  
  //================A2C Enrolment components===================//
  //**********************A2C***************************//
  { path: 'A2C/demographic/:id', component: DemographicComponent },
  { path: 'A2C/health-based-questions', component: HealthbasedquestionsComponent },
  { path: 'A2C/payment', component: PaymentComponent },
  { path: 'A2C/summary', component: SummaryDetailsComponent },

  //==========wild card routing======================//
  { path: '**', component: PagenotfoundComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }),],
  exports: [RouterModule, PhonenumberValidationDirective, SocialSecurityDirective, AlphabetOnlyDirective],
  declarations: [PhonenumberValidationDirective, SocialSecurityDirective, AlphabetOnlyDirective],
})
export class AppRoutingModule { }
