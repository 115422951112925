import { Action } from "@ngrx/store";
import { BillingActionTypes } from "../state/state";

export class GetStatesAction implements Action {
    readonly type = BillingActionTypes.GET_STATES;
}

export class GetStatesSuccessAction implements Action {
    readonly type = BillingActionTypes.GET_STATES_SUCCESS;
    constructor(public payload?: any) { }
}

export class GetStatesFailureAction implements Action {
    readonly type = BillingActionTypes.GET_STATES_FAILURE;
    constructor(public payload?: any) { }
}

export type StatesAction =
    GetStatesAction |
    GetStatesSuccessAction |
    GetStatesFailureAction;