<div class="container m-top" style="padding: 0 0 30px 0;">
    <div class="applicationProgress">
        <div class="appProgStep " style="z-index:5; padding-left: 30px;" id="app1">Demographics &amp; Dependents
        </div>
        <div class="appProgStep " style="z-index:3;" id="app3">Health-Based Questions</div>
        <div class="appProgStep active" style="z-index:2;" id="app4">Payment Details</div>
        <div class="appProgStep appProgStep-last" style="z-index:1;" id="app5">
            <span>Summary</span>
        </div>
    </div>
    <div class="container m-top">
        <div class="row demomargin">
            <div class="col-lg-4 col-md-12" style="padding: 0;">
                <div class="card">
                    <div class="row">
                        <div class="col-lg-6 col-xs-6">
                            <div class="planehead">Zip Code</div>
                            <div class="planeh">Effective Start Date</div>
                        </div>
                        <div class="col-lg-6 col-xs-6" style="text-align: right;">
                            <div class="planehead"> {{entryZipcode}}</div>
                            <div class="planeh"> {{entryStartdate | date:'MM/dd/yyyy'}}</div>
                        </div>
                    </div>
                </div>
                <div class="card  ">
                    <div class="row">
                        <div class="col-lg-6">
                            <div>Application Cart</div>
                        </div>
                    </div>
                </div>
                <app-applicationcart></app-applicationcart>
                <div class="card" *ngFor="let item of agentInfo">
                    <div class="row">
                        <div class="col-lg-12 cartHead">
                            Agent Information
                        </div>
                        <div class="col-lg-12"> {{item.firstname}} {{item.lastname}}</div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-xs-4 col-md-4">
                            <div class="planehead">Mobile &nbsp; : </div>
                        </div>
                        <div class="col-lg-8 col-xs-8 col-md-4">
                            <div class="planehead">{{item.mobile}}</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-xs-4 col-md-8">
                            <div class="planeh">E-mail &nbsp; :</div>
                        </div>
                        <div class="col-lg-8 col-xs-8 col-md-8">
                            <div class="planeh">{{item.email}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-12 payment" style="padding-right: 0;">
                <div class="card">
                    <div class="plan-card-header apclnt-card-header " style="margin-left: 0.05rem;">
                        <h6 class="mb-0 py-2 f-600"> Payment Details</h6>
                    </div>
                    <!-- <div class="cardsection" style="margin: 2% 0%;">
                        <label>
                            <input type="radio" name="credit" value="creditinfo" (click)="setradio('creditinfo')"
                                [checked]='true' [(ngModel)]="selectedLink"><span>
                                Credit/Debit Card</span>
                        </label>
                        <label style="    margin-left: 7%;">
                            <input type="radio" name="bank" value="bankinfo" (click)="setradio('bankinfo')"
                                [(ngModel)]="selectedLink"><span> Bank ACH</span>
                        </label>
                    </div> -->
                    <div *ngIf="isSelected('bankinfo')">
                        <div class="cardsection" style="margin: 2% 0%;">
                            <label>
                                <input type="radio" name="checking" (click)="onHandleChange('Checking')"
                                    value="Checking" [checked]='true' [(ngModel)]="paymentType">
                                <span> Checking</span>
                            </label>
                            <label style="margin-left: 7%;">
                                <input type="radio" name="savings" (click)="onHandleChange('Savings')" value="Savings"
                                    [(ngModel)]="paymentType">
                                <span> Savings</span>
                            </label>
                            <label style="margin-left: 7%;">
                                <input type="radio" name="credit" value="creditinfo" (click)="setradio('creditinfo')"
                                    [checked]='true' [(ngModel)]="selectedLink"><span>
                                    Credit/Debit Card</span>
                            </label>
                        </div>
                        <form [formGroup]="Banking_Form">
                            <div class="row">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="routingNumber"
                                            placeholder="Routing Number" #routingNumber
                                            (keyup)=onKeyUpEnterRoutingNumber(routingNumber.value)
                                            (keypress)="numberOnly($event)">
                                        <div class="errormsg"
                                            *ngIf="!Banking_Form.controls.routingNumber.valid && (Banking_Form.controls.routingNumber.touched || submitBankingAttempt)">
                                            Routing Number is required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="reenterRouting"
                                            (paste)="onPaste($event)" (copy)="onCopy($event)"
                                            #selectListConfirmRoutingNumber (keypress)="numberOnly($event)"
                                            (keyup)="onKeyUpRoutingNumberMismatch(selectListConfirmRoutingNumber.value)"
                                            placeholder="Re-enter Routing Number">
                                        <div class="errormsg"
                                            *ngIf="(!Banking_Form.controls.reenterRouting.dirty && !Banking_Form.controls.reenterRouting.valid) && (Banking_Form.controls.reenterRouting.touched || submitBankingAttempt)">
                                            Re-enter Routing Number is required
                                        </div>
                                        <div class="errormsg"
                                            *ngIf="Banking_Form.controls.reenterRouting.dirty && routingNumberMismatch">
                                            Routing Number and Re-enter Routing Number do not match
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="accountNumber"
                                            placeholder="Account Number" minlength="4" #accountNumber
                                            (keyup)=onKeyUpEnterAccountNumber(accountNumber.value)
                                            (keypress)="numberOnly($event)">
                                        <div class="errormsg"
                                            *ngIf="!Banking_Form.controls.accountNumber.valid && (Banking_Form.controls.accountNumber.touched || submitBankingAttempt)">
                                            Account Number is required minimum length 4 digits
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="reenterAccount"
                                            (paste)="onPaste($event)" (copy)="onCopy($event)"
                                            #selectListConfirmAccountNumber (keypress)="numberOnly($event)"
                                            minlength="4"
                                            (keyup)="onKeyUpAccountNumberMismatch(selectListConfirmAccountNumber.value)"
                                            placeholder="Re-enter Account Number">
                                        <div class="errormsg"
                                            *ngIf="(!Banking_Form.controls.reenterAccount.dirty && !Banking_Form.controls.reenterAccount.valid) && (Banking_Form.controls.reenterAccount.touched || submitBankingAttempt)">
                                            Re enter Account Number is required
                                        </div>
                                        <div class="errormsg"
                                            *ngIf="Banking_Form.controls.reenterAccount.dirty && accountNumberMismatch">
                                            Account Number and Re-enter Account Number do not match
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div *ngIf="isSelected('creditinfo')">
                        <div class="cardsection" style="margin: 2% 0%;">
                            <label>
                                <input type="radio" name="cardtype" (click)="changeCard('CreditCard')"
                                    value="CreditCard" [checked]='true' [(ngModel)]="typeOfCard">
                                <span> Credit Card</span>
                            </label>
                            <label style="margin-left: 7%;">
                                <input type="radio" name="cardtype" (click)="changeCard('DebitCard')" value="DebitCard"
                                    [(ngModel)]="typeOfCard">
                                <span> Debit Card</span>
                            </label>
                            <label style="    margin-left: 7%;">
                                <input type="radio" name="bank" value="bankinfo" (click)="setradio('bankinfo')"
                                    [(ngModel)]="selectedLink"><span> Bank ACH</span>
                            </label>
                        </div>
                        <form [formGroup]="Credit_Form">
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="cardNumber"
                                            placeholder="Card Number" #SelectList (keyup)="onKeyup(SelectList.value)"
                                            (keypress)="numberOnly($event)" maxlength="16">
                                        <div class="errormsg"
                                            *ngIf="!Credit_Form.controls.cardNumber.valid && (Credit_Form.controls.cardNumber.touched || submitCardAttempt)">
                                            Card Number is required
                                        </div>
                                        <div class="errormsg"
                                            *ngIf="(Credit_Form.controls.cardNumber.dirty && Credit_Form.controls.cardNumber.touched && notValidCardNumber) || (Credit_Form.controls.cardNumber.dirty && Credit_Form.controls.cardNumber.touched && !checkCardValid)">
                                            Please enter a Valid Card Number
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-5 col-xs-5">
                                                <input type="text" name="month" #selectListExpiryMonth
                                                    formControlName="expiryMonth"
                                                    (keyup)="onKeyupExpiryMonth(selectListExpiryMonth.value)"
                                                    (keypress)="numberOnly($event)" placeholder="MM" maxlength="2"
                                                    size="2" class="form-control" />
                                            </div>
                                            <div class="col-md-2 col-xs-2">
                                                <span class="slash_symbol">/</span>
                                            </div>
                                            <div class="col-md-5 col-xs-5">
                                                <input type="text" name="year" #selectListExpiryYear
                                                    formControlName="expiryYear"
                                                    (keyup)="onKeyupExpiryYear(selectListExpiryYear.value)"
                                                    (keypress)="numberOnly($event)" placeholder="YY" maxlength="2"
                                                    size="2" class="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="errormsg"
                                        *ngIf="(!Credit_Form.controls.expiryMonth.dirty && !Credit_Form.controls.expiryMonth.valid) && (Credit_Form.controls.expiryMonth.touched || submitCardAttempt) || (!Credit_Form.controls.expiryYear.dirty && !Credit_Form.controls.expiryYear.valid) && (Credit_Form.controls.expiryYear.touched || submitCardAttempt)">
                                        Expiration Date is required
                                    </div>
                                    <div class="errormsg"
                                        *ngIf="(Credit_Form.controls.expiryMonth.dirty && Credit_Form.controls.expiryMonth.touched && enterTwoDigitMonth)">
                                        Please Use 'MM/YY' format
                                    </div>
                                    <div class="errormsg"
                                        *ngIf="((Credit_Form.controls.expiryMonth.dirty && Credit_Form.controls.expiryMonth.touched && enterValidMonth) || (Credit_Form.controls.expiryMonth.dirty && Credit_Form.controls.expiryMonth.touched && invalidMonthEnteredValidation) || (Credit_Form.controls.expiryYear.dirty && !enterValidYear)) && (!enterTwoDigitMonth) ">
                                        Please enter Valid Expiration Date
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="cvv"
                                            (focusout)="oncvvfocusout()" id="cvvvalue" (keypress)="numberOnly($event)"
                                            placeholder="CVV" maxlength="4">
                                        <div class="errormsg"
                                            *ngIf="!Credit_Form.controls.cvv.valid && (Credit_Form.controls.cvv.touched || submitCardAttempt)">
                                            CVV is required
                                        </div>
                                        <div class="errormsg" id="cvverror">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row">
                        <div class="col-sm-12" style=" margin-top: -2px;">
                            <i style="font-size: 12px;color: #c1c1c1;">Transactions are secure and encrypted.</i>
                        </div>
                    </div>
                    <div style="margin-top:15px">
                        <h6 class="billinghdng">Billing Address</h6>
                        <label>
                            <input type="checkbox" [checked]="checkedCondition" (change)="handleChange($event)">
                            <span class="useshipping"> Use Shipping Address</span>
                        </label>
                        <form [formGroup]="Billing_Form" *ngIf="showShippingAddress">
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="form-group">
                                        <select formControlName="country_billing" class="form-control"
                                            style="margin-bottom: 15px;">
                                            <option *ngFor="let item of country_arr">{{item}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="zipCode_billing"
                                            (keypress)="numberOnly($event)" placeholder="ZIP Code" maxlength="5">
                                        <div class="errormsg"
                                            *ngIf="!Billing_Form.controls.zipCode_billing.valid && (Billing_Form.controls.zipCode_billing.touched || submitPaymentAttempt)">
                                            ZIP Code is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" formControlName="address1_billing"
                                    placeholder="Address 1">
                                <div class="errormsg"
                                    *ngIf="!Billing_Form.controls.address1_billing.valid && (Billing_Form.controls.address1_billing.touched || submitPaymentAttempt)">
                                    Address 1 is required
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" formControlName="address2_billing"
                                    placeholder="Address 2">
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="city_billing"
                                            placeholder="City">
                                        <div class="errormsg"
                                            *ngIf="!Billing_Form.controls.city_billing.valid && (Billing_Form.controls.city_billing.touched || submitPaymentAttempt)">
                                            City is required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <select formControlName="state_billing" class="form-control">
                                            <option>Select Your State</option>
                                            <option value={{item.name}} *ngFor="let item of states">{{item.name}}
                                            </option>
                                        </select>
                                        <div class="errormsg"
                                            *ngIf="!Billing_Form.controls.state_billing.valid && (Billing_Form.controls.state_billing.touched || submitPaymentAttempt)">
                                            State is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-xs-6">
                        <button type="button" class="btn btn-primary" (click)="backtoQutions()">Previous</button>
                    </div>
                    <!------*ngIf="!submitTermsAttempt"-->
                    <div class="col-lg-6 col-xs-6" style="text-align: right;">
                        <button type="button" (click)="onPayment()" class="btn btn-success">Next</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>