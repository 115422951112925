<div class="card">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-xs-6">
            <div class="planehead">Zip Code</div>
            <div class="planeh">Start Date</div>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-6" style="text-align: right;">
            <div class="planehead"> {{applicantData.zipcode}}</div>
            <div class="planeh"> {{applicantData.startdate | date:'MM/dd/yyyy'}}</div>
        </div>
    </div>
</div>
<div class="card  ">
    <div *ngFor="let item of applicantData.cart_products">
        <div class="row">
            <div class="col-lg-6">
                <div>Application Cart</div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-8 col-xs-8 cartHead">
                {{item.name}} Program
            </div>
            <div class="col-lg-4 col-md-4 col-xs-4 carthead">
                {{item.total_price | currency:'USD'}}
            </div>
        </div>
    </div>
</div>
<div class="card  ">
    <div style="font-weight: 800;">Onetime Fee</div>
    <div class="row" *ngFor="let item of applicantData.cart_fee">
        <div class="col-lg-6 col-md-6 col-xs-6 lfthead">
            {{item.fee_name}}
        </div>
        <div class="col-lg-6 col-md-6 col-xs-6 lfthead" style="text-align: right;">
            {{item.fee | currency:'USD'}}
        </div>
    </div>
    <div *ngIf="applicantData.cart_fee?.length > 0">
        <hr style=" border-top: 1px dashed  #c1c1c1;">
        <div class="row" *ngFor="let item of applicantData.cart_fee">
            <div class="col-lg-8 col-md-8 col-xs-8 lfthead" style="padding-right: 0;"
                *ngIf="item.discount !=0">
                {{item.fee_name}} <span>Discount</span>
            </div>
            <div class="col-lg-4 col-md-4 col-xs-4 lfthead" *ngIf="item.discount !=0"
                style="text-align: right;">
                {{item.discount | currency:'USD'}}
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <hr style=" border-top: 1px dashed  #c1c1c1;">
        </div>
        <div class="col-lg-6 col-md-6 col-xs-6" style="font-weight: 800;">
            Total Onetime Fee
        </div>
        <div class="col-lg-6 col-md-6 col-xs-6" style="text-align: right;font-weight: 800;">
            {{applicantData.total_one_time_fee | currency:'USD'}}
        </div>
    </div>
</div>
<div class="card">
    <div *ngIf="applicantData.cart_data?.length > 0">
        <div *ngFor="let data of applicantData.cart_data">
            <div class="row">
                <div class="col-lg-8 col-md-8 col-xs-8" style="font-weight: 800;">
                    Total Payments Today
                </div>
                <div class="col-lg-4 col-md-4 col-xs-4" style="text-align: right;font-weight: 800;">
                    {{data.total | currency:'USD'}}
                </div>
            </div>
        </div>
    </div>
</div>
<div class="card">
    <div *ngFor="let data of applicantData.cart_products">
        <div class="row">
            <div class="col-lg-8 col-md-8 col-xs-8" style="font-weight: 800;text-align: initial;">
                Monthly Contribution Starting 8/1
            </div>
            <div class="col-lg-4 col-md-4 col-xs-4" style="text-align: right;font-weight: 800;">
                {{data.total_price | currency:'USD'}}
            </div>
        </div>
    </div>
</div>