<div class="container space-3--lg space-2">
    <section id="two" class="wrapper style3 fade-up">
        <h2 class="hr">how it works</h2>
        
        <!-- <hr class="sep-2"> -->
         <!-- <div class="accordion_container">
          <div class="accordion_head">Who Can receive<span class="plusminus">-</span></div>
          <div class="accordion_body">
            <ul style="list-style: square;">
              <li>
               Groups
              </li>
              <li>
               Individuals
              </li>
              <li>
               Families
              </li>
              <li>
               “ANYONE who meets the membership eligibility requirements”
              </li>
          </ul>
          <p>Unlike traditional health insurance, there are: NO enrollment restrictions. NO enrollment periods. NO penalties.
       </p>
          </div>
          <div class="accordion_head"> How Do I Contribute?<span class="plusminus">+</span></div>
          <div class="accordion_body" style="display: none;">
            <p>
              You’ll make an affordable monthly contribution according to the plan you choose.</p>
          <p>    Your monthly contribution is combined with those of other individuals and families that share your faith.</p>
          <p> All this money is used to cover member’s medical bills when a need arises. This is much more affordable than COBRA or buying Major Medical Health Insurance without government subsidy.</p>
              
          </div>
          <div class="accordion_head">When Can I Receive?<span class="plusminus">+</span></div>
          <div class="accordion_body" style="display: none;">
            <p>
              We welcome you to join at any time. There are NO ENROLLMENT PERIODS.
            </p>
            <p>
              Each member or family chooses a plan with a Member Responsibility Amount (MRA), which is the minimum dollar amount they must contribute to the community before their own needs can be paid. The higher the MRA, the lower their monthly contribution and vice versa.
            </p>
            <p>
              Once a member’s MRA has been met, the community begins to pay his or her qualified medical bills.
            </p>
          </div>
          <div class="accordion_head">How Do I Receive?<span class="plusminus">+</span></div>
          <div class="accordion_body" style="display: none;">
            <p>
              See our Programs to learn more about what your extensive health benefits could be. There’s an option for every budget and lifestyle.
                 </p>
          </div>
        </div> -->
        <div class="accordion-container">
          <div href="#" class="accordion-toggle">Who Can receive<span class="plusminus">-</span></div>
          <div class="accordion-content">
            <ul style="list-style: square;">
              <li>
               Groups
              </li>
              <li>
               Individuals
              </li>
              <li>
               Families
              </li>
              <li>
               ANYONE who meets the membership eligibility requirements
              </li>
          </ul>
          <p>Unlike traditional health insurance, there are: NO enrollment restrictions. NO enrollment periods. NO penalties.
       </p>
          </div>
        </div>
        <div class="accordion-container">
          <div href="#" class="accordion-toggle">How Do I Contribute?<span class="plusminus">+</span></div>
          <div class="accordion-content" style="display: none;">
            <p>
              You’ll make an affordable monthly contribution according to the program you choose.</p>
          <p>    Your monthly contribution is combined with those of other individuals and families that share your faith.</p>
          <p> All this money is used to cover member’s medical bills when a need arises. This is much more affordable than COBRA or buying Major Medical Health Insurance without government subsidy.</p>
              
          </div>
        </div>
        <div class="accordion-container">
          <div href="#" class="accordion-toggle">When?<span class="plusminus">+</span></div>
          <div class="accordion-content" style="display: none;">
            <p>
              We welcome you to join at any time. There are NO ENROLLMENT PERIODS.
            </p>
            <p>
              Each member or family chooses a program with a Member Responsibility Amount (MRA), which is the minimum dollar amount they must contribute to the community before their own needs can be paid. The higher the MRA, the lower their monthly contribution and vice versa.
            </p>
            <p>
              Once a member’s MRA has been met, the community begins to pay his or her qualified medical bills.
            </p>
          </div>
        </div>
        <div class="accordion-container">
          <div href="#" class="accordion-toggle">How Do I Receive?<span class="plusminus">+</span></div>
          <div class="accordion-content" style="display: none;">
            <p>
              See our Programs to learn more about what your extensive health benefits could be. There’s an option for every budget and lifestyle.
                 </p>
          </div>
        </div>
    </section>
</div>