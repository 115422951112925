<h3 class="text-center">Blogs</h3>
<div class="container">
  <div class="row" *ngIf="blogList">
    <div class="col-md-6 col-lg-4 mb-4" *ngFor="let item of blogList">
      <div class="card shadow text-center">
        <div class="image">
          <img [src]="item.img  ? 'item.img' : 'assets/img/family-health.jpg'" class="img-thumbnail">
        </div>
        <div class="card-body ">
          <b>{{item.created_at | date: 'd-MMM-y, h:mm:ss a'}}</b>
          <p> {{item.blog_shortdesc}}</p>
        </div>
        <div class="card-footer" (click)="onNavigate(item)"> {{item.blog_title}}
          <span>
            »
          </span> 
        </div>
      </div>
    </div>
  </div>

</div>

