<h3>Billing Address</h3>
<input type="checkbox" [(ngModel)]="shippingAddress"> Use Shipping Address
<form [formGroup]="Billing_Address_Form" *ngIf="!shippingAddress">
    <div class="form-group">
      <select formControlName="country" class="form-control ">
        <option *ngFor="let country of countries">{{country}}</option>
      </select>
    </div>
    <div class="form-group">
      <input type="text" class="form-control " formControlName="address1" placeholder="Address 1">
    </div>
    <div class="form-group">
      <input type="text" class="form-control " formControlName="address2" placeholder="Address 2">
    </div>
    <div class="form-group row">
        <div class="col-md-4">
            <input type="text" class="form-control " formControlName="city" placeholder="City">
        </div>
        <div class="col-md-4">
          <select formControlName="state" class="form-control">
            <option value="" disabled selected [ngValue]="null">Select Your State
            </option>
            <option value={{item.name}} *ngFor="let item of states">{{item.name}}
            </option>
          </select>
        </div>
        <div class="col-md-4">
            <input type="text" class="form-control " formControlName="zipCode" placeholder="ZIP Code">
        </div>
    </div>
</form>
