<section class="footer">
    <div class="container" *ngIf="loadedData=='showheader'">
       
        <div class="row col-sm-12" *ngIf="companyContent">
            <div class="col-sm-4">
                <div class="widget">
                    <h5 class="widget-title">
                        HealthShare Program Disclaimer
                    </h5>
                    <p class="pr">
                        Fellowship HealthShare, Inc. is NOT an insurance company and the Membership is NOT issued or
                        offered
                        by an insurance company, while every effort is made to meet member's medical needs, Fellowship
                        HealthShare, and the Fellowship HealthShare Community do not guarantee payment of any medical
                        expense.
                    </p>
                </div>
            </div>
            <div class="col-sm-2">
                <div class="widget">
                    <h5 class="widget-title">
                        About
                    </h5>
                    <ul id="menu-footer-quick-links">
                        <li>
                            <a class="hover" routerLink="/what-we-do">
                                About Us
                            </a>
                        </li>
                        <li>
                            <a class="hover" routerLink="/TermOfUse">
                                Terms & Conditions
                            </a>
                        </li>
                        <li>
                            <a class="hover" routerLink="/privacy-policy">
                                Privacy & Policy
                            </a>
                        </li>
                        <li><a class="hover" routerLink="/stateSpecificNotices">
                                State Specific Notices
                            </a> </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-2">
                <div class="widget">
                    <h5 class="widget-title">
                        Support
                    </h5>
                    <ul id="menu-footer-quick-links">
                        <li>
                            <a class="hover" routerLink="/get-in-touch">
                                Contact Us
                            </a>
                        </li>
                        <li>
                            <a class="hover" routerLink="/Glossary">
                                Glossary of Terms
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-2">
                <div class="widget">
                    <h5 class="widget-title">
                        Collaboration
                    </h5>
                    <ul id="menu-footer-quick-links">
                        <li>
                            <a class="hover" target="_blank" href="{{url}}">
                                Affiliates & Producers
                            </a>
                        </li>
                        <li>
                            <a class="hover" routerLink="/employer-group">
                                Employer Groups
                            </a>
                        </li>
                        <li>
                            <a class="hover" target="_blank" href="{{url}}">
                                Management Login
                            </a>
                        </li>
                        <li>
                            <a class="hover" routerLink="/appointmenttest">
                                Appointment Test
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-2">
                <div class="widget">
                    <h5 class="widget-title">
                        Follow US on
                    </h5>
                    <ul id="" class="social d-flex  align-items-center">
                        <li><a target="_blank" href="https://www.facebook.com/fellowshiphealthshare"><i
                                    class="fa fa-facebook"></i> </a></li>
                        <li><a target="_blank" href="https://twitter.com/FHealthshare"><i class="fa fa-twitter"></i>
                            </a></li>
                        <li><a target="_blank" href="https://www.instagram.com/fellowshiphealthshare01/"><i
                                    class="fa fa-instagram"></i> </a></li>
                        <li><a target="_blank" href="https://www.linkedin.com/company/fellowship-healthshare">
                            <i class="bi bi-linkedin"></i> </a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-sm-12">
            <hr>
            <div class="p-60">
                <h5 class="widget-title">
                    Discount Health Benefits Disclosure
                </h5>
                <p>
                    Dental, Vision, Chiropractic, Labs, Imaging, Diabetic & Home Medical Supplies Disclosure. <b>This
                        plan is NOT insurance</b>. This plan is not a qualified health plan under the Affordable Care
                    Act (ACA). Some services may be covered by a qualified health plan under the ACA. This plan does not
                    meet the minimum creditable coverage requirements under M.G.L. c. 111M and 956 CMR 5.00. This is not
                    a Medicare prescription drug plan. Discounts on hospital services are not available in Maryland. The
                    plan provides discounts at certain health care providers of medical services. The plan does not make
                    payments directly to the providers of medical services. The plan member is obligated to pay for all
                    health care services but will receive a discount from those health care providers who have
                    contracted with the discount plan organization. The range of discounts will vary depending on the
                    provider type and services provided. The licensed discount plan organization is Coverdell & Company,
                    Inc., at Coverdell & Co., Inc. at 2850 W. Golf Road, Rolling Meadows, IL 60008, 1-866-770-5259. To
                    view a list of participating providers visit www.findbestbenefits.com and enter promo code 571130.
                    <span class="f-600">You have the right to cancel this plan within 30 days of the effective date for a full refund of
                        fees paid</span>. Such refunds are issued within 30 days of cancellation.
                </p>
            </div>
        </div>
    </div>
    <div class="copyright" *ngIf="companyContent">
        © Fellowship Healthshare. All rights reserved.
    </div>
 
</section>