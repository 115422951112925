import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RadioButtonItem } from 'src/app/featuresModule/radio-button/radio-button.component';
import { PaymentGroup, PaymentType } from '../payment-info.model';

@Component({
  selector: 'app-payment-section',
  templateUrl: './payment-section.component.html',
  styleUrls: ['./payment-section.component.scss']
})
export class PaymentSectionComponent implements OnInit {
  radioGroup: RadioButtonItem[] = [];
  paymentType: any;
  isBankType:boolean = false;
  ngOnInit(): void {
    this.radioGroup = PaymentGroup.CARD;
    this.paymentType = PaymentType.CREDIT_CARD;
  }

  paymentTypeChange(value) {
    if (value === PaymentType.BANK_ACH) {
      this.radioGroup = PaymentGroup.BANK;
      this.paymentType = PaymentType.CHECKING;
      this.isBankType = true;
    }
    if (value === PaymentType.CARD) {
      this.radioGroup = PaymentGroup.CARD;
      this.paymentType = PaymentType.CREDIT_CARD;
      this.isBankType = false;
    }
  }

}
